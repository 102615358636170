import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

type BoxProps = {
  chart: "bar" | "pie";
};

export const Box = styled.div<BoxProps>`
  ${({ chart }) => css`
    padding-top: ${chart === "pie" && "12px"};
    height: ${chart === "bar" ? "320px" : "400px"};

    @media (max-width: 1750px) {
      height: ${chart === "bar" ? "300px" : "360px"};
    }

    @media (max-width: 1650px) {
      height: ${chart === "bar" ? "280px" : "340px"};
    }

    @media (max-width: 1270px) {
      height: ${chart === "bar" ? "260px" : "340px"};
    }

    @media (max-width: 940px) {
      height: ${chart === "bar" ? "234px" : "300px"};
    }
  `}
`;
