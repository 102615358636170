import * as S from "./styles";

type ContactButtonProps = {
  text: string;
  href: string;
  textColor?: string;
  borderColor?: string;
  onClick: () => void;
  backgroundColor?: string;
  type: "large" | "medium";
};

const ContactButton: React.FC<ContactButtonProps> = ({
  type,
  href,
  text,
  onClick,
  textColor = "#fafafa",
  borderColor = "#257034",
  backgroundColor = "#257034",
}) => {
  return (
    <S.Container
      type={type}
      href={href}
      target="_blank"
      textColor={textColor}
      borderColor={borderColor}
      rel="noopener noreferrer"
      onClick={() => onClick()}
      backgroundColor={backgroundColor}
    >
      {text}
    </S.Container>
  );
};

export default ContactButton;
