const FuelIcon: React.FC = () => {
  return (
    <svg
      width="355"
      height="396"
      viewBox="0 0 355 396"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0H62.5C68.3333 0 73.5417 2.29167 77.2917 6.04167L120.625 49.5833L137.292 33.125C145.833 25 156.25 20.8333 166.667 20.8333H291.667C302.083 20.8333 312.5 25 321.042 33.125L341.875 53.9583C350 62.5 354.167 72.9167 354.167 83.3333V354.167C354.167 365.217 349.777 375.815 341.963 383.629C334.149 391.443 323.551 395.833 312.5 395.833H104.167C93.116 395.833 82.5179 391.443 74.7039 383.629C66.8899 375.815 62.5 365.217 62.5 354.167V125C62.5 114.583 66.6667 104.167 74.7917 95.625L91.25 78.9583L53.9583 41.6667H0V0ZM166.667 62.5V104.167H291.667V62.5H166.667ZM175.208 187.5L133.542 145.833H104.167V175.208L145.833 216.875V283.125L104.167 324.792V354.167H133.542L175.208 312.5H241.458L283.125 354.167H312.5V324.792L270.833 283.125V216.875L312.5 175.208V145.833H283.125L241.458 187.5H175.208ZM187.5 229.167H229.167V270.833H187.5V229.167Z"
        fill="black"
      />
    </svg>
  );
};

export default FuelIcon;
