const ResiduesIcon: React.FC = () => {
  return (
    <svg
      width="500"
      height="500"
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M109.375 109.375L128.906 421.875C129.834 439.932 142.969 453.125 160.156 453.125H339.844C357.1 453.125 369.99 439.932 371.094 421.875L390.625 109.375"
        stroke="black"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M78.125 109.375H421.875Z" fill="black" />
      <path
        d="M78.125 109.375H421.875"
        stroke="black"
        strokeWidth="32"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M187.5 109.375V70.3126C187.491 67.2322 188.091 64.1804 189.266 61.3328C190.44 58.4851 192.166 55.8978 194.345 53.7197C196.523 51.5415 199.11 49.8154 201.958 48.6408C204.805 47.4661 207.857 46.8661 210.938 46.8751H289.062C292.143 46.8661 295.195 47.4661 298.042 48.6408C300.89 49.8154 303.477 51.5415 305.655 53.7197C307.834 55.8978 309.56 58.4851 310.734 61.3328C311.909 64.1804 312.509 67.2322 312.5 70.3126V109.375M250 171.875V390.625M179.688 171.875L187.5 390.625M320.312 171.875L312.5 390.625"
        stroke="black"
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ResiduesIcon;
