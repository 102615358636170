const GasIcon: React.FC = () => {
  return (
    <svg
      width="300"
      height="400"
      viewBox="0 0 300 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M110 47.0588H120V70.5882C120 74.9192 124.477 78.4314 130 78.4314C135.523 78.4314 140 74.9192 140 70.5882V47.0588H160V70.5882C160 74.9192 164.478 78.4314 170 78.4314C175.522 78.4314 180 74.9192 180 70.5882V47.0588H190C195.522 47.0588 200 43.5467 200 39.2157C200 34.8847 195.522 31.3726 190 31.3726C166.544 31.3726 134.955 31.3726 110 31.3726C104.477 31.3726 100 34.8847 100 39.2157C100 43.5467 104.477 47.0588 110 47.0588Z"
        fill="black"
      />
      <path
        d="M260 62.7451H240V15.6863H255C260.522 15.6863 265 12.1741 265 7.84314C265 3.51216 260.522 0 255 0C238.039 0 51.615 0 45 0C39.477 0 35 3.51137 35 7.84314C35 12.1749 39.477 15.6863 45 15.6863H60V62.7451H40C17.944 62.7451 0 76.8188 0 94.1176V337.255C0 354.554 17.944 368.627 40 368.627H42.193L43.446 372.556C48.595 388.715 67.022 400 88.259 400H211.74C232.977 400 251.405 388.715 256.554 372.557L257.807 368.627H260C282.056 368.627 300 354.554 300 337.255V94.1176C300 76.8188 282.056 62.7451 260 62.7451ZM20 117.647H280V313.725H20V117.647ZM80 15.6863H220V62.7451H80V15.6863ZM40 78.4314C48.969 78.4314 248.928 78.4314 260 78.4314C271.028 78.4314 280 85.4682 280 94.1176V101.961H20V94.1176C20 85.4682 28.972 78.4314 40 78.4314ZM237.152 368.752C234.232 377.915 223.782 384.314 211.74 384.314H88.259C76.217 384.314 65.768 377.915 62.848 368.751L62.809 368.627H237.192L237.152 368.752ZM260 352.941C244.508 352.941 49.719 352.941 40 352.941C28.972 352.941 20 345.904 20 337.255V329.412H280V337.255C280 345.904 271.028 352.941 260 352.941Z"
        fill="black"
      />
    </svg>
  );
};

export default GasIcon;
