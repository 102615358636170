import { primaryColor } from "../../constants/colors";

interface IProps {
  color?: string;
}

const DisclosureIcon: React.FC<IProps> = ({ color }) => {
  return (
    <svg
      id="Camada_1"
      data-name="Camada 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
    >
      <g id="s7xQlP.tif">
        <g>
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m912.27,115.3c-33.77,25.66-60.46,58.71-91.23,87.5-7.3,6.83-14.14,14.16-21.63,20.75-9.87,8.69-21.5,14.48-32.48,3.54-11.03-10.99-5.5-22.95,3.61-32.39,24.69-25.56,50.09-50.43,74.84-75.92,11.56-11.91,22.29-24.62,33.4-36.96,28.15-5.83,39.31,5.33,33.48,33.48Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m651.1,81.81c16.6,50.99,6.44,103.17,6.36,154.84-.02,10.42-8.15,18.76-20.25,17.57-11.64-1.14-18.38-10.21-18.5-20.5-.58-50.7-9.21-101.78,5.6-151.92h26.79Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m912.27,369.77c-50.16,14.9-101.26,6.27-151.99,5.6-10.24-.14-19.35-6.91-20.51-18.55-1.2-12.13,7.12-20.23,17.58-20.22,51.7.04,103.91-10.17,154.92,6.38v26.79Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m53.07,800.4c-.79-18.78,3.85-37.11,17.64-49.04,63.87-55.21,91.85-133.28,132.22-203.47,8.34-14.51,16.15-29.32,24.74-43.68,6.1-10.18,14.68-18.6,27.34-12.35,14.28,7.04,12.91,20.23,6.94,31.84-17.33,33.73-36,66.78-53.44,100.46-51.2,98.88-53.03,66.1,18.38,139.87,61.79,63.83,62.66,63.13,141.76,20.95,102.38-54.59,204.32-110.02,307.25-163.56,25.72-13.38,26.3-22.31,5.98-42.23-90.03-88.29-179.25-177.42-267.43-267.55-20.99-21.46-30.71-17.92-42.01,7.2-10.04,22.32-22.67,43.53-34.9,64.8-7.45,12.97-13.96,33.12-33.9,22.41-21.41-11.5-8.15-28.94-.75-43.42,10.66-20.85,22.21-41.25,33.29-61.89,13.11-24.43,25.04-48.04,26.41-77.83,1.83-39.68,46.3-61.29,82.94-43.73,8.84,4.24,17.1,10.68,24.1,17.65,109.11,108.67,217.97,217.6,326.9,326.46,14.69,14.68,25.41,31.05,24.05,53.04-1.9,30.84-17.94,52.72-48.06,55.4-69.16,6.14-118.63,53.87-178.38,79.53-13.69,5.88-9.65,13.52-2.78,23.54,35.07,51.17,27.95,80.65-25.68,110.43-45.84,25.45-91.57,51.12-137.67,76.11-54.98,29.81-74.85,27.81-117.67-16.85-17.92-18.69-28.48-20.02-47.34-.51-51.21,52.97-89.66,51.65-142.57-1-15.03-14.96-30.04-29.93-44.79-45.16-15.43-15.94-23.9-34.81-22.56-57.4Zm726.77-225.18c-1.15-14.24-11.14-22.08-19.69-30.64-103.16-103.28-206.38-206.5-309.69-309.63-6.27-6.26-13.06-12.12-20.22-17.32-7.12-5.16-14.9-4.43-21.5,1.4-7.71,6.82-9.3,15.3-3.42,23.9,4.36,6.38,9.56,12.31,15.04,17.79,103.93,104.09,207.92,208.11,312.03,312.02,6.25,6.24,12.82,12.96,20.57,16.74,14.71,7.18,22.8-.98,26.89-14.26Zm-401.75,322.44c4.98-1.58,11.8-2.63,17.49-5.73,53.65-29.28,107.19-58.77,160.57-88.53,13.02-7.26,21.91-18.77,11.34-32.61-10.54-13.81-19.62-35.05-44.74-21.45-60.58,32.82-121.42,65.18-182.03,97.93-6.53,3.53-16.89,8.08-10.84,17.03,11.08,16.4,23.44,32.93,48.2,33.34Zm-236.13-154.96c-25.01,1.06-57.11,54.77-43.86,75.18,20.01,30.82,45.87,58.33,76.98,77.15,31.75,19.21,50.73-9.94,69.28-29.6,19.19-20.34-4.49-29.06-14.18-39.58-22.55-24.49-46.73-47.48-70.43-70.91-5.4-5.34-10.24-11.98-17.8-12.24Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m531.88,624.47c-1.99,18.72-13.55,23.31-26.48,21.69-36.88-4.62-62.5,7.26-82.32,40.7-16.22,27.36-37.32,23.39-45.51-8.38-8.83-34.24-25.95-54.81-61.39-60.16-10.98-1.66-21.78-7.15-24.26-19.98-2.59-13.4,6.86-21.4,16.62-26.29,33.55-16.79,42.31-43.49,40.33-79.5-1.64-29.86,17.67-39.36,40.25-19.44,27.79,24.53,53.8,27.76,87.48,14,27.58-11.26,43.06,3.86,31.97,31.58-13.51,33.77-12.87,61.5,14.17,87.41,5.15,4.93,6.82,13.49,9.13,18.36Zm-68.89-90.73c-29.07,12.91-48.96,8.75-72.59-5.29,3.68,27.89-11.19,42.96-29.61,59.73,25.35,6.62,38.61,22,48.08,45.28,16.28-20.98,35.28-30.04,64.78-25.6-20.43-23.02-26.62-43.38-10.65-74.11Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default DisclosureIcon;
