import React, { RefObject, useEffect, useRef, useState } from "react";

import * as S from "./styles";
import AboutUs from "./components/AboutUs";
// import Benefits from "./components/Benefits";
import SEO from "../../components/atoms/SEO";
import InfoPages from "./components/InfoPages";
import Plataform from "./components/Plataform";
import Advantages from "./components/Advantages";
import Newsletter from "./components/Newsletter";
import FAQ from "../../components/organisms/FAQ";
import { Analytics, Cookies } from "../../services";
import InitialBanner from "./components/InitialBanner";
import Footer from "../../components/molecules/Footer";
import Header from "../../components/molecules/Header";
import { getCookieFromUser } from "../../utils/cookies";
import ContactUs from "../../components/organisms/ContactUs";
import HowCompensate from "../../components/organisms/HowCompensate";
import CalculatorModal from "../../components/molecules/CalculatorModal";
import ParallaxSection from "../../components/molecules/ParallaxSection";
import RequestAccessModal from "../../components/molecules/RequestAccessModal";

import boatForest from "../../components/assets/forest_parallax.png";

import home from "../../config/home.json";

const Home: React.FC = () => {
  const [requestModal, setRequestModal] = useState<boolean>(false);
  const [calculatorModal, setCalculatorModal] = useState<boolean>(false);

  const faq = useRef<HTMLDivElement>(null);
  const aboutUs = useRef<HTMLDivElement>(null);
  const howDeal = useRef<HTMLDivElement>(null);
  // const benefits = useRef<HTMLDivElement>(null);
  const plataform = useRef<HTMLDivElement>(null);
  const advantages = useRef<HTMLDivElement>(null);

  const scrollToSection = (
    sectionRef: RefObject<HTMLElement>,
    marginTop: number
  ) => {
    if (sectionRef.current) {
      const topPosition =
        sectionRef.current.getBoundingClientRect().top +
        window.scrollY -
        marginTop;
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const sendPageView = async () => {
      if (typeof document !== "undefined") {
        const userId = getCookieFromUser();

        if (!userId) {
          const newUserId = await Cookies.setCookie();

          await Analytics.sendPageView({
            user: newUserId,
            location: "home-lp",
            eventLabel: "page-view-home",
            meta: { stage: "landing-page" },
          });
        } else {
          await Analytics.sendPageView({
            user: userId,
            location: "home-lp",
            eventLabel: "page-view-home",
            meta: { stage: "landing-page" },
          });
        }
      }
    };

    sendPageView();
  }, []);

  const onClickSection = (label: string) => {
    if (label === "aboutUs") scrollToSection(aboutUs, 60);

    if (label === "advantages") scrollToSection(advantages, 60);

    if (label === "howDeal") scrollToSection(howDeal, 60);

    // if (label === "benefits") scrollToSection(benefits, 60);

    if (label === "plataform") scrollToSection(plataform, 60);

    if (label === "faq") scrollToSection(faq, 60);
  };

  return (
    <S.Page>
      <SEO
        route={home.route}
        title={home.title}
        keywords={home.keywords}
        description={home.description}
      />

      <Header
        onClickSection={(val) => onClickSection(val)}
        onClickCalculators={() => setCalculatorModal(true)}
      />

      <InitialBanner onClickCalculators={() => setCalculatorModal(true)} />

      <S.Container ref={aboutUs}>
        <AboutUs />
      </S.Container>

      <InfoPages />

      <S.Container ref={advantages}>
        <Advantages onClickCalculators={() => setCalculatorModal(true)} />
      </S.Container>

      <ParallaxSection image={boatForest} />

      <S.Container ref={howDeal}>
        <HowCompensate />
      </S.Container>

      {/* <S.Container ref={benefits}>
        <Benefits />
      </S.Container> */}

      <S.Container ref={plataform}>
        <Plataform onRequestAccess={() => setRequestModal(true)} />
      </S.Container>

      <S.Container ref={faq}>
        <FAQ />
      </S.Container>

      <ContactUs location="home" />

      <Newsletter />

      <Footer />

      {calculatorModal && (
        <CalculatorModal
          isOpen={calculatorModal}
          onClose={() => setCalculatorModal(false)}
        />
      )}

      {requestModal && (
        <RequestAccessModal
          isOpen={requestModal}
          onClose={() => setRequestModal(false)}
        />
      )}
    </S.Page>
  );
};

export default Home;
