import * as S from "./styles";
import H3 from "../../../../components/atoms/Typography/H3";
import ibioma from "../../../../components/assets/ibioma_logo.png";

const AboutUs: React.FC = () => {
  return (
    <S.Container>
      <S.Image image={ibioma} />

      <S.TextBox>
        <H3 color="#000000">
          Somos uma solução do <span>Grupo OMA</span>! Compre créditos de
          carbono e acesse projetos de alta <span>integridade</span>, capazes de
          gerar <span>impactos positivos</span> através de uma plataforma{" "}
          <span>intuitiva</span> e <span>amigável</span>. Tenha autonomia para
          calcular sua própria pegada de carbono em nossa{" "}
          <span>calculadora virtual</span>.
        </H3>
      </S.TextBox>
    </S.Container>
  );
};

export default AboutUs;
