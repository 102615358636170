const FlightsIcon: React.FC = () => {
  return (
    <svg
      width="536"
      height="537"
      viewBox="0 0 536 537"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M228.526 58.2752V183.585C228.532 186.322 227.853 189.017 226.555 191.412C225.256 193.808 223.381 195.827 221.105 197.278L25.4211 322.051C23.1532 323.497 21.2824 325.507 19.9844 327.893C18.6864 330.279 18.0036 332.962 18 335.691V359.856C17.9999 362.305 18.5471 364.722 19.6002 366.923C20.6533 369.125 22.1844 371.052 24.0772 372.558C25.97 374.065 28.1746 375.112 30.5233 375.618C32.872 376.125 35.3031 376.078 37.6316 375.482L208.895 331.77C211.223 331.174 213.654 331.128 216.003 331.635C218.352 332.141 220.556 333.188 222.449 334.694C224.342 336.201 225.873 338.128 226.926 340.329C227.979 342.531 228.526 344.948 228.526 347.397V426.874C228.524 429.168 228.041 431.435 227.111 433.523C226.181 435.611 224.824 437.473 223.132 438.983L166.289 489.73C154.237 500.523 163.737 520.634 179.5 517.708L265.184 501.839C267.046 501.495 268.954 501.495 270.816 501.839L356.5 517.708C372.263 520.661 381.763 500.523 369.711 489.757L312.868 439.01C311.173 437.497 309.814 435.631 308.884 433.538C307.953 431.444 307.472 429.172 307.474 426.874V347.397C307.475 344.951 308.022 342.537 309.073 340.338C310.125 338.139 311.653 336.214 313.543 334.708C315.432 333.202 317.633 332.154 319.979 331.645C322.324 331.136 324.752 331.179 327.079 331.77L498.395 375.482C500.721 376.074 503.15 376.116 505.495 375.608C507.84 375.099 510.041 374.051 511.931 372.545C513.821 371.039 515.349 369.114 516.401 366.915C517.452 364.716 517.999 362.302 518 359.856V335.691C517.996 332.962 517.314 330.279 516.016 327.893C514.718 325.507 512.847 323.497 510.579 322.051L314.895 197.251C312.623 195.803 310.75 193.788 309.452 191.397C308.153 189.007 307.473 186.318 307.474 183.585V58.2752C307.474 47.5935 303.315 37.3494 295.912 29.7963C288.509 22.2433 278.469 18 268 18C257.531 18 247.491 22.2433 240.088 29.7963C232.685 37.3494 228.526 47.5935 228.526 58.2752Z"
        stroke="black"
        strokeWidth="36"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FlightsIcon;
