interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const CloseIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="229"
      fill="none"
      height="224"
      viewBox="0 0 229 224"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="3.13013"
        y1="220.213"
        x2="220.213"
        y2="3.13016"
        strokeWidth="16"
        stroke={color || "#257034"}
      />
      <line
        x1="8.78698"
        y1="3.13007"
        x2="225.87"
        y2="220.213"
        strokeWidth="16"
        stroke={color || "#257034"}
      />
    </svg>
  );
};

export default CloseIcon;
