interface IProps {
  color?: string;
}

const EmailTwoIcon: React.FC<IProps> = ({ color }) => {
  return (
    <svg
      width="43"
      height="34"
      viewBox="0 0 43 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 0H38.25C40.5875 0 42.5 1.9125 42.5 4.25V29.75C42.5 32.0875 40.5875 34 38.25 34H4.25C1.9125 34 0 32.0875 0 29.75L0.02125 4.25C0.02125 1.9125 1.9125 0 4.25 0ZM22.3762 18.4236L37.4 9.0311C37.9313 8.6911 38.25 8.11735 38.25 7.5011C38.25 6.07735 36.6987 5.22735 35.4875 5.9711L21.25 14.8748L7.0125 5.9711C5.80125 5.22735 4.25 6.07735 4.25 7.5011C4.25 8.11735 4.56875 8.6911 5.1 9.0311L20.1237 18.4236C20.8037 18.8486 21.6962 18.8486 22.3762 18.4236Z"
        fill={color || "#ddff66"}
      />
    </svg>
  );
};

export default EmailTwoIcon;
