import * as S from "./styles";

interface IProps {
  image: string;
}

const ParallaxSection: React.FC<IProps> = ({ image }) => {
  return <S.Container image={image} />;
};

export default ParallaxSection;
