import { primaryColor } from "../../constants/colors";

interface IProps {
  color?: string;
}

const SupportIcon: React.FC<IProps> = ({ color }) => {
  return (
    <svg
      id="Camada_1"
      data-name="Camada 1"
      viewBox="0 0 1000 1000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="EGmIol.tif">
        <g>
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m487.48,829.91c-20.5-5.9-36.42-17.27-45.34-37.33-17.69-39.74,11.67-86.75,55.09-87.59,67.19-1.29,124.65-24.86,170.46-74.52,32.35-35.07,51.99-76.56,57.45-124.02,9.72-84.46-18.47-154.26-84.32-207.94-36.75-29.95-79.62-45.31-127.17-48.27-114.98-7.15-211.55,72.31-235.41,174.93-10.24,44.06-6.8,87.35,7.15,130.08,2.22,6.79,4.38,13.6,6.67,20.36,1.18,3.5,1.09,5.79-3.58,5.77-29.29-.12-58.61.83-87.84-.46-29.15-1.29-54.99-29.48-55.31-58.6-.33-30.2-.36-60.4.01-90.6.38-30.57,28.31-58.18,58.94-58.74,13.37-.24,26.75-.22,40.12,0,4.37.07,6.57-1.63,8.37-5.49,36.04-77.51,94.68-129.74,177.48-151.32,93.74-24.43,178.02-3.19,251.05,60.68,28.69,25.1,50.3,55.65,65.78,90.46,1.88,4.23,4.38,5.78,8.95,5.67,11.75-.27,23.52-.12,35.28-.08,28.89.11,51.95,16.97,60.83,44.41.64,1.97,1.36,3.91,2.05,5.86v107.91c-.61,1.73-1.27,3.45-1.83,5.2-9.07,28.22-31.96,44.96-61.69,45.07-11.52.04-23.05.24-34.56-.1-4.98-.15-7.29,1.86-9.31,6.2-34.53,74.29-90.96,123.8-168.99,148.67-15.06,4.8-15.19,4.61-15.1,20.6.07,12.22.24,24.34-4.97,35.92-9,20.02-24.85,31.39-45.36,37.25h-24.91Zm324.7-352.63c0-13.13.16-26.26-.04-39.39-.21-13.86-8.72-22.21-22.61-22.5-5.76-.12-11.52-.03-17.28-.03-8.66,0-8.56,0-6.98,8.24,2.82,14.7,4.65,29.51,4.99,44.52.49,21.72-.77,43.26-5.6,64.48-1.12,4.91.06,6.68,5.22,6.38,6.2-.36,12.44-.02,18.66-.1,15.18-.19,23.51-8.46,23.63-23.61.11-12.67.02-25.34.01-38Zm-624.5-.37c0,12.67,0,25.33,0,38,0,.46,0,.92,0,1.38.37,13.86,8.69,22.25,22.57,22.58,6.22.15,12.47-.38,18.65.15,6.61.57,7.12-2.26,5.95-7.67-3.08-14.18-4.83-28.54-5.23-43.08-.62-22.64.65-45.09,5.64-67.23.94-4.18.09-5.95-4.54-5.74-6.44.29-12.9.02-19.35.08-15.2.16-23.55,8.4-23.68,23.53-.11,12.66-.02,25.33-.02,38Zm332.49,283.08c0-2.76-.01-5.53,0-8.29.02-2.76-1.16-4.46-4.08-4.36-7.34.24-14.77-.81-21.97,1.5-8.65,2.78-14.95,11.55-14.28,19.88.79,9.83,9.06,18.13,18.92,19,8.3.73,17.25-5.56,20.09-14.04,1.5-4.47,1.41-9.07,1.3-13.68Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m416.92,664.37c-32.05,0-64.1-.14-96.15.12-6.32.05-8.58-1.88-8.25-8.25.45-8.74.48-17.53-.01-26.26-.35-6.14,1.91-8.49,7.88-9.35,13.26-1.9,22.55-9.66,29.02-21.19,2.44-4.36,2.07-7.83-.98-11.96-20.4-27.76-32.47-58.97-35.27-93.21-4.21-51.45,9.52-97.9,43.2-137.39,41.57-48.73,95.07-70.61,158.84-66.7,82.55,5.05,156.18,72.31,169.26,153.98,15.09,94.21-35.5,179.36-125.46,210.85-17.05,5.97-34.73,9.23-52.86,9.29-29.74.1-59.49.03-89.23.03v.04Zm34.16-42.47c19.97-.43,43.75,1.21,67.41-1.47,78.08-8.86,135.98-84.64,124.68-163.03-12.2-84.6-93.38-140.28-176.29-120.92-59.63,13.92-110.93,66.85-111.5,140.59-.28,35.75,11.82,67.84,35.72,94.91,4.24,4.8,6.55,9.35,5.61,16.01-1.32,9.44-3.12,18.71-7.32,27.22-2.97,6.02-.95,6.85,4.69,6.78,17.75-.23,35.5-.08,57.01-.08Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m395.67,477.67c0-21.77,0-21.95,20.43-21.68,7.02.09,15.88-3.2,20.58,1.68,4.36,4.53,1.35,13.2,1.41,20.03.19,20.67.06,20.84-20.36,20.57-7.02-.09-15.87,3.19-20.58-1.68-4.4-4.54-.83-13.21-1.47-18.92Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m500.09,455.92c5.06,0,10.13.11,15.19-.04,3.94-.11,5.97,1.24,5.91,5.52-.15,10.58-.12,21.17-.03,31.75.04,3.91-1.83,5.24-5.5,5.2-10.59-.09-21.18-.11-31.76,0-3.92.04-5.24-1.84-5.21-5.5.09-10.58.11-21.17,0-31.75-.04-3.91,1.83-5.28,5.5-5.2,5.29.11,10.59.03,15.88.02Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m582.82,455.7c6.89,1.09,15.5-2.88,20,2.02,4.1,4.46,1.4,12.81,1.26,19.41-.15,6.78,3.16,15.37-1.71,19.86-4.45,4.11-12.8,1.29-19.41,1.29-6.61,0-14.96,2.82-19.41-1.29-4.87-4.49-1.68-13.09-1.68-19.86,0-6.76-3.17-15.32,1.67-19.87,4.48-4.22,12.82-.62,19.27-1.56Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default SupportIcon;
