import { useState } from "react";

import * as S from "./styles";
import InputText from "../InputText";
import { Snackbar } from "../../../hooks";
import H3 from "../../atoms/Typography/H3";
import H4 from "../../atoms/Typography/H4";
import { emptyPlataform } from "../../../utils";
import { IRequestAccess } from "../../../types/lead";
import { Analytics, Contact } from "../../../services";
import { grayIbioma } from "../../../constants/colors";
import WhatsappButton from "../../atoms/WhatsappButton";
import AvailableIcons from "../../atoms/AvailableIcons";
import { maskPhoneNumber } from "../../../utils/numbers";
import { CONTACT_PHONE_NUMBER } from "../../../constants";
import { getCookieFromUser } from "../../../utils/cookies";

const validateForm = (data: IRequestAccess): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.name || data.name.length === 0)
    errors.name = [...(errors.name || []), "Nome é obrigatório"];

  if (!data.mail || data.mail.length === 0)
    errors.mail = [...(errors.mail || []), "E-mail é obrigatório"];

  if (
    data.mail &&
    !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(data.mail)
  )
    errors.mail = [...(errors.mail || []), "Verifique o email"];

  if (!data.phone || data.phone.length === 0)
    errors.phone = [...(errors.phone || []), "Telefone é obrigatório"];

  if (!data.company || data.company.length === 0)
    errors.company = [...(errors.company || []), "Empresa é obrigatório"];

  return errors;
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const RequestAccessModal: React.FC<IProps> = ({ isOpen, onClose }) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [contactHref, setContactHref] = useState<string>("");
  const [form, setForm] = useState<IRequestAccess>(emptyPlataform);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  const { newError, newSuccess } = Snackbar.useSnackbar();

  const onChangeHandler = (key: keyof IRequestAccess, value: string) => {
    setForm((curr) => ({
      ...curr,
      [key]: value,
    }));
  };

  const onSubmitHandler = async () => {
    try {
      const currErrors = validateForm(form);

      if (currErrors && Object.keys(currErrors).length) {
        setErrors(currErrors);
        window.scrollTo({ top: 0, behavior: "smooth" });
        return alert(
          "O formulário possui erros, por favor verifique os campos para continuar"
        );
      }

      const userId = getCookieFromUser();

      await Analytics.sendClick({
        location: "home-lp",
        user: userId || "",
        eventLabel: "plataform-request-access",
        meta: { stage: "landing-page", section: "plataform", method: "mail" },
      });

      await Contact.sendPlataformRequest({
        ...form,
        userId: userId || "",
        mail: form.mail.toLowerCase().trim(),
      });

      setSubmitted(true);

      const url = `https://wa.me/${CONTACT_PHONE_NUMBER}`;

      const text = `
        Olá! Meu nome é ${form.name}, demonstrei interesse na plataforma e gostaria de falar mais.
      `;

      const encodedText = encodeURIComponent(text);

      setContactHref(url + "?text=" + encodedText);

      newSuccess("Obrigado pelo interesse! Entraremos em contato em breve.");
    } catch (error) {
      newError(
        "Sentimos muito, mas houve um erro! Tente novamente mais tarde."
      );
    }

    return;
  };

  const onContactHandler = async () => {
    const userId = getCookieFromUser();

    await Analytics.sendClick({
      user: userId || "",
      location: "home-lp",
      eventLabel: "get-in-touch",
      meta: { section: "contact-us", method: "whatsapp" },
    });
  };

  return (
    <S.Content isOpen={isOpen}>
      <S.Backdrop onClick={onClose} />

      <S.Modal>
        <S.FormBox>
          {submitted ? (
            <S.SubmittedBox>
              <S.SubmittedTexts>
                <H3 color="#000000" fontWeight="bold">
                  Obrigado pelo interesse!
                </H3>

                <H3 color="#000000" fontWeight="bold">
                  Entraremos em contato em breve, com novidades.
                </H3>
              </S.SubmittedTexts>

              <S.ContactBox>
                <H3 color="#000000">
                  Caso queira, entre em contato pelo Whatsapp
                </H3>

                <WhatsappButton
                  type="large"
                  href={contactHref}
                  text="Entrar em contato"
                  onClick={() => onContactHandler()}
                />
              </S.ContactBox>

              <S.IbiomaLogo>
                <AvailableIcons option="ibioma" />
              </S.IbiomaLogo>
            </S.SubmittedBox>
          ) : (
            <>
              <S.Title>
                <H4 color="#000000">
                  Em breve, teremos a <span>plataforma</span> no ar!
                </H4>

                <H4 color="#000000">
                  Preencha abaixo para podermos entrar em contato quando
                  acontecer.
                </H4>
              </S.Title>

              <S.Form>
                <InputText
                  border={false}
                  borderRadius="24px"
                  errors={errors.name}
                  value={form.name || ""}
                  fixedPlaceholder="Nome:"
                  backgroundColor={grayIbioma}
                  onChange={(val) => onChangeHandler("name", val)}
                />

                <InputText
                  border={false}
                  borderRadius="24px"
                  errors={errors.company}
                  value={form.company || ""}
                  fixedPlaceholder="Empresa:"
                  backgroundColor={grayIbioma}
                  onChange={(val) => onChangeHandler("company", val)}
                />

                <InputText
                  border={false}
                  borderRadius="24px"
                  errors={errors.mail}
                  value={form.mail || ""}
                  fixedPlaceholder="E-mail:"
                  backgroundColor={grayIbioma}
                  onChange={(val) => onChangeHandler("mail", val)}
                />

                <InputText
                  border={false}
                  charLimit={15}
                  borderRadius="24px"
                  errors={errors.phone}
                  fixedPlaceholder="Telefone:"
                  backgroundColor={grayIbioma}
                  value={maskPhoneNumber(form.phone || "")}
                  onChange={(val) =>
                    onChangeHandler("phone", val.replace(/[^0-9]/g, ""))
                  }
                />
              </S.Form>

              <S.Button onClick={() => onSubmitHandler()}>enviar</S.Button>
            </>
          )}
        </S.FormBox>
      </S.Modal>
    </S.Content>
  );
};

export default RequestAccessModal;
