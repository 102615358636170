import * as S from "./styles";
import Button from "../../atoms/Button";
import InputText from "../../molecules/InputText";
import { IInputValues } from "../../../types/event";
import AvailableIcons from "../../atoms/AvailableIcons";
import { primaryColor } from "../../../constants/colors";
import InputNumberBase from "../../atoms/InputNumberBase";

interface IProps {
  buttonLabel: string;
  addValues: () => void;
  values: IInputValues[] | undefined;
  removeValues: (index: number) => void;
  changeValues: (index: number, key: keyof IInputValues, value: string) => void;
}

const AddInputs: React.FC<IProps> = ({
  values,
  addValues,
  buttonLabel,
  changeValues,
  removeValues,
}) => {
  return (
    <S.Container>
      <S.Values>
        {values &&
          values.length > 0 &&
          values.map((item, index) => (
            <S.Content key={index}>
              <S.Inputs>
                <InputText
                  value={item.label}
                  onChange={(val) => changeValues(index, "label", val)}
                />

                <InputNumberBase
                  type="Km"
                  value={item.value}
                  onChange={(val) => changeValues(index, "value", val)}
                />
              </S.Inputs>

              <S.RemoveIcon onClick={() => removeValues(index)}>
                <AvailableIcons option="close" color="#ff000080" />
              </S.RemoveIcon>
            </S.Content>
          ))}
      </S.Values>

      <S.AddButton onClick={() => addValues()}>
        <Button
          variant="outline"
          borderType="dashed"
          textColor={primaryColor}
          borderColor={primaryColor}
        >
          {buttonLabel}
        </Button>
      </S.AddButton>
    </S.Container>
  );
};

export default AddInputs;
