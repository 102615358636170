import { ReactElement } from "react";

import { TEmissionsSources } from "../../../types";

import GasIcon from "../../icons/GasIcon";
import FuelIcon from "../../icons/FuelIcon";
import HerdsIcon from "../../icons/HerdsIcon";
import WheatIcon from "../../icons/WheatIcon";
import EnergyIcon from "../../icons/EnergyIcon";
import FlightsIcon from "../../icons/FlightsIcon";
import ResiduesIcon from "../../icons/ResiduesIcon";
import FugitivesIcon from "../../icons/FugitivesIcon";
import TransportIcon from "../../icons/TransportIcon";
import GeneratorIcon from "../../icons/GeneratorIcon";
import DeliveriesIcon from "../../icons/DeliveriesIcon";
import ProductionIcon from "../../icons/ProductionIcon";
import ThirdPartiesIcon from "../../icons/ThirdPartiesIcon";
import PreservationIcon from "../../icons/PreservationIcon";
import DeforestationIcon from "../../icons/DeforestationIcon";

interface IProps {
  option: TEmissionsSources;
}

const CalculatorResultIcons: React.FC<IProps> = ({ option }) => {
  const icons: { [key in TEmissionsSources]: ReactElement } = {
    gas: <GasIcon />,
    fuel: <FuelIcon />,
    herds: <HerdsIcon />,
    crops: <WheatIcon />,
    energy: <EnergyIcon />,
    flights: <FlightsIcon />,
    residues: <ResiduesIcon />,
    generator: <GeneratorIcon />,
    fugitives: <FugitivesIcon />,
    transport: <TransportIcon />,
    production: <ProductionIcon />,
    deliveries: <DeliveriesIcon />,
    thirdParties: <ThirdPartiesIcon />,
    preservation: <PreservationIcon />,
    deforestation: <DeforestationIcon />,
  };

  return icons[option] || null;
};

export default CalculatorResultIcons;
