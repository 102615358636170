/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

import {
  IContactForm,
  IRequestAccess,
  INewsletterForm,
} from "../../types/lead";

const { REACT_APP_CALCULATOR_BASE_URL } = process.env;

const sendInformation = async (formData: IContactForm) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CALCULATOR_BASE_URL}/contact`,
      {
        formData,
      }
    );

    return data;
  } catch (error) {
    throw new Error("Houve um erro ao enviar os dados de contato");
  }
};

const sendPlataformRequest = async (formData: IRequestAccess) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CALCULATOR_BASE_URL}/plataform-request`,
      {
        formData,
      }
    );

    return data;
  } catch (error) {
    throw new Error("Houve um erro ao requisitar acesso à plataforma");
  }
};

const sendNewsletterInterest = async (formData: INewsletterForm) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CALCULATOR_BASE_URL}/create-news`,
      {
        formData,
      }
    );

    return data;
  } catch (error) {
    throw new Error("Houve um erro ao enviar o interesse na Newsletter");
  }
};

export default {
  sendInformation,
  sendPlataformRequest,
  sendNewsletterInterest,
};
