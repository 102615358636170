import { useEffect, useState } from "react";

import * as S from "./styles";
import { IContent } from "../../../../types";
import { Analytics } from "../../../../services";
import H1 from "../../../../components/atoms/Typography/H1";
import H2 from "../../../../components/atoms/Typography/H2";
import H3 from "../../../../components/atoms/Typography/H3";
import H4 from "../../../../components/atoms/Typography/H4";
import H5 from "../../../../components/atoms/Typography/H5";
import { primaryColor } from "../../../../constants/colors";
import { getCookieFromUser } from "../../../../utils/cookies";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";
import LPContentSlider from "../../../../components/organisms/LPContentSlider";

interface IProps {
  onClickCalculators: () => void;
}

const Advantages: React.FC<IProps> = ({ onClickCalculators }) => {
  const [boxWidth, setBoxWidth] = useState<number>(416);

  useEffect(() => {
    const handleResize = () => {
      const width = (() => {
        if (window.innerWidth <= 520) return 316;

        return 416;
      })();
      setBoxWidth(width);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const steps: IContent[] = [
    {
      icon: "usingDevice",
      description:
        "Controle o processo de compra dos créditos, do começo ao fim, com transparência",
    },
    {
      icon: "portfolioFolder",
      description:
        "Compre um único projeto qualificado ou crie um portfólio variado de acordo com suas preferências",
    },
    {
      icon: "seal",
      description:
        "Os certificados de aposentadoria* dos créditos são gerados automaticamente após a compra",
    },
  ];

  const content = [
    {
      label: "vantagem_1",
      content: (
        <S.AdvantageBox>
          <H3 color="#000000" fontWeight="bold">
            Preços mais acessíveis do mercado
          </H3>

          <S.AdvantageIcon>
            <AvailableIcons option="payment" />
          </S.AdvantageIcon>

          <H5 color="#00000080">
            Constantemente revemos nossos preços para garantir que você pague o
            melhor preço possível pelo seu crédito
          </H5>
        </S.AdvantageBox>
      ),
    },
    {
      label: "vantagem_2",
      content: (
        <S.AdvantageBox>
          <H3 color="#000000" fontWeight="bold">
            Créditos de Alta Qualidade
          </H3>

          <S.AdvantageIcon>
            <AvailableIcons option="qualitySeal" />
          </S.AdvantageIcon>

          <H5 color="#00000080">
            Créditos adquiridos na Ibioma possuem um processo rigoroso de
            seleção
          </H5>
        </S.AdvantageBox>
      ),
    },
    {
      label: "vantagem_3",
      content: (
        <S.AdvantageBox>
          <H3 color="#000000" fontWeight="bold">
            Processo Automatizado
          </H3>

          <S.AdvantageIcon>
            <AvailableIcons option="automatedProcess" />
          </S.AdvantageIcon>

          <H5 color="#00000080">
            Todo processo da compra pode ser feito online e diretamente em nossa
            plataforma, sem precisar de nenhum auxilio ou horas de consultoria.
          </H5>
        </S.AdvantageBox>
      ),
    },
    {
      label: "vantagem_4",
      content: (
        <S.AdvantageBox>
          <H3 color="#000000" fontWeight="bold">
            Pacote de Marketing Completo para sua empresa
          </H3>

          <S.AdvantageIcon>
            <AvailableIcons option="marketingPackage" />
          </S.AdvantageIcon>

          <H5 color="#00000080">
            Pagina de Sustentabilidade com Logo da Sua Empresa
          </H5>
          <H5 color="#00000080">Pacote de Comunicação Interna</H5>

          <H5 color="#00000080">Pacote para redes sociais</H5>

          <H5 color="#00000080">
            Pacote de apresentação com esforços de sustentabilidade
          </H5>
        </S.AdvantageBox>
      ),
    },
  ];

  const onClickHandler = async () => {
    const userId = getCookieFromUser();

    await Analytics.sendClick({
      location: "home-lp",
      user: userId || "",
      eventLabel: "cta-compensate-home",
      meta: {
        stage: "landing-page",
        section: "advantages",
        action: "calculators-modal",
      },
    });

    onClickCalculators();
  };

  return (
    <S.Container>
      <H1 color="#000000" fontWeight="bold">
        compre créditos de projetos de alta integridade em poucos cliques
      </H1>

      <S.Information>
        <S.TextsAndCTA>
          <S.TextsAndIcons>
            {steps.map((item, index) => (
              <S.TextAndIcon key={`${item.icon}#${index}`}>
                <S.Icon>
                  <AvailableIcons option={item.icon} color="#000000" />
                </S.Icon>

                <H5 color="#00000080">{item.description}</H5>
              </S.TextAndIcon>
            ))}
          </S.TextsAndIcons>

          <S.CTA onClick={() => onClickHandler()}>
            <H4 color={primaryColor} fontWeight="bold">
              calcule suas emissões
            </H4>

            <S.ButtonIcon>
              <AvailableIcons option="mathOps" />
            </S.ButtonIcon>
          </S.CTA>
        </S.TextsAndCTA>

        <S.Advantages>
          <H2 color="#000000" fontWeight="bold">
            vantagens de comprar com a IBIOMA
          </H2>

          <LPContentSlider
            content={content}
            clearPadding={true}
            imageWidth={boxWidth}
            hideIndicator={false}
          />
        </S.Advantages>
      </S.Information>
    </S.Container>
  );
};

export default Advantages;
