import * as S from "./styles";
import {
  TUnits,
  TStates,
  IProduction,
  ICalculatorForm,
} from "../../../../../../types";
import {
  states,
  statesCities,
  productionType,
  transportUnitsObj,
  productionProducts,
} from "../../../../../../constants";
import {
  maskCPFOrCNPJ,
  maskPhoneNumber,
} from "../../../../../../utils/numbers";
import {
  textColor,
  primaryColor,
  backgroundColor,
} from "../../../../../../constants/colors";
import { ProductionErrors } from "../..";
import Button from "../../../../../../components/atoms/Button";
import H2 from "../../../../../../components/atoms/Typography/H2";
import InputText from "../../../../../../components/molecules/InputText";
import OptionsList from "../../../../../../components/molecules/OptionsList";
import DropdownMenu from "../../../../../../components/organisms/DropdownMenu";
import AvailableIcons from "../../../../../../components/atoms/AvailableIcons";
import InputSliceAndNumber from "../../../../../../components/organisms/InputSliceAndNumber";

interface IProps {
  search?: string;
  filteredOptions?: string[];
  onClearSearch?: () => void;
  onSearch?: (value: string, opt: string[]) => void;
  question: number;
  cpfOrCnpj: string;
  production?: boolean;
  onAheadClick: () => void;
  booleanOptions: string[];
  setProduction: () => void;
  cpfOrCnpjError?: string[];
  transportOptions: string[];
  airConditioningType: number;
  formFields: ICalculatorForm;
  onPreviousClick: () => void;
  onAddProduction: () => void;
  onCalculateClick: () => void;
  productionData?: IProduction[];
  airConditioningTypes: string[];
  errors: { [key: string]: string[] };
  setCpfOrCnpj: (val: string) => void;
  productionErrors?: ProductionErrors[];
  onRemoveProduction: (val: number) => void;
  setAirConditioningType: (index: number) => void;
  onChangeProductionForm: (
    key: keyof IProduction,
    index: number,
    value: string | number
  ) => void;
  onChangeForm: (
    key: keyof ICalculatorForm,
    value: string | number | boolean
  ) => void;
}

const Questions: React.FC<IProps> = ({
  errors,
  search,
  onSearch,
  question,
  cpfOrCnpj,
  formFields,
  production,
  setCpfOrCnpj,
  onAheadClick,
  onChangeForm,
  setProduction,
  onClearSearch,
  cpfOrCnpjError,
  productionData,
  booleanOptions,
  filteredOptions,
  onAddProduction,
  onPreviousClick,
  productionErrors,
  transportOptions,
  onCalculateClick,
  onRemoveProduction,
  airConditioningType,
  airConditioningTypes,
  setAirConditioningType,
  onChangeProductionForm,
}) => {
  const content = () => {
    switch (question) {
      case 1:
        return (
          <>
            <S.Box>
              <S.QuestionTitle>
                <H2 color={textColor} fontWeight="bold">
                  Pessoas e rotina
                </H2>
              </S.QuestionTitle>

              <S.Questions>
                <S.Question>
                  <InputSliceAndNumber
                    type="number"
                    minValue={1}
                    maxValue={500}
                    value={formFields.employees}
                    label="Quantas pessoas trabalham em sua empresa?"
                    onChange={(val) => onChangeForm("employees", val)}
                  />
                </S.Question>

                <S.Question>
                  <InputSliceAndNumber
                    type="%"
                    maxValue={100}
                    value={formFields.homeOffice || "0"}
                    onChange={(val) => onChangeForm("homeOffice", val)}
                    label="Quanto tempo (em %) seus colaboradores trabalham de HomeOffice?"
                  />
                </S.Question>
              </S.Questions>
            </S.Box>

            <S.Box>
              <S.QuestionTitle>
                <H2 color={textColor} fontWeight="bold">
                  Deslocamentos
                </H2>
              </S.QuestionTitle>

              <S.Questions>
                <S.Question>
                  <InputSliceAndNumber
                    maxValue={20000}
                    unitSelector={true}
                    units={transportOptions}
                    value={formFields.fuelExpenses || "0,00"}
                    type={
                      transportOptions[
                        Object.values(transportUnitsObj).indexOf(
                          formFields.fuelUnit || "R$"
                        )
                      ] as TUnits
                    }
                    selectedUnit={Object.values(transportUnitsObj).indexOf(
                      formFields.fuelUnit || "R$"
                    )}
                    selectUnit={(val) => onChangeForm("fuelUnit", val)}
                    onChange={(val) => onChangeForm("fuelExpenses", val)}
                    label="Se você possui frota própria, qual é a média mensal de gastos com combustíveis? (Indique a unidade de medida entre R$, Km ou L)"
                  />
                </S.Question>

                <S.Question>
                  <InputSliceAndNumber
                    maxValue={20000}
                    unitSelector={true}
                    units={transportOptions}
                    value={formFields.transportationExpenses || "0,00"}
                    selectUnit={(val) =>
                      onChangeForm("transportationUnit", val)
                    }
                    onChange={(val) =>
                      onChangeForm("transportationExpenses", val)
                    }
                    selectedUnit={Object.values(transportUnitsObj).indexOf(
                      formFields.transportationUnit || "R$"
                    )}
                    type={
                      transportOptions[
                        Object.values(transportUnitsObj).indexOf(
                          formFields.transportationUnit || "R$"
                        )
                      ] as TUnits
                    }
                    label="Qual é a média mensal de gastos com combustíveis para o deslocamento de colaboradores em veículos próprios, táxis, transportes por aplicativo etc.? (Indique a unidade de medida entre R$, Km ou L)"
                  />
                </S.Question>

                <S.Question>
                  <InputSliceAndNumber
                    maxValue={20000}
                    unitSelector={true}
                    units={transportOptions}
                    value={formFields.publicTransportationExpenses || "0,00"}
                    selectUnit={(val) =>
                      onChangeForm("publicTransportationUnit", val)
                    }
                    onChange={(val) =>
                      onChangeForm("publicTransportationExpenses", val)
                    }
                    selectedUnit={Object.values(transportUnitsObj).indexOf(
                      formFields.publicTransportationUnit || "R$"
                    )}
                    type={
                      transportOptions[
                        Object.values(transportUnitsObj).indexOf(
                          formFields.publicTransportationUnit || "R$"
                        )
                      ] as TUnits
                    }
                    label="Qual é a média mensal de gastos com combustíveis para o deslocamento de colaboradores em transportes públicos? (Indique a unidade de medida entre R$, Km ou L)"
                  />
                </S.Question>
              </S.Questions>
            </S.Box>
          </>
        );

      case 2:
        return (
          <>
            <S.Box>
              <S.QuestionTitle>
                <H2 color={textColor} fontWeight="bold">
                  Viagens aéreas (considere ida e volta como 2 voos)
                </H2>
              </S.QuestionTitle>

              <S.Questions>
                <S.Question>
                  <InputSliceAndNumber
                    type="number"
                    maxValue={100}
                    value={formFields.numberOfShortFlights || "0"}
                    onChange={(val) =>
                      onChangeForm("numberOfShortFlights", val)
                    }
                    label="Número de voos curtos (até 3 horas de voo) - média mensal"
                  />
                </S.Question>

                <S.Question>
                  <InputSliceAndNumber
                    type="number"
                    maxValue={100}
                    value={formFields.numberOfMediumFlights || "0"}
                    onChange={(val) =>
                      onChangeForm("numberOfMediumFlights", val)
                    }
                    label="Número de voos médios (até 6 horas de voo) - média mensal"
                  />
                </S.Question>

                <S.Question>
                  <InputSliceAndNumber
                    type="number"
                    maxValue={100}
                    value={formFields.numberOfLongFlights || "0"}
                    onChange={(val) => onChangeForm("numberOfLongFlights", val)}
                    label="Número de voos longos (mais de 6 horas de voo) - média mensal"
                  />
                </S.Question>
              </S.Questions>
            </S.Box>

            <S.Box>
              <S.QuestionTitle>
                <H2 color={textColor} fontWeight="bold">
                  Gastos do escritório
                </H2>
              </S.QuestionTitle>

              <S.Questions>
                <S.Question>
                  <InputSliceAndNumber
                    option2="R$"
                    option1="KWh"
                    maxValue={20000}
                    unitSwitcher={true}
                    isOn={formFields.energyUnit === "R$"}
                    type={formFields.energyUnit || "KWh"}
                    value={formFields.energyExpenses || "0,00"}
                    setIsOn={(val) => onChangeForm("energyUnit", val)}
                    onChange={(val) => onChangeForm("energyExpenses", val)}
                    label="Qual é a média mensal de gastos com energia elétrica em sua empresa? (Indique a unidade de medida entre R$ ou KWh)"
                  />
                </S.Question>

                <S.Question>
                  <InputSliceAndNumber
                    option2="R$"
                    option1="Kg"
                    maxValue={1000}
                    unitSwitcher={true}
                    value={formFields.gasExpenses || "0,00"}
                    type={formFields.cookingGasUnit || "Kg"}
                    isOn={formFields.cookingGasUnit === "R$"}
                    onChange={(val) => onChangeForm("gasExpenses", val)}
                    setIsOn={(val) => onChangeForm("cookingGasUnit", val)}
                    label="Qual é a média mensal de gastos com gás de cozinha em sua empresa? (Indique a unidade de medida entre R$ ou Kg)"
                  />
                </S.Question>
              </S.Questions>
            </S.Box>
          </>
        );

      case 3:
        return (
          <S.ResidualsEmissions>
            <S.QuestionTitle>
              <H2 color={textColor} fontWeight="bold">
                Emissões residuais
              </H2>
            </S.QuestionTitle>

            <S.Questions>
              <S.Question>
                <OptionsList
                  options={airConditioningTypes}
                  selectedItem={airConditioningType}
                  label="Qual o tipo do seu Ar-condicionado?"
                  setSelectedItem={(val) => setAirConditioningType(val)}
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  option1="Kg"
                  option2="un."
                  maxValue={50}
                  unitSwitcher={true}
                  type={formFields.airConditioningUnit || "Kg"}
                  isOn={formFields.airConditioningUnit === "un."}
                  value={formFields.airConditioningRefills || "0,00"}
                  setIsOn={(val) => onChangeForm("airConditioningUnit", val)}
                  onChange={(val) =>
                    onChangeForm("airConditioningRefills", val)
                  }
                  label="Quantidade de recargas de Ar-condicionado no último mês"
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  option1="Kg"
                  option2="un."
                  maxValue={50}
                  unitSwitcher={true}
                  type={formFields.fireExtinguisherUnit || "Kg"}
                  isOn={formFields.fireExtinguisherUnit === "un."}
                  value={formFields.fireExtinguisherRefills || "0,00"}
                  setIsOn={(val) => onChangeForm("fireExtinguisherUnit", val)}
                  onChange={(val) =>
                    onChangeForm("fireExtinguisherRefills", val)
                  }
                  label="Quantidade de recargas de extintor no último mês"
                />
              </S.Question>
            </S.Questions>
          </S.ResidualsEmissions>
        );

      case 4:
        return (
          <S.Box marginTop="60px">
            <S.QuestionTitle>
              <H2 color={textColor} fontWeight="bold">
                Resíduos sólidos
              </H2>
            </S.QuestionTitle>

            <S.Questions>
              <S.Question>
                <InputSliceAndNumber
                  type="Kg"
                  maxValue={20000}
                  value={formFields.organicWaste || "0,00"}
                  onChange={(val) => onChangeForm("organicWaste", val)}
                  label="Quantos Kg de lixo Orgânico foram gerados pela empresa no último mês?"
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  type="Kg"
                  maxValue={20000}
                  value={formFields.recyclableTrash || "0,00"}
                  onChange={(val) => onChangeForm("recyclableTrash", val)}
                  label="Quantos Kg de lixo Reciclável foram gerados pela empresa no último mês?"
                />
              </S.Question>
            </S.Questions>
          </S.Box>
        );

      case 5:
        return (
          <S.Box marginTop="60px">
            <S.QuestionTitle>
              <H2 color={textColor} fontWeight="bold">
                Produção
              </H2>
            </S.QuestionTitle>

            <S.Questions>
              <S.Question>
                <OptionsList
                  options={booleanOptions}
                  label="Possui produção?"
                  selectedItem={production ? 0 : 1}
                  setSelectedItem={() => setProduction()}
                />
              </S.Question>
            </S.Questions>

            {production &&
              productionData &&
              productionData.map((item, index) => (
                <>
                  {index > 0 && <S.SeparatorLine />}

                  <S.ProductionBox key={`${item}#${index}`}>
                    <S.QuestionsProduction>
                      <S.DropdownQuestion>
                        <DropdownMenu
                          labelWeight="regular"
                          labelColor={textColor}
                          label="Tipo de produção"
                          options={productionType}
                          selected={item.production || ""}
                          placeholder="Selecione a produção"
                          onSelect={(val) =>
                            onChangeProductionForm("production", index, val)
                          }
                          errors={[
                            (productionErrors &&
                              productionErrors[index]?.production) ||
                              "",
                          ]}
                        />
                      </S.DropdownQuestion>

                      <S.DropdownQuestion>
                        <DropdownMenu
                          labelWeight="regular"
                          label="Tipo de produto"
                          labelColor={textColor}
                          selected={item.product || ""}
                          placeholder="Selecione o produto"
                          onSelect={(val) =>
                            onChangeProductionForm("product", index, val)
                          }
                          options={
                            productionProducts[item.production || "Bebidas"] ||
                            ""
                          }
                          errors={[
                            (productionErrors &&
                              productionErrors[index]?.product) ||
                              "",
                          ]}
                        />
                      </S.DropdownQuestion>

                      {item.production === "Têxtil" ? (
                        <S.Question>
                          <InputSliceAndNumber
                            type="m²"
                            gap="10px"
                            slicer={false}
                            maxValue={20000}
                            label="Metragem de tecido"
                            value={item.dimension || "0,00"}
                            onChange={(val) =>
                              onChangeProductionForm("dimension", index, val)
                            }
                            errors={[
                              (productionErrors &&
                                productionErrors[index]?.dimension) ||
                                "",
                            ]}
                          />
                        </S.Question>
                      ) : (
                        <>
                          <S.Question>
                            <InputSliceAndNumber
                              type="mL"
                              gap="10px"
                              slicer={false}
                              label="Volume"
                              maxValue={20000}
                              value={item.dimension || "0,00"}
                              errors={[
                                (productionErrors &&
                                  productionErrors[index]?.dimension) ||
                                  "",
                              ]}
                              onChange={(val) =>
                                onChangeProductionForm("dimension", index, val)
                              }
                            />
                          </S.Question>

                          <S.Question>
                            <InputSliceAndNumber
                              gap="10px"
                              type="number"
                              slicer={false}
                              maxValue={20000}
                              label="Quantidade"
                              value={item.quantity || "0"}
                              onChange={(val) =>
                                onChangeProductionForm("quantity", index, val)
                              }
                              errors={[
                                (productionErrors &&
                                  productionErrors[index]?.quantity) ||
                                  "",
                              ]}
                            />
                          </S.Question>
                        </>
                      )}
                    </S.QuestionsProduction>

                    <S.RemoveIcon onClick={() => onRemoveProduction(index)}>
                      <AvailableIcons option="close" color="#ff0000" />
                    </S.RemoveIcon>
                  </S.ProductionBox>
                </>
              ))}

            {production && (
              <S.AddButton onClick={() => onAddProduction()}>
                <Button
                  variant="outline"
                  borderType="dashed"
                  textColor={primaryColor}
                  borderColor={primaryColor}
                >
                  Adicionar produção
                </Button>
              </S.AddButton>
            )}
          </S.Box>
        );

      case 6:
        return (
          <S.Box>
            <S.QuestionTitle>
              <H2 color={textColor} fontWeight="bold">
                Informações da empresa
              </H2>
            </S.QuestionTitle>

            <S.CompanyQuestions>
              <S.CompanyQuestion maxWidth="440px">
                <InputText
                  labelColor={textColor}
                  label="Nome da empresa"
                  errors={errors.companyName}
                  value={formFields.companyName}
                  onChange={(val) => onChangeForm("companyName", val)}
                />
              </S.CompanyQuestion>

              <S.CompanyQuestion maxWidth="260px">
                <DropdownMenu
                  labelWeight="bold"
                  labelColor={textColor}
                  selectorType="vertical"
                  label="Segmento da empresa"
                  errors={errors.companyVertical}
                  placeholder="Selecione o segmento"
                  selected={formFields.companyVertical}
                  onSelect={(val) => onChangeForm("companyVertical", val)}
                />
              </S.CompanyQuestion>

              <S.CompanyQuestion maxWidth="360px">
                <InputText
                  charLimit={18}
                  label="CPF ou CNPJ"
                  labelColor={textColor}
                  errors={cpfOrCnpjError}
                  value={maskCPFOrCNPJ(cpfOrCnpj)}
                  onChange={(val) => setCpfOrCnpj(val.replace(/[^0-9]/g, ""))}
                />
              </S.CompanyQuestion>

              <S.CompanyQuestion maxWidth="200px">
                <DropdownMenu
                  search={search}
                  useSearch={true}
                  options={states}
                  labelWeight="bold"
                  labelColor={textColor}
                  label="Estado da empresa"
                  errors={errors.companyState}
                  placeholder="Selecione o estado"
                  filteredOptions={filteredOptions}
                  selected={formFields.companyState}
                  onSearch={(val) => onSearch && onSearch(val, states)}
                  onSelect={(val) => onChangeForm("companyState", val)}
                  onClearSearch={() => onClearSearch && onClearSearch()}
                />
              </S.CompanyQuestion>

              <S.CompanyQuestion maxWidth="220px">
                <DropdownMenu
                  search={search}
                  infoIcon={true}
                  useSearch={true}
                  labelWeight="bold"
                  labelColor={textColor}
                  label="Cidade da empresa"
                  errors={errors.companyCity}
                  placeholder="Selecione a cidade"
                  filteredOptions={filteredOptions}
                  selected={formFields.companyCity}
                  onSelect={(val) => onChangeForm("companyCity", val)}
                  onClearSearch={() => onClearSearch && onClearSearch()}
                  options={statesCities[formFields.companyState as TStates]}
                  info="Caso não encontre sua cidade, selecione aquela mais próxima"
                  onSearch={(val) =>
                    onSearch &&
                    onSearch(
                      val,
                      statesCities[formFields.companyState as TStates]
                    )
                  }
                />
              </S.CompanyQuestion>

              <S.CompanyQuestion maxWidth="440px">
                <InputText
                  labelColor={textColor}
                  label="Nome para contato"
                  errors={errors.contactName}
                  value={formFields.contactName}
                  onChange={(val) => onChangeForm("contactName", val)}
                />
              </S.CompanyQuestion>

              <S.CompanyQuestion maxWidth="240px">
                <InputText
                  type="tel"
                  charLimit={15}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  labelColor={textColor}
                  errors={errors.contactPhone}
                  label="Telefone para contato"
                  value={maskPhoneNumber(formFields.contactPhone)}
                  onChange={(val) =>
                    onChangeForm(
                      "contactPhone",
                      (val || "").replace(/[^0-9]/g, "")
                    )
                  }
                />
              </S.CompanyQuestion>

              <S.CompanyQuestion maxWidth="480px">
                <InputText
                  labelColor={textColor}
                  label="E-mail para contato"
                  errors={errors.contactMail}
                  value={formFields.contactMail}
                  onChange={(val) => onChangeForm("contactMail", val)}
                />
              </S.CompanyQuestion>
            </S.CompanyQuestions>
          </S.Box>
        );

      default:
        return;
    }
  };

  return (
    <S.Container>
      {content()}

      <S.Buttons>
        <S.Clickable
          variant="solid"
          fontWeight="bold"
          borderColor={primaryColor}
          isVisible={question !== 6}
          textColor={backgroundColor}
          backgroundColor={primaryColor}
          onClick={() => onAheadClick()}
        >
          Próximo
        </S.Clickable>

        <S.Clickable
          variant="solid"
          fontWeight="bold"
          borderColor={primaryColor}
          isVisible={question === 6}
          textColor={backgroundColor}
          backgroundColor={primaryColor}
          onClick={() => onCalculateClick()}
        >
          Calcular
        </S.Clickable>

        <S.Clickable
          variant="solid"
          fontWeight="bold"
          textColor={primaryColor}
          isVisible={question > 1}
          borderColor={primaryColor}
          backgroundColor={backgroundColor}
          onClick={() => onPreviousClick()}
        >
          Anterior
        </S.Clickable>
      </S.Buttons>
    </S.Container>
  );
};

export default Questions;
