import styled, { css } from "styled-components";

import { secondaryColor } from "../../../constants/colors";

type ContentProps = {
  isOpen: boolean;
};

export const Content = styled.div<ContentProps>`
  ${({ isOpen }) => css`
    position: relative;
    align-items: center;
    justify-content: center;
    display: ${isOpen ? "flex" : "none"};
  `}
`;

export const Backdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: #00000080;
`;

export const CloseIcon = styled.div`
  top: 12px;
  right: 12px;
  width: 16px;
  height: 16px;
  display: flex;
  cursor: pointer;
  position: absolute;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Modal = styled.div`
  top: 24vh;
  gap: 32px;
  z-index: 10;
  display: flex;
  position: fixed;
  padding: 36px 32px;
  text-align: center;
  border-radius: 12px;
  flex-direction: column;
  background-color: #fafafa;

  h3 {
    max-width: 300px;
  }
`;

export const Calculators = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

export const Calculator = styled.div`
  gap: 12px;
  width: 100%;
  display: flex;
  cursor: pointer;
  user-select: none;
  padding: 8px 20px;
  border-radius: 24px;
  align-items: center;
  background-color: ${secondaryColor};
`;

export const Icon = styled.div`
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
