import styled, { css } from "styled-components";

type ContainerProps = {
  image: string;
};

export const Container = styled.div<ContainerProps>`
  ${({ image }) => css`
    width: 100%;
    height: 240px;
    background: url(${image});
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 500px) {
      background-position: center left;
    }
  `}
`;
