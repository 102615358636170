import styled from "styled-components";

export const Container = styled.div`
  gap: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Options = styled.div`
  gap: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
