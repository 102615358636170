import { primaryColor } from "../../constants/colors";

interface IProps {
  color?: string;
}

const CO2RemovalIcon: React.FC<IProps> = ({ color }) => {
  return (
    <svg
      id="Camada_1"
      data-name="Camada 1"
      viewBox="0 0 1000 1000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="B5K2Hx">
        <g>
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m424,802.75c10.43-9.85,17.02-16.36,23.92-22.52,6.39-5.71,13.66-9.38,20.83-1.74,6.56,7,5.08,14.74-1.21,21.18-15.12,15.49-30.43,30.8-45.91,45.93-7.61,7.45-15.98,7.83-23.77.17-15.81-15.54-31.44-31.28-47.1-46.98-6.62-6.64-7.92-13.9-.67-20.69,6.72-6.3,13.73-4.51,19.53,1.64,7.14,7.57,13.82,15.58,20.71,23.39,1.47-.84,2.94-1.69,4.41-2.53,0-30.87.17-61.75-.1-92.62-.09-10.32-8.4-9.12-15.12-9.13-48.05-.07-96.1-.25-144.14.03-97.52.57-173.25-62.15-190.51-158.1-15.08-83.82,37.22-171.82,120.59-201.81,11.28-4.06,16.97-8.58,17.14-21.95,1.07-84,65.15-159.56,147.89-174.03,76.82-13.43,139.19,11.9,186.34,73.8,6.67,8.75,10.68,11.11,21.2,4.74,103.33-62.6,231.53-17.25,272.52,96.31,3.85,10.65,9.4,15.94,19.98,19.51,83.03,27.98,134.26,106.96,126.57,194.03-7.39,83.75-74.15,152.8-160.72,164.9-43.54,6.08-87.5,1.32-131.26,2.43-16.36.42-32.74-.17-49.1.11-9.53.16-14.79-2.87-15-13.46-.22-10.74,4.07-15.58,14.78-15.55,48.04.16,96.18-1.64,144.11.66,68.73,3.3,130.93-33.5,157.9-96.96,26.06-61.31,9.79-132.28-40.66-177.79-19.62-17.7-42.19-29.9-67.96-35.54-11.76-2.57-17.67-8.77-20.98-20.38-32.43-113.78-159.88-155.63-252.31-83.14-14.88,11.67-20.65,10.5-30.65-6.94-31.05-54.18-78.19-82.15-140.13-82.52-85.02-.52-155.04,70.44-155.53,155.42-.02,4.22-.35,8.5.18,12.66,1.92,15.01-5.22,21.44-19.42,25.16-74.07,19.41-121.92,83.29-119.9,158.71,2.03,75.83,62.36,143.86,137.95,149.04,58.79,4.03,118.05,1.27,177.1,1.68,6.83.05,9.49-2.82,9.4-9.43-.16-11.61-.13-23.23.06-34.85.18-10.55-.97-23.75,13.74-24.06,15.91-.33,15.02,13.75,15.1,24.6.34,43.82.16,87.65.18,131.47,0,13.46,0,26.92,0,45.1Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m526.44,575.3c-58.77-.44-109.3-51.23-108.89-109.46.43-60.37,49.6-108.99,110.45-109.19,58.76-.2,109.08,50.24,108.89,109.14-.19,59.62-50.95,109.95-110.44,109.51Zm-79.73-111.04c-.5,45.59,34.08,81.91,79.32,83.3,43.7,1.34,81.34-35.97,81.67-80.95.34-45.61-33.73-80.93-78.58-81.45-46.69-.55-81.91,33.25-82.42,79.11Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m550.67,858.73c6.89-6.56,6.05-9.9,6.06-12.91.07-43.79-.21-87.59.21-131.38.11-11.48-3.74-16.05-15.56-15.81-27.95.57-55.93,0-83.89.24-10.54.09-18.46-.79-18.47-14.83,0-14.66,9.13-14.27,18.99-14.27,27.39,0,54.78-.07,82.17.01,8.1.03,14.86-1.24,21.33-7.63,9.44-9.32,20.24-5.33,23.35,7.87,1.31,5.55,1.08,11.52,1.09,17.3.08,50.13.05,100.25.05,150.38,0,5.65,0,11.29,0,21.62,10.21-9.82,17.1-16.66,24.23-23.24,6.51-6.01,13.93-8.65,20.89-1.3,6.71,7.07,4.7,14.8-1.5,21.14-15.48,15.85-31.14,31.54-47.03,46.98-7.17,6.97-15.31,6.98-22.53-.08-15.84-15.49-31.51-31.17-47.02-46.99-6.23-6.36-8.3-13.98-1.52-21.07,7-7.31,14.75-5,20.81,1.4,6.5,6.86,11.99,14.67,18.33,22.57Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m288.98,465.82c.15,55.53,52.54,93.44,106.38,77.03,10.29-3.14,23.35-10.48,28.23,4.85,5.02,15.77-10.11,18.53-20.78,22.02-45.05,14.69-93.96-1.93-121.77-40.92-27.31-38.29-26.49-90.45,2.02-128.66,28.02-37.54,78.75-52.91,123.14-37.22,3.95,1.4,7.93,3.06,11.42,5.33,6.47,4.2,9.45,10.22,5.63,17.59-3.6,6.95-9.7,9.73-17.26,6.73-11.87-4.72-23.87-7.71-36.86-7.55-44.49.55-80.27,36.37-80.15,80.8Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m702.13,573.69c8.31.66,16.1.87,23.76,2.01,7.5,1.12,12.76,5.28,12.61,13.66-.15,8.28-5.49,13.22-12.89,13.46-20.01.66-40.15,1.3-60.06-.3-11.1-.89-16.03-9.35-11.48-20.99,5.59-14.3,14.47-25.58,28.75-32.16,5.72-2.63,11.62-5.21,16.71-8.82,7.48-5.31,13.16-13.06,5.26-21.09-7.37-7.5-17.11-7.3-25.41.4-1.5,1.39-2.34,3.49-3.49,5.26-4.41,6.72-10.54,8.92-17.75,5.47-8.45-4.05-8.28-11.61-5.89-19.09,4.97-15.55,25.48-27.7,43.19-25.98,21.51,2.08,37.56,15.64,40.35,34.06,2.96,19.49-5.33,33.62-20.79,44.62-4.09,2.91-9.95,3.45-12.87,9.49Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default CO2RemovalIcon;
