interface IProps {
  color?: string;
}

const FacilitiesIcon: React.FC<IProps> = ({ color }) => {
  return (
    <svg
      width="52"
      height="52"
      fill="none"
      viewBox="0 0 52 52"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.5 25.7533C51.5 39.6959 40.0878 51.0066 26 51.0066C11.9122 51.0066 0.5 39.6959 0.5 25.7533C0.5 11.8108 11.9122 0.5 26 0.5C40.0878 0.5 51.5 11.8108 51.5 25.7533Z"
        stroke={color || "#000000"}
      />
      <path
        d="M26 16L16 21.7778V36H22.6667L25.8889 32.6667L29.3333 36H36V21.7778L26 16ZM21.4444 34.8889V28.2222L24.7778 31.5556L21.4444 34.8889ZM22.5556 27.1111H29.2222L25.8889 30.4444L22.5556 27.1111ZM30.3333 34.8889L27 31.5556L30.3333 28.2222V34.8889ZM29.3333 24.8889H22.4444V22.6667H29.3333V24.8889Z"
        fill={color || "#000000"}
      />
    </svg>
  );
};

export default FacilitiesIcon;
