import * as S from "./styles";
import P from "../../atoms/Typography/P";
import H4 from "../../atoms/Typography/H4";
import H5 from "../../atoms/Typography/H5";
import AvailableIcons from "../../atoms/AvailableIcons";

import ibioma from "../../../components/assets/ibioma_logo_preto.png";

const Footer: React.FC = () => {
  return (
    <S.Footer>
      <S.LogoAndRights>
        <S.IbiomaLogo image={ibioma} />

        <P color="#000000">
          ©Ibioma {new Date().getFullYear()} - Todos os direitos reservados
        </P>
      </S.LogoAndRights>

      <S.Information>
        <S.Text>
          <H4 color="#000000" fontWeight="bold">
            Ibioma
          </H4>

          <H5 color="#000000">
            Av. Cidade Jardim, 377, 4° andar, Jardim Paulistano, São Paulo - SP
            01453-900 | Brasil
          </H5>
        </S.Text>

        <S.Text>
          <H4 color="#000000" fontWeight="bold">
            OMA Ativos Ambientais
          </H4>

          <H5 color="#000000">
            Av. Cidade Jardim, 377, 4° andar, Jardim Paulistano, São Paulo - SP
            01453-900 | Brasil
          </H5>
        </S.Text>
      </S.Information>

      <S.SocialMediaContainer>
        <H4 color="#000000" fontWeight="bold">
          Redes sociais
        </H4>

        <S.SocialMediaIcons>
          <S.Icon
            href="https://www.instagram.com/ibiomabr/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <AvailableIcons option="instagram" color="#fafafa" />
          </S.Icon>

          <S.Icon
            href="https://www.linkedin.com/company/ibioma/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <AvailableIcons option="linkedin" color="#fafafa" />
          </S.Icon>

          <S.Icon
            href="https://twitter.com/OMAgroupbr"
            target="_blank"
            rel="noreferrer noopener"
          >
            <AvailableIcons option="twitter" color="#fafafa" />
          </S.Icon>

          <S.Icon
            href="https://www.facebook.com/ibiomabr"
            target="_blank"
            rel="noreferrer noopener"
          >
            <AvailableIcons option="facebook" color="#fafafa" />
          </S.Icon>
        </S.SocialMediaIcons>
      </S.SocialMediaContainer>
    </S.Footer>
  );
};

export default Footer;
