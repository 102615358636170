import { primaryColor } from "../../constants/colors";

interface IProps {
  color?: string;
}

const MathOpsIcon: React.FC<IProps> = ({ color }) => {
  return (
    <svg
      id="Camada_1"
      data-name="Camada 1"
      viewBox="0 0 1000 1000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="RSYvg8.tif">
        <g>
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m824.04,523.8c0,89.27-.07,178.55.07,267.82.02,13.5-4.1,25.5-11.43,36.44-8.47,12.65-20.07,21.58-34.39,26.9-6.69,2.49-13.58,3.58-20.74,3.58-89.6-.06-179.2-.03-268.8-.03-89.27,0-178.55-.06-267.82.05-13.39.02-25.27-4.28-36.06-11.57-12.49-8.44-21.4-19.95-26.68-34.17-2.41-6.49-3.58-13.13-3.58-20.07.05-90.14.03-180.29.03-270.43,0-87.2.13-174.41-.15-261.61-.05-14.28,2.71-27.39,10.35-39.39,8.52-13.38,20.32-22.8,35.08-28.55,7.3-2.84,14.86-3.71,22.67-3.71,177.79.06,355.57.08,533.36,0,22.61-.01,40.23,9.62,53.98,26.87,9.49,11.91,14.25,25.53,14.22,41.04-.17,88.95-.08,177.89-.09,266.84Zm-319.68-160.39c0,46.92,0,93.84,0,140.77,0,4.53.06,4.59,4.54,4.59,13.61.01,27.22,0,40.83,0,79.91,0,159.82,0,239.73,0,4.84,0,5-.11,5-4.16,0-83.28-.01-166.57-.04-249.85,0-1.41,0-2.86-.32-4.22-4.08-17.91-20.33-32.16-40.58-32.05-81.32.43-162.65.18-243.98.18q-5.18,0-5.19,5.28c0,46.49,0,92.97,0,139.46Zm-30.05.4c0-46.81,0-93.63,0-140.44,0-4.5-.15-4.69-4.14-4.69-83.28,0-166.57.01-249.85.03-1.41,0-2.86,0-4.22.31-17.82,4.01-32.19,20.43-32.08,40.57.44,81.32.18,162.65.18,243.97q0,5.2,5.26,5.21c93.19,0,186.38,0,279.58,0q5.26,0,5.27-5.18c0-46.6,0-93.19,0-139.79Zm-145.08,175.02c-46.81,0-93.63,0-140.44,0-4.42,0-4.58.14-4.58,4.25,0,83.17,0,166.35.03,249.52,0,1.52,0,3.08.34,4.54,4.05,17.83,20.49,32.09,40.66,31.97,81.32-.45,162.65-.19,243.97-.19q5.08,0,5.1-5.05c0-93.3,0-186.6,0-279.9q0-5.15-5.29-5.16c-46.6,0-93.19,0-139.79,0Zm320.16,0c-46.81,0-93.63,0-140.44,0-4.45,0-4.58.11-4.58,4.23,0,93.84,0,187.69,0,281.53,0,4.11.21,4.34,4.16,4.34,83.18,0,166.35,0,249.53-.03,1.52,0,3.08,0,4.54-.33,17.86-4.02,32.16-20.48,32.05-40.59-.44-81.32-.18-162.65-.18-243.97q0-5.17-5.3-5.18c-46.6,0-93.19,0-139.79,0Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m656.57,355.87c18.62,0,37.24.19,55.85-.1,6.95-.11,13.84,5.32,14.68,13.34.75,7.16-4.08,13.99-11.35,15.76-2.65.65-5.32,1.06-8.07,1.05-34.08-.02-68.16.01-102.24-.04-6.23,0-12.22-.93-16.39-6.38-3.59-4.68-4.05-9.92-1.76-15.1,2.3-5.2,6.39-8.51,12.44-8.52,18.95-.02,37.89,0,56.84-.02Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m284,355.87c6.21,0,12.41,0,18.62,0,4.34,0,4.44-.1,4.44-4.34.01-12.19-.05-24.39.03-36.58.05-8.3,6.5-14.96,14.48-15.19,8.43-.24,15.45,6.52,15.51,15.16.09,12.19.02,24.39.03,36.58,0,4.27.11,4.37,4.41,4.37,12.3.01,24.61-.02,36.91.02,7.65.03,13.32,5.43,14.18,13.39.75,6.89-4.07,13.75-10.86,15.48-3.07.79-6.17,1.19-9.36,1.17-10.45-.06-20.9.06-31.35-.07-2.99-.04-4,1.01-3.97,3.99.12,11.87.07,23.73.04,35.6-.03,8.88-6.43,15.42-15.01,15.42-8.66,0-14.99-6.45-15.02-15.39-.04-11.76,0-23.52-.02-35.27,0-4.09-.19-4.28-4.21-4.29-10.89-.02-21.78.09-32.66-.05-5.88-.08-11.51-1.12-15.49-6.2-3.65-4.66-4.18-9.89-1.96-15.08,2.28-5.34,6.45-8.69,12.65-8.7,6.21-.01,12.41,0,18.62,0h0Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m378.98,648.7c-.15,4.53-1.91,7.78-4.55,10.7-8.9,9.87-18.64,18.89-27.92,28.38-3.21,3.28-3.21,3.23.09,6.6,9.36,9.56,19.18,18.67,28.14,28.63,4.43,4.93,5.37,10.3,2.64,15.92-2.67,5.51-8.75,9.32-14.46,8.89-3.86-.29-6.97-2.21-9.66-4.92-9.29-9.33-18.61-18.63-27.93-27.93-2.89-2.88-3.07-2.84-5.99,0-9.99,9.7-19.31,20.09-29.83,29.23-4.58,3.98-9.89,5.09-15.42,2.05-5.5-3.03-8.95-7.61-8.77-14.18.1-3.88,2.1-7,4.78-9.72,9.24-9.38,18.45-18.78,27.68-28.16,3.35-3.4,3.37-3.39.08-6.69-9.38-9.39-18.81-18.74-28.13-28.19-4.73-4.8-5.66-10.14-3.08-15.83,2.51-5.53,8.55-9.5,14.24-9.24,3.77.17,6.79,1.98,9.54,4.45,9.95,8.96,19.04,18.8,28.62,28.14,3.06,2.99,3.03,3,6.13-.06,9.54-9.38,19.03-18.81,28.65-28.11,4.42-4.27,9.6-5.97,15.51-3.16,5.74,2.73,9.03,7.28,9.62,13.19Z"
          />
          <path
            className="cls-1"
            d="m656.56,634.22c18.61,0,37.22-.03,55.83.02,6.12.01,11.94,4.18,13.83,9.68,2.15,6.27.44,12.82-4.49,16.86-2.78,2.28-6.04,3.46-9.63,3.47-37,.02-74,.05-111-.01-8.62-.01-15.23-6.8-15.1-15.15.13-8.43,6.71-14.84,15.39-14.85,18.39-.03,36.78-.01,55.17,0Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m656.57,717.83c18.61,0,37.22-.03,55.83.02,6.11.01,11.94,4.19,13.82,9.69,2.15,6.27.43,12.83-4.51,16.85-2.79,2.27-6.04,3.46-9.63,3.46-37,.02-74,.05-111-.01-8.62-.01-15.22-6.82-15.09-15.17.14-8.44,6.71-14.82,15.41-14.84,18.39-.03,36.78,0,55.17,0Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default MathOpsIcon;
