import * as S from "./styles";

interface IProps {
  currentQuestion: number;
  numberOfQuestions: number;
}

const ProgressBar: React.FC<IProps> = ({
  currentQuestion,
  numberOfQuestions,
}) => {
  return (
    <S.ProgressBar>
      <S.Progress progress={(100 * currentQuestion) / numberOfQuestions} />
    </S.ProgressBar>
  );
};

export default ProgressBar;
