import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import { IContent } from "../../../types";
import H1 from "../../atoms/Typography/H1";
import H3 from "../../atoms/Typography/H3";
import H4 from "../../atoms/Typography/H4";
import H5 from "../../atoms/Typography/H5";
import LPContentSlider from "../LPContentSlider";
import mock from "../../assets/mocks/calculator.png";
import AvailableIcons from "../../atoms/AvailableIcons";
import { primaryColor } from "../../../constants/colors";
import { TIconOptions } from "../../../types/iconOptions";
import mockLog from "../../assets/mocks/calculator_log.png";
import mockAgro from "../../assets/mocks/calculator_agro.png";
import mockEvent from "../../assets/mocks/calculator_eventos.png";

interface ICalculators {
  label: string;
  route: string;
  icon: TIconOptions;
}

const HowCompensate: React.FC = () => {
  const [hover, setHover] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<number>(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (hover) return;

    if (!hover) {
      const interval = setInterval(() => {
        setSelectedItem((curr) => (curr + 1) % steps.length);
      }, 4000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hover]);

  const steps: IContent[] = [
    {
      icon: "form",
      title: "Detalhe",
      description:
        "Preencha o formulário com maior detalhamento possível de sua operação",
    },
    {
      title: "Calcule",
      icon: "calculator",
      description: "Calcule suas emissões e prepare-se para a mudança",
    },
    {
      icon: "co2Cycle",
      title: "Compense",
      description:
        "Compense sua pegada de carbono e contribua para um mundo mais sustentável",
    },
    {
      title: "Comunique",
      icon: "comunication",
      description:
        "Comunique a seus clientes e parceiros sobre sua iniciativa sustentável e se destaque frente aos seus concorrentes",
    },
  ];

  const calculators: ICalculators[] = [
    { icon: "buildings", label: "sua empresa", route: "calculadora" },
    {
      icon: "engineCycle",
      label: "sua logística",
      route: "calculadora-logistica",
    },
    { icon: "agriculture", label: "suas terras", route: "calculadora-agro" },
    { icon: "ticket", label: "seu evento", route: "calculadora-eventos" },
  ];

  const content = [
    {
      label: "mock",
      content: <S.Mockup key="item1" mockup={mock} />,
    },
    {
      label: "mockLog",
      content: <S.Mockup key="item2" mockup={mockLog} />,
    },
    {
      label: "mockAgro",
      content: <S.Mockup key="item3" mockup={mockAgro} />,
    },
    {
      label: "mockEvent",
      content: <S.Mockup key="item4" mockup={mockEvent} />,
    },
  ];

  const onEnterMouse = (index: number) => {
    setHover(true);
    setSelectedItem(index);
  };

  return (
    <S.Container>
      <S.Information>
        <S.Texts>
          <H1 color="#000000" fontWeight="bold">
            como faço para compensar?
          </H1>

          <S.TextsAndIcons>
            {steps.map((item, index) => (
              <S.TextAndIcon
                key={`${item.icon}#${index}`}
                isSelected={selectedItem === index}
                onMouseLeave={() => setHover(false)}
                onMouseEnter={() => onEnterMouse(index)}
              >
                <S.Icon>
                  <AvailableIcons option={item.icon} color="#000000" />
                </S.Icon>

                <S.Text>
                  <H4 color="#000000" fontWeight="bold">
                    {item.title}
                  </H4>

                  <H5 color="#00000080">{item.description}</H5>
                </S.Text>
              </S.TextAndIcon>
            ))}
          </S.TextsAndIcons>
        </S.Texts>

        <S.Neutralize>
          <H3 color="#000000" fontWeight="bold">
            Neutralize
          </H3>

          <S.Calculators>
            {calculators.map((item, index) => (
              <S.Calculator
                key={`${item.label}#${index}`}
                onClick={() => navigate(`/${item.route}`)}
              >
                <S.CalculatorLabel>
                  <H3 color={primaryColor} fontWeight="bold">
                    {item.label}
                  </H3>
                </S.CalculatorLabel>

                <S.CalculatorIcon>
                  <AvailableIcons option={item.icon} color="#000000" />
                </S.CalculatorIcon>
              </S.Calculator>
            ))}
          </S.Calculators>
        </S.Neutralize>
      </S.Information>

      <S.Mocks>
        <LPContentSlider
          blur={true}
          imageWidth={328}
          content={content}
          clearPadding={true}
          hideIndicator={false}
        />
      </S.Mocks>
    </S.Container>
  );
};

export default HowCompensate;
