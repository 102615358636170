import styled, { css } from "styled-components";

import Small from "../../atoms/Typography/Small";

type ContainerProps = {
  gap: string;
};

export const Container = styled.div<ContainerProps>`
  ${({ gap }) => css`
    gap: ${gap};
    width: 100%;
    display: flex;
    flex-direction: column;
  `}
`;

export const LabelBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InfoIcon = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  transform: rotate(180deg);

  svg {
    width: 16px;
    height: 16px;
  }

  :hover {
    span {
      display: flex;
    }
  }
`;

export const InfoBox = styled.span`
  top: -160px;
  padding: 8px;
  left: -240px;
  width: 240px;
  display: none;
  font-size: 12px;
  position: absolute;
  border-radius: 8px;
  text-align: justify;
  transform: rotate(180deg);
  background-color: #fafafa;
`;

export const Inputs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 840px) {
    gap: 20px;
    justify-content: flex-start;
  }

  @media (max-width: 730px) {
    gap: 12px;
  }
`;

export const InputValueAndUnit = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

type ErrorProps = {
  width: string;
};

export const ErrorMessage = styled(Small)<ErrorProps>`
  ${({ width }) => css`
    width: ${width};
    font-size: 0.75rem;
  `}
`;
