import styled, { css } from "styled-components";
import H4 from "../../atoms/Typography/H4";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

type LabelProps = {
  labelSize: "medium" | "small";
};

export const Label = styled(H4)<LabelProps>`
  ${({ labelSize }) => css`
    font-size: ${labelSize === "small" ? "0.75rem" : "inherit"};
  `}
`;
