import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import H3 from "../../atoms/Typography/H3";
import H4 from "../../atoms/Typography/H4";
import { Analytics } from "../../../services";
import AvailableIcons from "../../atoms/AvailableIcons";
import { primaryColor } from "../../../constants/colors";
import { getCookieFromUser } from "../../../utils/cookies";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const CalculatorModal: React.FC<IProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const onClickHandler = async (label: string) => {
    navigate(`/${label}`);

    const userId = getCookieFromUser();

    await Analytics.sendClick({
      user: userId || "",
      location: "home-lp",
      eventLabel: `go-to-${label}`,
      meta: { stage: "landing-page", section: "calculator-modal" },
    });
  };

  return (
    <S.Content isOpen={isOpen}>
      <S.Backdrop onClick={onClose} />

      <S.Modal>
        <S.CloseIcon onClick={onClose}>
          <AvailableIcons option="close" color="#00000080" />
        </S.CloseIcon>

        <H3 color="#000000">Selecione a calculadora de interesse:</H3>

        <S.Calculators>
          <S.Calculator onClick={() => onClickHandler("calculadora")}>
            <S.Icon>
              <AvailableIcons option="buildings" />
            </S.Icon>

            <H4 color={primaryColor} fontWeight="bold">
              Administrativa
            </H4>
          </S.Calculator>

          <S.Calculator onClick={() => onClickHandler("calculadora-logistica")}>
            <S.Icon>
              <AvailableIcons option="engineCycle" />
            </S.Icon>

            <H4 color={primaryColor} fontWeight="bold">
              Logística
            </H4>
          </S.Calculator>

          <S.Calculator onClick={() => onClickHandler("calculadora-agro")}>
            <S.Icon>
              <AvailableIcons option="agriculture" />
            </S.Icon>

            <H4 color={primaryColor} fontWeight="bold">
              Agro
            </H4>
          </S.Calculator>

          <S.Calculator onClick={() => onClickHandler("calculadora-eventos")}>
            <S.Icon>
              <AvailableIcons option="ticket" />
            </S.Icon>

            <H4 color={primaryColor} fontWeight="bold">
              Eventos
            </H4>
          </S.Calculator>
        </S.Calculators>
      </S.Modal>
    </S.Content>
  );
};

export default CalculatorModal;
