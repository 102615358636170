/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useState,
  useEffect,
  TouchEventHandler,
  MouseEventHandler,
} from "react";

import * as S from "./styles";
import { secondaryColor } from "../../../constants/colors";

interface IContent {
  label: string;
  content: any;
}

interface IProps {
  blur?: boolean;
  imageWidth?: number;
  content: IContent[];
  startPosition?: number;
  clearPadding?: boolean;
  hideIndicator: boolean;
}

const MIN_DISTANCE_TO_SWIPE = 40;

const LPContentSlider: React.FC<IProps> = ({
  content,
  clearPadding,
  blur = false,
  hideIndicator,
  startPosition,
  imageWidth = 1140,
}) => {
  const [currentContent, setCurrentContent] = useState(startPosition || 0);
  const [movementStartPosition, setMovementStartPosition] = useState(
    startPosition || 0
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentContent((curr) => (curr + 1) % content.length);
    }, 5000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSwipeRightHandler = () => {
    if (currentContent < content.length - 1)
      setCurrentContent((curr) => curr + 1);
  };

  const onSwipeLeftHandler = () => {
    if (currentContent > 0) setCurrentContent((curr) => curr - 1);
  };

  const onTouchStartHandler: TouchEventHandler<HTMLDivElement> = (e) => {
    setMovementStartPosition(e.changedTouches[0].clientX);
  };

  const onTouchEndHandler: TouchEventHandler<HTMLDivElement> = (e) => {
    const endPosition = e.changedTouches[0].clientX;

    if (Math.abs(endPosition - movementStartPosition) > MIN_DISTANCE_TO_SWIPE) {
      if (endPosition > movementStartPosition) {
        onSwipeLeftHandler();
      } else onSwipeRightHandler();
    }
  };

  const onMouseDownHandler: MouseEventHandler<HTMLDivElement> = (e) => {
    setMovementStartPosition(e.clientX);
  };

  const onMouseUpHandler: MouseEventHandler<HTMLDivElement> = (e) => {
    const endPosition = e.clientX;

    if (Math.abs(endPosition - movementStartPosition) > MIN_DISTANCE_TO_SWIPE) {
      if (endPosition > movementStartPosition) {
        onSwipeLeftHandler();
      } else onSwipeRightHandler();
    }
  };

  return (
    <S.ContentSlider>
      <S.Container
        centralize={hideIndicator}
        onMouseUp={onMouseUpHandler}
        clearPadding={!!clearPadding}
        onTouchEnd={onTouchEndHandler}
        onMouseDown={onMouseDownHandler}
        onTouchStart={onTouchStartHandler}
        transformValue={currentContent * imageWidth}
        paddingLeft={`${(content.length - 1) * imageWidth}px`}
      >
        {content.map((item, index) => (
          <S.ContentBox
            blur={blur}
            key={`${item.label}#${index}`}
            isSelected={currentContent === index}
          >
            {item.content}
          </S.ContentBox>
        ))}
      </S.Container>

      {!hideIndicator && (
        <S.Indicators>
          {Array(content.length)
            .fill(null)
            .map((_, index) => (
              <S.Indicator
                key={index}
                selectedColor={secondaryColor}
                notSelectedColor="#fafafa40"
                isSelected={index === currentContent}
                onClick={() => setCurrentContent(index)}
              />
            ))}
        </S.Indicators>
      )}
    </S.ContentSlider>
  );
};

export default LPContentSlider;
