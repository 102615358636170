import styled from "styled-components";

type H4Props = {
  color: string;
  fontWeight?: string;
};

const H4 = styled.h4<H4Props>`
  font-size: 1rem;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
`;

export default H4;
