import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";

import Router from "./router";
import { Loading, Snackbar } from "./hooks";
import { GlobalStyle } from "./globalStyles";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <GlobalStyle />
    <HelmetProvider>
      <Loading.Provider>
        <Snackbar.Provider>
          <Router />
        </Snackbar.Provider>
      </Loading.Provider>
    </HelmetProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
