import { primaryColor } from "../../constants/colors";

interface IProps {
  color?: string;
}

const AddBoxIcon: React.FC<IProps> = ({ color }) => {
  return (
    <svg
      id="Camada_1"
      data-name="Camada 1"
      viewBox="0 0 1000 1000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="lhlsQY.tif">
        <g>
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m293.04,181h317.44c14.43,2.95,23.74,11.81,30.84,24.5,36.94,66.08,74.29,131.94,111.47,197.89,6.97,12.36,10.55,25.55,10.4,39.88-.26,24.22.17,48.44-.22,72.66-.12,7.17,2.25,11.49,8.05,15.86,64.71,48.7,93.71,113.49,82.2,193.85-21.53,150.33-199.66,221.72-319.68,128.72-7.55-5.85-14.94-8.21-24.33-8.19-108.01.24-216.03.16-324.04.14-25.26,0-36.64-8.33-44.16-32.1v-380.93c2.62-10.8,5.64-21.37,11.22-31.2,36.97-65.23,73.91-130.49,110.46-195.96,7.05-12.63,16.2-21.63,30.36-25.12Zm159.32,279.75c-80.45,0-160.91.06-241.36-.1-7.36-.01-11.12,1.31-11.1,10.02.25,102.17.21,204.34.06,306.51-.01,7.06,2.06,9.91,9.51,9.89,86.02-.18,172.04-.1,258.07-.14,8.55,0,8.78-.53,5.23-8.6-14.51-33-20.97-67.29-16.74-103.34,8.05-68.69,42.22-120.53,102-154.74,42.4-24.26,88.32-31,136.33-21.55,7.39,1.46,9.79-.96,9.59-7.89-.17-5.84,0-11.69-.02-17.54-.05-12.37-.18-12.52-12.7-12.52-79.62,0-159.24,0-238.86,0Zm61.67,234.07c-.11,78.39,62.65,141.97,140.37,142.18,78.4.22,142.52-63.24,142.6-141.12.08-78.23-62.81-141.65-140.68-141.86-79.06-.21-142.18,62.24-142.29,140.8Zm-192.9-292.36c30.59,0,61.19-.13,91.78.1,6.92.05,10.02-1.83,9.98-9.41-.25-47.84-.23-95.68,0-143.51.03-7.24-2.42-9.76-9.68-9.7-31.15.28-62.3.26-93.45,0-6.13-.05-9.66,2.28-12.57,7.46-27.47,49-55.12,97.9-82.67,146.85-4.31,7.66-4,8.18,4.83,8.19,30.59.05,61.19.02,91.78.02Zm262.21,0c30.04,0,60.08.02,90.11-.01,10.14-.01,10.63-.81,5.73-9.51-27.3-48.45-54.71-96.85-81.85-145.39-3.14-5.61-6.95-7.67-13.3-7.61-30.31.26-60.63.1-90.95.13-10.48.01-11.01.48-11.02,10.75-.04,47-.04,94.01,0,141.01,0,10.15.55,10.62,11.15,10.63,30.04.03,60.08.01,90.11.01Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m749,694.87c.26,15.64-12.25,29.06-27.99,29.63-9.17.33-18.37.34-27.54.03-6.37-.21-9.19,2.06-8.94,8.69.33,8.89.22,17.81.01,26.71-.36,15.75-12.15,27.99-27.73,29.06-14.68,1.01-27.84-10-30.4-25.31-1.48-8.86-.76-17.77-.88-26.66-.17-12.16-.33-12.38-12.85-12.41-7.51-.02-15.03.16-22.54-.08-15.29-.5-27.39-12.31-28.43-27.49-1.01-14.78,9.97-28.12,25.08-30.59,9.13-1.49,18.33-.74,27.49-.94,10.15-.21,10.99-1.02,11.22-10.95.21-9.17-.56-18.36.9-27.5,2.43-15.22,15.52-26.21,30.35-25.36,15.47.89,27.38,13.21,27.77,29,.22,8.9.22,17.81.04,26.71-.12,5.69,2.43,7.98,7.99,8.1,10.55.22,21.16-.82,31.66.99,14.28,2.46,24.54,14.22,24.77,28.36Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default AddBoxIcon;
