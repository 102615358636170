/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

import { ICalculatorForm } from "../../types";
import { IAgroCalculatorForm } from "../../types/agro";
import { IEventCalculatorForm } from "../../types/event";
import { ILogisticCalculatorForm } from "../../types/logistic";

const { REACT_APP_CALCULATOR_BASE_URL } = process.env;

const sendInformation = async (formData: ICalculatorForm) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CALCULATOR_BASE_URL}/public-calculator`,
      {
        formData,
      }
    );

    return data.result;
  } catch (error) {
    throw new Error("Houve um erro ao calcular suas emissões");
  }
};

const sendEventInformation = async (formData: IEventCalculatorForm) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CALCULATOR_BASE_URL}/event-calculator`,
      {
        formData,
      }
    );

    return data.result;
  } catch (error) {
    throw new Error("Houve um erro ao calcular suas emissões");
  }
};

const sendAgroInformation = async (formData: IAgroCalculatorForm) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CALCULATOR_BASE_URL}/agro-calculator`,
      {
        formData,
      }
    );

    return data.result;
  } catch (error) {
    throw new Error("Houve um erro ao calcular suas emissões");
  }
};

const sendLogisticInformation = async (formData: ILogisticCalculatorForm) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CALCULATOR_BASE_URL}/logistic-calculator`,
      {
        formData,
      }
    );

    return data.result;
  } catch (error) {
    throw new Error("Houve um erro ao calcular suas emissões");
  }
};

export default {
  sendInformation,
  sendAgroInformation,
  sendEventInformation,
  sendLogisticInformation,
};
