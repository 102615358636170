import { ReactElement } from "react";

import { TIconOptions } from "../../../types/iconOptions";

import Chevron from "../../icons/Chevron";
import FormIcon from "../../icons/FormIcon";
import SealIcon from "../../icons/SealIcon";
import PlusIcon from "../../icons/PlusIcon";
import CloseIcon from "../../icons/CloseIcon";
import AddBoxIcon from "../../icons/AddBoxIcon";
import TicketIcon from "../../icons/TicketIcon";
import TiktokIcon from "../../icons/TiktokIcon";
import IbiomaLogo from "../../icons/IbiomaLogo";
import InfoOneIcon from "../../icons/InfoOneIcon";
import MathOpsIcon from "../../icons/MathOpsIcon";
import TwitterIcon from "../../icons/TwitterIcon";
import SupportIcon from "../../icons/SupportIcon";
import PaymentIcon from "../../icons/PaymentIcon";
import DocumentIcon from "../../icons/DocumentIcon";
import VerifiedIcon from "../../icons/VerifiedIcon";
import WhatsappIcon from "../../icons/WhatsappIcon";
import EmailTwoIcon from "../../icons/EmailTwoIcon";
import LinkedinIcon from "../../icons/LinkedinIcon";
import CO2CycleIcon from "../../icons/CO2CycleIcon";
import FacebookIcon from "../../icons/FacebookIcon";
import BuildingsIcon from "../../icons/BuildingsIcon";
import InstagramIcon from "../../icons/InstagramIcon";
import CoinStackIcon from "../../icons/CoinStackIcon";
import CalculatorIcon from "../../icons/CalculatorIcon";
import CO2RemovalIcon from "../../icons/CO2RemovalIcon";
import DisclosureIcon from "../../icons/DesclosureIcon";
import InfoOutlineIcon from "../../icons/InfoOutlineIcon";
import AgricultureIcon from "../../icons/AgricultureIcon";
import DataCollectIcon from "../../icons/DataCollectIcon";
import EngineCycleIcon from "../../icons/EngineCycleIcon";
import QualitySealIcon from "../../icons/QualitySealIcon";
import UsingDeviceIcon from "../../icons/UsingDeviceIcon";
import ComunicationIcon from "../../icons/ComunicationIcon";
import ReductionOptIcon from "../../icons/ReductionOptIcon";
import ProjectsFolderIcon from "../../icons/ProjectsFolderIcon";
import PortfolioFolderIcon from "../../icons/PortfolioFolderIcon";
import MarketingPackageIcon from "../../icons/MarketingPackageIcon";
import PlataformDevicesIcon from "../../icons/PlataformDevicesIcon";
import AutomatedProcessIcon from "../../icons/AutomatedProcessIcon";

interface IProps {
  color?: string;
  option: TIconOptions;
}

const AvailableIcons: React.FC<IProps> = ({ option, color }) => {
  const icons: { [key in TIconOptions]: ReactElement } = {
    ibioma: <IbiomaLogo />,
    form: <FormIcon color={color} />,
    plus: <PlusIcon color={color} />,
    seal: <SealIcon color={color} />,
    close: <CloseIcon color={color} />,
    chevron: <Chevron color={color} />,
    addBox: <AddBoxIcon color={color} />,
    ticket: <TicketIcon color={color} />,
    tiktok: <TiktokIcon color={color} />,
    mathOps: <MathOpsIcon color={color} />,
    infoOne: <InfoOneIcon color={color} />,
    payment: <PaymentIcon color={color} />,
    twitter: <TwitterIcon color={color} />,
    support: <SupportIcon color={color} />,
    whatsapp: <WhatsappIcon color={color} />,
    linkedin: <LinkedinIcon color={color} />,
    verified: <VerifiedIcon color={color} />,
    facebook: <FacebookIcon color={color} />,
    emailTwo: <EmailTwoIcon color={color} />,
    document: <DocumentIcon color={color} />,
    co2Cycle: <CO2CycleIcon color={color} />,
    instagram: <InstagramIcon color={color} />,
    buildings: <BuildingsIcon color={color} />,
    coinStack: <CoinStackIcon color={color} />,
    calculator: <CalculatorIcon color={color} />,
    co2Removal: <CO2RemovalIcon color={color} />,
    disclosure: <DisclosureIcon color={color} />,
    infoOutline: <InfoOutlineIcon color={color} />,
    agriculture: <AgricultureIcon color={color} />,
    dataCollect: <DataCollectIcon color={color} />,
    engineCycle: <EngineCycleIcon color={color} />,
    qualitySeal: <QualitySealIcon color={color} />,
    usingDevice: <UsingDeviceIcon color={color} />,
    comunication: <ComunicationIcon color={color} />,
    reductionOpt: <ReductionOptIcon color={color} />,
    projectsFolder: <ProjectsFolderIcon color={color} />,
    portfolioFolder: <PortfolioFolderIcon color={color} />,
    marketingPackage: <MarketingPackageIcon color={color} />,
    plataformDevices: <PlataformDevicesIcon color={color} />,
    automatedProcess: <AutomatedProcessIcon color={color} />,
  };

  return icons[option] || null;
};

export default AvailableIcons;
