import styled from "styled-components";

import {
  grayIbioma,
  primaryColor,
  secondaryColor,
} from "../../../../constants/colors";

export const Container = styled.div`
  gap: 60px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 80px 200px;
  flex-direction: column;

  h1 {
    max-width: 920px;
    font-size: 3.5rem;
    padding-right: 80px;
  }

  @media (max-width: 1420px) {
    padding: 80px 100px;

    h1 {
      max-width: 840px;
      padding-right: 0px;
    }
  }

  @media (max-width: 1150px) {
    padding: 80px 80px;
  }

  @media (max-width: 940px) {
    padding: 80px 0;

    h1 {
      padding: 0 40px;
    }
  }

  @media (max-width: 740px) {
    gap: 28px;
  }

  @media (max-width: 580px) {
    h1 {
      font-size: 2.5rem;
    }
  }

  @media (max-width: 500px) {
    padding-top: 40px;
  }

  @media (max-width: 380px) {
    h1 {
      padding: 40px 20px;
    }
  }
`;

export const Information = styled.div`
  gap: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 940px) {
    flex-direction: column;
  }
`;

export const TextsAndCTA = styled.div`
  gap: 40px;
  display: flex;
  max-width: 600px;
  text-align: justify;
  flex-direction: column;

  @media (max-width: 1750px) {
    max-width: 500px;
  }

  @media (max-width: 1650px) {
    max-width: 400px;
  }

  @media (max-width: 940px) {
    padding: 0 40px;
    max-width: 600px;
  }

  @media (max-width: 440px) {
    padding: 0 20px;
  }
`;

export const TextsAndIcons = styled.div`
  gap: 44px;
  display: flex;
  flex-direction: column;
`;

export const TextAndIcon = styled.div`
  gap: 24px;
  display: flex;
  align-items: center;

  h5 {
    color: #00000080;
    transition: color 0.3s ease-in-out;
  }

  span {
    border: 1px solid #000000;
    background-color: #ffffff;

    svg {
      g {
        g {
          path {
            fill: #000000;
          }
        }
      }
    }
  }

  :hover {
    h5 {
      color: #000000;
    }

    span {
      border: none;
      background-color: ${secondaryColor};

      svg {
        g {
          g {
            path {
              fill: ${primaryColor};
            }
          }
        }
      }
    }
  }
`;

export const Icon = styled.span`
  width: 48px;
  content: "";
  height: 80px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  border-radius: 28px;
  justify-content: center;
  border: 1px solid #000000;

  svg {
    width: 44px;
    height: 44px;
  }
`;

export const CTA = styled.div`
  gap: 8px;
  display: flex;
  cursor: pointer;
  user-select: none;
  padding: 8px 12px;
  align-self: center;
  width: fit-content;
  align-items: center;
  border-radius: 24px;
  justify-content: center;
  background-color: ${secondaryColor};
`;

export const ButtonIcon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Advantages = styled.div`
  gap: 20px;
  width: 420px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;

  h2 {
    max-width: 300px;
  }

  @media (max-width: 940px) {
    width: 100%;
  }
`;

export const AdvantageBox = styled.div`
  gap: 8px;
  width: 400px;
  display: flex;
  padding: 20px 40px;
  border-radius: 40px;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  background-color: ${grayIbioma};

  @media (max-width: 520px) {
    width: 300px;
  }
`;

export const AdvantageIcon = styled.div`
  width: 80px;
  height: 140px;
  display: flex;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  background-color: ${secondaryColor};

  svg {
    width: 100%;
    height: 100%;
  }
`;
