import { useEffect, useRef, useState } from "react";

import * as S from "./styles";
import H4 from "../../atoms/Typography/H4";
import InputBase from "../../atoms/InputBase";

type InputTextProps = {
  id?: string;
  value: string;
  rows?: number;
  label?: string;
  height?: string;
  limit?: boolean;
  border?: boolean;
  baseURL?: string;
  errors?: string[];
  charLimit?: number;
  disabled?: boolean;
  labelColor?: string;
  autofocus?: boolean;
  labelWeight?: string;
  placeholder?: string;
  borderRadius?: string;
  allLowerCase?: boolean;
  preventScroll?: boolean;
  backgroundColor?: string;
  fixedPlaceholder?: string;
  as?: "textarea" | "input";
  onChange: (val: string) => void;
  status?: "success" | "error" | "loading" | "";
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">;

const InputText: React.FC<InputTextProps> = ({
  id,
  value,
  label,
  limit,
  status,
  errors,
  height,
  disabled,
  rows = 5,
  onChange,
  autofocus,
  labelColor,
  placeholder,
  borderRadius,
  as = "input",
  allLowerCase,
  preventScroll,
  border = true,
  backgroundColor,
  charLimit = 100,
  fixedPlaceholder,
  labelWeight = "bold",
  baseURL,
  ...props
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const input = useRef<HTMLInputElement>(null);

  const onFocusHandler = () => {
    if (window.navigator.userAgent.includes("Mobi")) {
      document.body.style.paddingBottom = "400px";
      document.body.style.backgroundColor = "#F9FAFB";

      if (!preventScroll) {
        setTimeout(() => {
          if (input.current)
            window.scrollTo({
              left: 0,
              top: input.current?.offsetTop - 100,
              behavior: "smooth",
            });
        }, 10);
      }
    }
  };

  const onBlurHandler = () => {
    if (window.navigator.userAgent.includes("Mobi"))
      document.body.style.paddingBottom = "0";
  };

  const onChangeHandler = (val: string) => {
    if (charLimit)
      return allLowerCase
        ? onChange(val.toLowerCase().substring(0, charLimit))
        : onChange(val.substring(0, charLimit));

    allLowerCase ? onChange(val.toLowerCase()) : onChange(val);
  };

  useEffect(() => {
    if (autofocus && input.current) input.current.focus();
  }, [autofocus]);

  return (
    <S.Container
      height={height}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
    >
      {label || limit ? (
        <S.LabelAndLimit>
          {label ? (
            <H4 color={labelColor || "#fafafa"} fontWeight={labelWeight}>
              {label}
            </H4>
          ) : null}

          {limit ? (
            <S.RightBar>
              <S.CharLimit
                color={"#fafafa"}
              >{`${value.length}/${charLimit}`}</S.CharLimit>
            </S.RightBar>
          ) : null}
        </S.LabelAndLimit>
      ) : null}

      <S.InputAndIcon hasIcon={props.type === "password"} border={border}>
        {baseURL && <S.BaseUrl color="#fafafa60">{baseURL}</S.BaseUrl>}

        {fixedPlaceholder && (
          <S.FixedPlaceholder color="#00000080">
            {fixedPlaceholder}
          </S.FixedPlaceholder>
        )}

        <InputBase
          id={id}
          as={as}
          rows={rows}
          ref={input}
          value={value}
          autoComplete="off"
          disabled={disabled}
          onBlur={onBlurHandler}
          onFocus={onFocusHandler}
          placeholder={placeholder}
          onChange={({ target }: any) => onChangeHandler(target.value)}
          {...props}
          type={
            props.type === "password" && isPasswordVisible ? "text" : props.type
          }
        />
      </S.InputAndIcon>

      {errors && errors.length ? (
        <S.ErrorMessage color="#FF4D4F">
          Erros: {errors.join(", ")}
        </S.ErrorMessage>
      ) : null}
    </S.Container>
  );
};

export default InputText;
