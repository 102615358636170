import styled, { css } from "styled-components";

import Button from "../../../../../../components/atoms/Button";
import { primaryColor } from "../../../../../../constants/colors";
import { Box } from "../../../../../Calculadora/components/Calculator/components/Questions/styles";

export const Container = styled.div`
  gap: 48px;
  width: 100%;
  display: flex;
  padding: 0 300px;
  min-height: 400px;
  flex-direction: column;

  @media (max-width: 1700px) {
    padding: 0 200px;
  }

  @media (max-width: 1400px) {
    padding: 0 100px;
  }

  @media (max-width: 1080px) {
    width: 100%;
  }

  @media (max-width: 930px) {
    padding: 0 80px;
  }

  @media (max-width: 830px) {
    padding: 0 60px;
  }

  @media (max-width: 600px) {
    padding: 0 20px;
  }

  @media (max-width: 480px) {
    padding: 0;
  }
`;

export const QuestionTitle = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

type RefPageProps = {
  color: string;
};

export const RefPage = styled.a<RefPageProps>`
  ${({ color }) => css`
    color: ${color};
    font-size: 1rem;
    cursor: pointer;
    text-decoration: underline;
  `}
`;

export const Questions = styled.div`
  gap: 32px;
  width: 100%;
  display: flex;
  padding: 0 40px;
  flex-direction: column;

  @media (max-width: 580px) {
    padding: 0;
  }
`;

export const LogisticBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const SeparatorLine = styled.span`
  content: "";
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #00000040;
`;

export const QuestionsDeliveries = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 40px;
  justify-content: space-between;

  @media (max-width: 1250px) {
    gap: 20px;
  }

  @media (max-width: 580px) {
    padding: 0;
  }
`;

type QuestionProps = {
  width?: string;
};

export const Question = styled.div<QuestionProps>`
  ${({ width }) => css`
    display: flex;
    flex-direction: column;
    width: ${width && width};
  `}
`;

export const CompanyQuestions = styled.div`
  gap: 32px;
  display: flex;
  flex-wrap: wrap;
  padding: 0 40px;
`;

type CompanyQuestionProps = {
  maxWidth: string;
};

export const CompanyQuestion = styled.div<CompanyQuestionProps>`
  ${({ maxWidth }) => css`
    gap: 32px;
    width: 100%;
    display: flex;
    max-width: ${maxWidth};
  `}
`;

export const AddButton = styled.div`
  width: 100%;
  display: flex;
`;

export const RemoveIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const SliderAndNumber = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Buttons = styled.div`
  display: flex;
  margin-bottom: 40px;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

type ButtonsProps = {
  isVisible: boolean;
};

export const Clickable = styled(Button)<ButtonsProps>`
  ${({ isVisible }) => css`
    width: 126px;
    align-items: center;
    justify-content: center;
    display: ${isVisible ? "flex" : "none"};
  `}
`;

export const ResidualsEmissions = styled.div`
  gap: 40px;
  display: flex;
  margin-top: 0;
  padding: 40px 40px;
  border-radius: 12px;
  flex-direction: column;
  border: 2px solid ${primaryColor};

  @media (max-width: 540px) {
    margin-top: 60px;
  }
`;

type BoxProps = {
  marginTop?: string;
};

export const BoxContent = styled(Box)<BoxProps>`
  ${({ marginTop }) => css`
    margin-top: ${marginTop ? marginTop : 0};
  `}
`;
