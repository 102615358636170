import { useEffect, useState } from "react";

import * as S from "./styles";
import { IFAQ } from "../../../types";
import H1 from "../../atoms/Typography/H1";
import H5 from "../../atoms/Typography/H5";
import AvailableIcons from "../../atoms/AvailableIcons";

const faq: IFAQ[] = [
  {
    question: "O que é a calculadora?",
    answer:
      "Uma calculadora de emissão de crédito de carbono é uma ferramenta capaz de quantificar as emissões de gases de efeito estufa causadas por uma atividade. Instrumento que auxilia empresas, projetos e indivíduos a avaliarem seu impacto ambiental, estabelecerem metas de redução de emissões e calcularem a quantidade de créditos de carbono necessária para neutralizar, ou ao menos compensar, sua operação.",
  },
  {
    question: "Por que devemos calcular nossa pegada de carbono?",
    answer:
      "Calcular nossa pegada de carbono é uma forma de nos reconectarmos com nosso compromisso ambiental, buscando compensar e equilibrar as emissões que causamos.",
  },
  {
    question: "Diretrizes da calculadora",
    answer:
      "As diretrizes da calculadora seguem o Programa Brasileiro GHG Protocol (PBGHG), o qual é responsável pela adaptação do método GHG Protocol ao contexto brasileiro e desenvolvimento de ferramentas de cálculo para estimativas de emissões de gases do efeito estufa (GEE).",
  },
  {
    question: "Entenda os escopos e tipos de emissão",
    answer: [
      {
        title: "Escopo 1 - emissões diretas",
        description:
          "Emissões diretas de GEE da fonte de propriedade ou controle da organização. Isso inclui emissões provenientes de atividades como queima de combustíveis fósseis em instalações, emissões de veículos pertencentes à organização e emissões de processos industriais específicos.",
        examples: [
          "Queima de gás natural para aquecimento em uma fábrica",
          "Emissões de CO2 de uma frota de veículos",
          "Emissões de gases industriais liberados durante a produção.",
        ],
      },
      {
        title: "Escopo 2 - emissões indiretas de energia",
        description:
          "Emissões indiretas de GEE associadas à produção de eletricidade, calor ou vapor adquiridos pela organização. Essas emissões são provenientes da geração de energia por terceiros, mas são consideradas relevantes para a organização, pois são resultado de sua demanda.",
        examples: [
          "Eletricidade comprada de uma rede elétrica que gera energia a partir de carvão ou gás natural",
          "Aquisição de vapor de uma usina de cogeração movida a combustíveis fósseis",
        ],
      },
      {
        title: "Escopo 3 - outras emissões indiretas",
        description:
          "Emissões indiretas de GEE resultantes das atividades da organização, mas que ocorrem em fontes que estão fora do seu controle operacional. Essas emissões abrangem toda a cadeia de valor da organização, desde a produção de matérias-primas até o descarte do produto final.",
        examples: [
          "Emissões provenientes da produção de matérias-primas, transporte de produtos, uso e descarte de produtos pelos consumidores",
          "Viagens aéreas e terrestres de negócios",
          "Desmatamento causado indiretamente por fornecedores da organização.",
        ],
      },
    ],
  },
];

const FAQ: React.FC = () => {
  const [faqSelected, setFaqSelected] = useState<number>();
  const [height, setHeight] = useState<string[]>([
    "700px",
    "700px",
    "700px",
    "1200px",
  ]);

  useEffect(() => {
    if (typeof window !== "undefined")
      if (window.innerWidth < 560) {
        setHeight(["700px", "700px", "700px", "1400px"]);
      }
  }, []);

  const onClickFAQ = (index: number) => {
    faqSelected === index ? setFaqSelected(undefined) : setFaqSelected(index);
  };

  return (
    <S.Container
      height={faqSelected !== undefined ? height[faqSelected] : "600px"}
    >
      <H1 color="#000000" fontWeight="bold">
        dúvidas frequentes
      </H1>

      <S.QuestionsBox>
        {faq.map((item, index) => (
          <S.Faq key={item.question} onClick={() => onClickFAQ(index)}>
            <S.Title isOpen={faqSelected === index}>
              <H5
                color="#000000"
                fontWeight={faqSelected === index ? "bold" : "regular"}
              >
                {item.question}
              </H5>

              <AvailableIcons option="chevron" color="#000000" />
            </S.Title>

            {index === faq.length - 1 ? (
              <S.Description isOpen={faqSelected === index} column={true}>
                {typeof item.answer !== "string" &&
                  item.answer.length > 0 &&
                  item.answer.map((scope) => (
                    <S.ScopesDescription key={scope.title}>
                      <H5 color="#000000" fontWeight="bold">
                        {scope.title}
                      </H5>

                      <H5 color="#000000">{scope.description}</H5>

                      <H5 color="#000000">Exemplos:</H5>
                      {scope.examples.length > 0 &&
                        scope.examples.map((example) => (
                          <H5 color="#000000" key={example}>
                            • {example}
                          </H5>
                        ))}
                    </S.ScopesDescription>
                  ))}
              </S.Description>
            ) : (
              <S.Description isOpen={faqSelected === index}>
                <H5 color="#000000cc">
                  {typeof item.answer === "string" && item.answer}
                </H5>
              </S.Description>
            )}
          </S.Faq>
        ))}
      </S.QuestionsBox>
    </S.Container>
  );
};

export default FAQ;
