import styled, { css } from "styled-components";

import { secondaryColor, primaryColor } from "../../../constants/colors";

export const Container = styled.div`
  gap: 60px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 80px 300px;

  h1 {
    font-size: 3.5rem;
  }

  @media (max-width: 1820px) {
    padding: 80px 200px;
  }

  @media (max-width: 1660px) {
    padding: 80px 100px;
  }

  @media (max-width: 1380px) {
    padding: 80px 40px;
  }

  @media (max-width: 1320px) {
    padding: 80px 0;
    flex-direction: column;
  }
`;

export const Information = styled.div`
  gap: 80px;
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 1320px) {
    gap: 60px;
    padding: 0 100px;
    flex-direction: column;
  }

  @media (max-width: 840px) {
    padding: 0 40px;
  }

  @media (max-width: 500px) {
    padding: 0 20px;
  }
`;

export const Texts = styled.div`
  gap: 40px;
  width: 460px;
  display: flex;
  text-align: justify;
  flex-direction: column;

  @media (max-width: 1320px) {
    width: 100%;
    align-items: center;
    flex-direction: column;

    h1 {
      text-align: center;
      max-width: 800px;
    }
  }

  @media (max-width: 540px) {
    max-width: 400px;
    text-align: start;
  }

  @media (max-width: 480px) {
    max-width: 300px;
  }
`;

export const TextsAndIcons = styled.div`
  gap: 44px;
  display: flex;
  max-width: 400px;
  flex-direction: column;

  @media (max-width: 1320px) {
    gap: 28px;
    max-width: 600px;
    flex-direction: column;
  }

  @media (max-width: 800px) {
    max-width: 500px;
  }

  @media (max-width: 580px) {
    max-width: 100%;
  }
`;

type TextAndIconPrps = {
  isSelected: boolean;
};

export const TextAndIcon = styled.div<TextAndIconPrps>`
  ${({ isSelected }) => css`
    gap: 24px;
    display: flex;
    align-items: center;

    h5 {
      color: #000000;
      transition: color 0.3s ease-in-out;
      color: ${isSelected ? "#000000" : "#00000080"};
    }

    span {
      transition: background-color 0.3s ease-in-out;
      border: ${isSelected ? "none" : "1px solid #000000"};
      background-color: ${isSelected ? `${secondaryColor}` : "white"};

      svg {
        g {
          g {
            path {
              fill: ${isSelected ? `${primaryColor}` : "#000000"};
            }
          }
        }
      }
    }

    :hover {
      h5 {
        color: #000000;
      }

      span {
        border: none;
        background-color: ${secondaryColor};

        svg {
          g {
            g {
              path {
                fill: ${primaryColor};
              }
            }
          }
        }
      }
    }
  `}
`;

export const Text = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
`;

export const Icon = styled.span`
  content: "";
  width: 44px;
  height: 80px;
  display: flex;
  flex-shrink: 0;
  border-radius: 28px;
  align-items: center;
  justify-content: center;
  border: 1px solid #000000;

  svg {
    width: 40px;
    height: 40px;
  }
`;

export const Neutralize = styled.div`
  gap: 40px;
  display: flex;
  text-align: end;
  align-self: flex-end;
  flex-direction: column;

  @media (max-width: 1320px) {
    text-align: center;
    align-self: center;
  }
`;

export const Calculators = styled.div`
  gap: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1320px) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
`;

export const Calculator = styled.div`
  display: flex;
  align-items: center;
`;

export const CalculatorLabel = styled.div`
  width: 200px;
  display: flex;
  padding: 12px 0;
  cursor: pointer;
  user-select: none;
  text-align: center;
  height: fit-content;
  justify-content: center;
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  background-color: ${secondaryColor}80;

  :hover {
    background-color: ${secondaryColor};
  }
`;

export const CalculatorIcon = styled.div`
  width: 52px;
  height: 92px;
  display: flex;
  flex-shrink: 0;
  border-radius: 28px;
  align-items: center;
  justify-content: center;
  border: 1px solid #000000;

  svg {
    width: 40px;
    height: 40px;
  }
`;

type MockupProps = {
  mockup: string;
};

export const Mockup = styled.div<MockupProps>`
  ${({ mockup }) => css`
    width: 320px;
    height: 560px;
    margin: 20px 0;
    background: url(${mockup});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  `}
`;

export const Mocks = styled.div`
  width: 328px;
  display: flex;

  @media (max-width: 1320px) {
    width: 100%;
  }
`;
