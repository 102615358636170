import * as S from "./styles";
import LoadingIcon from "../../icons/ibioma-animated.gif";

const Loading: React.FC = () => {
  return (
    <S.Loading>
      <S.Image src={LoadingIcon} alt="" />
    </S.Loading>
  );
};

export default Loading;
