interface IProps {
  color?: string;
}

const InfoOutlineIcon: React.FC<IProps> = ({ color }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20C0 8.96 8.94 0 19.98 0C31.04 0 40 8.96 40 20C40 31.04 31.04 40 19.98 40C8.94 40 0 31.04 0 20ZM22 12C22 10.9 21.1 10 20 10C18.9 10 18 10.9 18 12V20C18 21.1 18.9 22 20 22C21.1 22 22 21.1 22 20V12ZM20 36C11.16 36 4 28.84 4 20C4 11.16 11.16 4 20 4C28.84 4 36 11.16 36 20C36 28.84 28.84 36 20 36ZM18 26V30H22V26H18Z"
        fill={color || "#ddff66"}
      />
    </svg>
  );
};

export default InfoOutlineIcon;
