/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

const { REACT_APP_COOKIES_BASE_URL } = process.env;

const setCookie = async (): Promise<string> => {
  try {
    const locationData = await axios.get(
      "https://ipinfo.io?token=3b4ad5df6132ec"
    );

    const location =
      locationData.data.city +
      " - " +
      locationData.data.region +
      ", " +
      locationData.data.country;

    const { data } = await axios.post(`${REACT_APP_COOKIES_BASE_URL}/create`, {
      location: location,
    });

    document.cookie = data.cookie;

    return data.userId;
  } catch (error) {
    throw new Error("Houve um erro ao criar o cookie");
  }
};

export default {
  setCookie,
};
