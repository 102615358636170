import styled, { css } from "styled-components";

type ContentProps = {
  isOpen: boolean;
};

export const Content = styled.div<ContentProps>`
  ${({ isOpen }) => css`
    position: relative;
    align-items: center;
    justify-content: center;
    display: ${isOpen ? "flex" : "none"};
  `}
`;

export const Backdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;
  position: fixed;
  background-color: #00000080;
`;

export const Modal = styled.div`
  top: 35vh;
  gap: 32px;
  z-index: 1200;
  width: 500px;
  display: flex;
  position: fixed;
  padding: 28px 32px;
  text-align: justify;
  border-radius: 12px;
  flex-direction: column;
  background-color: #fafafa;

  h4 {
    line-height: 1.3;
  }

  @media (max-width: 550px) {
    top: 25vh;
    width: 320px;
  }

  @media (max-width: 340px) {
    top: 22vh;
    width: 280px;
  }
`;
