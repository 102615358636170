interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PlusIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6C12 6.19096 11.9241 6.37409 11.7891 6.50912C11.6541 6.64414 11.471 6.72 11.28 6.72H6.72V11.28C6.72 11.471 6.64414 11.6541 6.50912 11.7891C6.37409 11.9241 6.19096 12 6 12C5.80904 12 5.62591 11.9241 5.49088 11.7891C5.35586 11.6541 5.28 11.471 5.28 11.28V6.72H0.72C0.529044 6.72 0.345909 6.64414 0.210883 6.50912C0.0758569 6.37409 0 6.19096 0 6C0 5.80904 0.0758569 5.62591 0.210883 5.49088C0.345909 5.35586 0.529044 5.28 0.72 5.28H5.28V0.72C5.28 0.529044 5.35586 0.345909 5.49088 0.210883C5.62591 0.0758569 5.80904 0 6 0C6.19096 0 6.37409 0.0758569 6.50912 0.210883C6.64414 0.345909 6.72 0.529044 6.72 0.72V5.28H11.28C11.471 5.28 11.6541 5.35586 11.7891 5.49088C11.9241 5.62591 12 5.80904 12 6Z"
        fill={color || "#257034"}
      />
    </svg>
  );
};

export default PlusIcon;
