import "typeface-montserrat";

import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: "Montserrat", "sans-serif";
    font-weight: 500;
    font-size: 1rem;
    line-height: 105%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    border: none;
    box-sizing: border-box;
    transition: all ease 0.2s;

    ::-webkit-scrollbar {
      width: 12px;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 80px;
      background-color: #00000040;
      background-clip: content-box;
      border: 4px solid transparent;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #00000080;
    }
}

body {
  background-color: #F9FAFB;
}

html {
  overflow: overlay;
}

@keyframes loading {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

@media (max-width: 1080px){
  html{
    font-size: 93.75%;
  }
}

@media (max-width: 720px){
  html{
    font-size: 87.5%;
  }
}
`;
