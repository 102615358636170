const PreservationIcon: React.FC = () => {
  return (
    <svg
      width="448"
      height="406"
      viewBox="0 0 448 406"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M307.333 57.1665L369.833 119.666L328.167 140.5L411.5 223.833L349 244.666L432.333 328H244.833M286.5 390.5V328M140.667 223.833L99.0001 182.166M140.667 203L182.333 161.333M140.667 390.5V119.666M95.3335 286.333C86.2183 285.799 77.3307 283.275 69.296 278.937C61.2613 274.6 54.2746 268.554 48.8273 261.226C43.38 253.899 39.6045 245.466 37.7663 236.522C35.9281 227.578 36.0719 218.34 38.1876 209.458C30.635 203.169 24.6721 195.186 20.7833 186.16C16.8945 177.133 15.1894 167.317 15.8064 157.507C16.4234 147.698 19.3452 138.173 24.3344 129.705C29.3236 121.236 36.2397 114.064 44.521 108.771C37.2579 95.8676 34.8116 80.805 37.6181 66.2666C40.4245 51.7283 48.3014 38.6584 59.8452 29.3859C71.389 20.1133 85.85 15.2403 100.651 15.635C115.453 16.0298 129.634 21.6667 140.667 31.5415C151.702 21.6823 165.878 16.0588 180.672 15.6717C195.465 15.2846 209.915 20.159 221.452 29.4275C232.988 38.6959 240.861 51.7571 243.67 66.2863C246.479 80.8154 244.042 95.87 236.792 108.771C245.074 114.063 251.992 121.233 256.983 129.7C261.974 138.168 264.898 147.693 265.517 157.502C266.136 167.311 264.433 177.128 260.546 186.156C256.659 195.183 250.698 203.167 243.146 209.458C245.333 218.641 245.411 228.201 243.375 237.418C241.339 246.636 237.241 255.273 231.389 262.681C225.537 270.088 218.084 276.074 209.587 280.188C201.091 284.303 191.773 286.439 182.333 286.437H99.0001L95.3335 286.333Z"
        stroke="black"
        strokeWidth="30"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PreservationIcon;
