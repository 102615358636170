interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const TwitterIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4512 12.5125L24.5335 13.8966L23.1607 13.7271C18.1635 13.0774 13.7978 10.8742 10.0911 7.17387L8.27894 5.33783L7.81217 6.69367C6.82371 9.71607 7.45523 12.908 9.51451 15.0547C10.6128 16.2411 10.3657 16.4106 8.47114 15.7044C7.81217 15.4784 7.23557 15.3089 7.18066 15.3937C6.98846 15.5914 7.64743 18.1618 8.16911 19.1787C8.88299 20.5911 10.3382 21.9752 11.9307 22.7943L13.2761 23.444L11.6836 23.4722C10.146 23.4722 10.0911 23.5005 10.2558 24.0937C10.805 25.9297 12.9741 27.8787 15.3903 28.7261L17.0927 29.3193L15.61 30.2232C13.4134 31.5226 10.8324 32.257 8.25148 32.3135C7.01591 32.3417 6 32.4547 6 32.5394C6 32.8219 9.34977 34.4037 11.2992 35.0252C17.1476 36.8612 24.0942 36.0703 29.3111 32.9349C33.0178 30.7034 36.7245 26.2687 38.4543 21.9752C39.3878 19.6872 40.3214 15.5067 40.3214 13.5011C40.3214 12.2018 40.4037 12.0323 41.9413 10.4787C42.8474 9.57484 43.6986 8.5862 43.8633 8.30374C44.1379 7.76705 44.1105 7.76705 42.7101 8.24724C40.3763 9.09465 40.0468 8.98166 41.2 7.71056C42.0512 6.80666 43.0671 5.16835 43.0671 4.68815C43.0671 4.60341 42.6552 4.74465 42.1885 4.99887C41.6942 5.28134 40.5959 5.70504 39.7722 5.95926L38.2895 6.43945L36.9441 5.50731C36.2028 4.99887 35.1594 4.43393 34.6103 4.26445C33.21 3.869 31.0683 3.92549 29.8053 4.37744C26.3732 5.64854 24.2041 8.92516 24.4512 12.5125Z"
        fill={color || "#257034"}
      />
    </svg>
  );
};

export default TwitterIcon;
