interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const Chevron: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.425 6.01L1.414 0L0 1.414L4.6 6.014L0 10.607L1.414 12.021L7.425 6.01Z"
        fill={color || "#257034"}
      />
    </svg>
  );
};

export default Chevron;
