import { useState } from "react";

import * as S from "./styles";
import P from "../../../../components/atoms/Typography/P";
import { primaryColor } from "../../../../constants/colors";
import H2 from "../../../../components/atoms/Typography/H2";
import H3 from "../../../../components/atoms/Typography/H3";
import H4 from "../../../../components/atoms/Typography/H4";
import H5 from "../../../../components/atoms/Typography/H5";
import Sheets from "../../../../components/organisms/Sheets";
import { TIconOptions } from "../../../../types/iconOptions";
import { CONTACT_PHONE_NUMBER } from "../../../../constants";
import Title from "../../../../components/atoms/Typography/Title";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";

import seal from "../../../../components/assets/selo_ibioma.png";
import report from "../../../../components/assets/mocks/report.png";

interface ITopic {
  title: string;
  sheetName: string;
}

const topics: ITopic[] = [
  {
    sheetName: "os créditos de carbono",
    title: "e o que são créditos de carbono?",
  },
  {
    sheetName: "como fazemos",
    title: "como fazemos?",
  },
  {
    sheetName: "o selo",
    title: "o selo",
  },
  {
    sheetName: "comunique",
    title: "comunique",
  },
];

interface IHow {
  title: string;
  icon: TIconOptions;
  description: string;
}

const howWeDo: IHow[] = [
  {
    icon: "dataCollect",
    title: "Coleta de Dados",
    description:
      "Coleta de dados precisos sobre as atividades e práticas que geram emissões de carbono.",
  },
  {
    icon: "calculator",
    title: "Cálculo da Pegada de Carbono",
    description:
      "Com os dados coletados, utilizamos a calculadora de emissões de carbono, que é baseada no GHG Protocol.",
  },
  {
    icon: "reductionOpt",
    title: "Identificação de Oportunidades de Redução",
    description:
      "Analise dos resultados e identificamos oportunidades de redução das emissões. Sendo a compensação, neutralização ou negativação.",
  },
  {
    icon: "co2Removal",
    title: "Compensação de Emissões",
    description:
      "Identificação das oportunidades de redução, o cliente pode optar por compensar as emissões restantes que não podem ser reduzidas imediatamente.",
  },
  {
    icon: "projectsFolder",
    title: "Escolha do Projeto de Compensação",
    description:
      "O cliente pode selecionar o projeto de compensação de sua preferência, com base em seus valores e objetivos.",
  },
  {
    icon: "seal",
    title: "Certificação e Comprovação",
    description:
      "Certificado que comprova a neutralização das emissões de carbono do cliente",
  },
  {
    icon: "disclosure",
    title: "Divulgação e Reconhecimento",
    description:
      "Criação da página online e materiais para divulgação da sua ação de neutralização de carbono.",
  },
  {
    icon: "plataformDevices",
    title: "Acompanhamento em nossa plataforma",
    description:
      "Acesso a nossa plataforma para acompanhamento da sua página, a documentos, projetos e possíveis novas neutralizações.",
  },
];

const InfoPages: React.FC = () => {
  const [selectedTopic, setSelectedTopic] = useState<number>(0);

  const labels = topics.map((item) => item.sheetName);

  const url = `https://wa.me/${CONTACT_PHONE_NUMBER}`;

  const text = "Olá, gostaria de falar sobre as soluções Ibioma!";

  const encodedText = encodeURIComponent(text);

  const talk2Specialist = url + "?text=" + encodedText;

  const content = (() => {
    switch (selectedTopic) {
      case 0:
        return (
          <S.CarbonCreditBox>
            <S.CarbonEquality>
              <S.CarbonOption>
                <S.CarbonIcon>
                  <AvailableIcons option="coinStack" />
                </S.CarbonIcon>

                <P color="#000000">1 crédito de carbono</P>
              </S.CarbonOption>

              <H2 color={primaryColor}>=</H2>

              <S.CarbonOption>
                <S.CarbonIcon>
                  <AvailableIcons option="co2Removal" />
                </S.CarbonIcon>

                <P color="#000000">1 tonelada de CO2 neutralizada</P>
              </S.CarbonOption>
            </S.CarbonEquality>

            <S.CarbonText>
              <H4 color="#000000">
                Créditos de carbono são unidades de medida que correspondem,
                cada uma, à uma tonelada de dióxido de carbono equivalente (t
                CO2e).{" "}
              </H4>

              <H4 color="#000000">
                Essas medidas servem para calcular a redução das emissões de
                gases do efeito estufa (GEE) e seu possível valor
                comercializado.
              </H4>

              <H4 color="#000000" fontWeight="bold">
                Sim, é isso mesmo! A redução das emissões de gases do efeito
                estufa pode ser comercializada!
              </H4>
            </S.CarbonText>
          </S.CarbonCreditBox>
        );

      case 1:
        return (
          <S.HowBox>
            {howWeDo.map((item, index) => (
              <S.HowItem key={`${item.icon}#${index}`}>
                <S.HowIcon>
                  <AvailableIcons option={item.icon} />
                </S.HowIcon>

                <H5 color="#000000" fontWeight="bold">
                  {item.title}
                </H5>

                <P color="#000000">{item.description}</P>
              </S.HowItem>
            ))}
          </S.HowBox>
        );

      case 2:
        return (
          <S.SealBox>
            <S.SealLogo image={seal} />

            <S.SealTexts>
              <H3 color="#000000" fontWeight="bold">
                As soluções da Ibioma são garantidas pelo selo de qualidade{" "}
              </H3>

              <S.IconAndText>
                <S.SealIcon>
                  <AvailableIcons option="verified" />
                </S.SealIcon>

                <S.SealText>
                  Por meio do selo, todas as operações realizadas podem ser
                  facilmente comprovadas no sistema da Verra (certificação
                  global) e fornecer confiança para o consumidor final.{" "}
                </S.SealText>
              </S.IconAndText>

              <S.IconAndText>
                <S.SealIcon>
                  <AvailableIcons option="addBox" />
                </S.SealIcon>

                <S.SealText>
                  O selo pode ser adicionado em produtos com o intuito de
                  promover as ações sustentáveis da marca.
                </S.SealText>
              </S.IconAndText>
            </S.SealTexts>
          </S.SealBox>
        );

      case 3:
        return (
          <S.ReportBox>
            <S.ReportTextAndCTA>
              <S.ReportText>
                <H4 color="#000000">
                  Destaque sua Responsabilidade Ambiental! Ganhe uma Página de
                  Sustentabilidade com o Selo de Neutralização IBIOMA e Projeto
                  de Investimento em Sustentabilidade. Compense suas emissões de
                  carbono e mostre ao mundo seu compromisso com um futuro mais
                  verde e consciente. Saiba mais!
                </H4>

                <H4 color="#000000">
                  Com essa Página de Sustentabilidade, você pode divulgar e
                  compartilhar suas ações para neutralizar suas emissões de
                  carbono, além de evidenciar os projetos de investimento em
                  sustentabilidade que estão transformando positivamente o
                  planeta. Juntos, estamos construindo um futuro melhor para
                  todos, valorizando a preservação do meio ambiente e deixando
                  um legado positivo para as gerações futuras. Não perca essa
                  oportunidade única de mostrar sua empresa como um exemplo de
                  responsabilidade ambiental.
                </H4>
              </S.ReportText>

              <S.ReportCTA
                href={talk2Specialist}
                target="_blank"
                rel="noreferrer noopener"
              >
                <H5 color={primaryColor} fontWeight="bold">
                  fale com um especialista
                </H5>

                <S.Icon>
                  <AvailableIcons option="support" />
                </S.Icon>
              </S.ReportCTA>
            </S.ReportTextAndCTA>

            <S.ReportMockup image={report} />
          </S.ReportBox>
        );

      default:
        return <></>;
    }
  })();

  return (
    <S.Container>
      <S.TitleBox>
        <Title color="#000000" fontWeight="bold">
          {topics[selectedTopic].title}
        </Title>
      </S.TitleBox>

      <Sheets
        labels={labels}
        selectedSheet={selectedTopic}
        setSelectedSheet={(val) => setSelectedTopic(val)}
      >
        {content}
      </Sheets>
    </S.Container>
  );
};

export default InfoPages;
