const GeneratorIcon: React.FC = () => {
  return (
    <svg
      width="418"
      height="418"
      viewBox="0 0 418 418"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M104.833 0.666504C81.9165 0.666504 63.1665 19.4165 63.1665 42.3332V83.9998H42.3332C19.4165 83.9998 0.666504 102.75 0.666504 125.666V375.666H42.3332V396.5C42.3332 407.958 51.7082 417.333 63.1665 417.333H83.9998C95.4582 417.333 104.833 407.958 104.833 396.5V375.666H313.166V396.5C313.166 407.958 322.541 417.333 334 417.333H354.833C366.291 417.333 375.666 407.958 375.666 396.5V375.666H417.333V125.666C417.333 114.616 412.943 104.018 405.129 96.2037C397.315 88.3897 386.717 83.9998 375.666 83.9998H354.833V42.3332C354.833 31.2825 350.443 20.6844 342.629 12.8704C334.815 5.05637 324.217 0.666504 313.166 0.666504H104.833ZM250.666 167.333V125.666H375.666V167.333H250.666ZM250.666 250.666V209H375.666V250.666H250.666ZM104.833 42.3332H313.166V83.9998H104.833V42.3332ZM104.833 125.666V209H146.5L83.9998 334V250.666H42.3332L104.833 125.666Z"
        fill="black"
      />
    </svg>
  );
};

export default GeneratorIcon;
