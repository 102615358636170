import styled, { css } from "styled-components";

type LabelProps = {
  color: string;
  fontWeight?: string;
};

const Label = styled.small<LabelProps>`
  ${({ color, fontWeight }) => css`
    font-size: 12px;
    color: ${color};
    line-height: 10px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-weight: ${fontWeight ? fontWeight : 500};
  `}
`;
export default Label;
