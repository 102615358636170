import styled, { css } from "styled-components";

import { secondaryColor } from "../../../../constants/colors";
import Title from "../../../../components/atoms/Typography/Title";

export const Container = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  min-height: 100vh;
  position: relative;
  align-items: center;
  padding: 180px 300px 40px;
  justify-content: space-between;

  @media (max-width: 1750px) {
    padding: 180px 200px 40px;
  }

  @media (max-width: 1550px) {
    padding: 180px 100px 40px;
  }

  @media (max-width: 1200px) {
    padding: 180px 60px 40px;
  }

  @media (max-width: 900px) {
    gap: 40px;
    min-height: auto;
    flex-direction: column;
    padding: 160px 60px 0px;
    justify-content: flex-start;
  }

  @media (max-width: 500px) {
    padding: 160px 32px 0px;
  }
`;

export const TitleCTA = styled(Title)`
  display: flex;
  flex-wrap: wrap;

  span {
    font-size: 5rem;
    font-weight: bold;
    flex-wrap: nowrap;
  }

  @media (max-width: 1415px) {
    font-size: 4rem;

    span {
      font-size: 4rem;
    }
  }

  @media (max-width: 900px) {
    align-self: center;
    text-align: center;
    justify-content: center;
  }

  @media (max-width: 500px) {
    font-size: 3rem;

    span {
      font-size: 3rem;
    }
  }
`;

export const Box = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  flex-shrink: 1;
  flex-wrap: wrap;
  flex-direction: column;

  h3 {
    width: 100%;
    max-width: 520px;
  }

  @media (max-width: 900px) {
    max-width: 100%;
    justify-content: center;

    h3 {
      text-align: center;
      align-self: center;
    }
  }
`;

export const Button = styled.div`
  gap: 8px;
  display: flex;
  cursor: pointer;
  user-select: none;
  padding: 8px 20px;
  width: fit-content;
  align-items: center;
  border-radius: 24px;
  justify-content: center;
  background-color: ${secondaryColor};

  @media (max-width: 900px) {
    align-self: center;
    justify-content: center;
  }
`;

export const Icon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Images = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-items: space-around;

  @media (max-width: 900px) {
    justify-content: center;
  }

  @media (max-width: 480px) {
    gap: 12px;
    padding: 0 20px;
    transform: scale(0.8);
    margin-top: -40px;
  }
`;

type ImagesProps = {
  image: string;
};

export const ImageSmall = styled.div<ImagesProps>`
  ${({ image }) => css`
    width: 100%;
    height: 400px;
    max-width: 200px;
    min-width: 100px;
    align-items: center;
    border-radius: 100px;
    background: url(${image});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;

    @media (max-width: 1100px) {
      width: 100%;
      height: 300px;
      min-width: 120px;
      max-width: 160px;
    }
  `}
`;

export const ImageBig = styled.div<ImagesProps>`
  ${({ image }) => css`
    width: 100%;
    height: 600px;
    max-width: 200px;
    min-width: 100px;
    align-items: center;
    border-radius: 100px;
    background: url(${image});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;

    @media (max-width: 1100px) {
      height: 460px;
      min-width: 120px;
      max-width: 160px;
    }
  `}
`;
