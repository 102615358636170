import { useEffect, useState } from "react";

import * as S from "./styles";
import P from "../../atoms/Typography/P";
import ibioma from "../../assets/ibioma_logo.png";
import AvailableIcons from "../../atoms/AvailableIcons";
import { primaryColor } from "../../../constants/colors";

interface IProps {
  onClickCalculators: () => void;
  onClickSection: (label: string) => void;
}

const Header: React.FC<IProps> = ({ onClickSection, onClickCalculators }) => {
  const [mobileMenu, setMobileMenu] = useState<boolean>(false);
  const [calculatorMenu, setCalculatorMenu] = useState<boolean>(false);
  const [showHeaderBackground, setShowHeaderBackground] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== "undefined")
      window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined")
      if (window.innerWidth <= 900) {
        setMobileMenu(true);
        return;
      }

    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, []);

  const onScroll = () => {
    if (typeof window !== "undefined")
      window.scrollY > 100
        ? setShowHeaderBackground(false)
        : setShowHeaderBackground(true);
  };

  const onResize = () => {
    if (typeof window !== "undefined")
      window.innerWidth <= 900 ? setMobileMenu(true) : setMobileMenu(false);
  };

  const onLeaveMouse = () => {
    if (typeof window !== "undefined")
      window.scrollY > 100 && setShowHeaderBackground(false);
  };

  const onCalculateMobile = () => {
    setIsMobileMenuOpen(false);
    onClickCalculators();
  };

  return (
    <S.Header
      scrolled={showHeaderBackground}
      onMouseLeave={() => onLeaveMouse()}
      onMouseEnter={() => setShowHeaderBackground(true)}
    >
      <S.Logo logo={ibioma} />

      <S.Sections isMobile={mobileMenu || !showHeaderBackground}>
        <S.Section
          isMobile={mobileMenu}
          onClick={() => onClickSection("aboutUs")}
        >
          sobre nós
        </S.Section>

        <S.Section
          isMobile={mobileMenu}
          onClick={() => onClickSection("advantages")}
        >
          vantagens
        </S.Section>

        <S.Section
          isMobile={mobileMenu}
          onClick={() => onClickSection("howDeal")}
        >
          como contratar
        </S.Section>

        {/* <S.Section
          isMobile={mobileMenu}
          onClick={() => onClickSection("benefits")}
        >
          benefícios
        </S.Section> */}

        <S.Section
          isMobile={mobileMenu}
          onClick={() => onClickSection("plataform")}
        >
          nossa plataforma
        </S.Section>

        <S.Section isMobile={mobileMenu} onClick={() => onClickSection("faq")}>
          dúvidas frequentes
        </S.Section>
      </S.Sections>

      <S.CalculatorButton
        isMobile={mobileMenu}
        onClick={() => onClickCalculators()}
      >
        <P color={primaryColor} fontWeight="bold">
          calcule suas emissões
        </P>

        <S.Icon>
          <AvailableIcons option="mathOps" color={primaryColor} />
        </S.Icon>
      </S.CalculatorButton>

      {calculatorMenu && (
        <S.Backdrop onClick={() => setCalculatorMenu(false)} />
      )}

      <S.MobileMenu
        isMobile={mobileMenu}
        onClick={() => setIsMobileMenuOpen((curr) => !curr)}
      >
        <span />
        <span />
        <span />
      </S.MobileMenu>

      {isMobileMenuOpen && (
        <S.Backdrop onClick={() => setIsMobileMenuOpen(false)} />
      )}

      {isMobileMenuOpen && (
        <S.MobileMenuSections isMobile={isMobileMenuOpen}>
          <S.Close
            isMobile={isMobileMenuOpen}
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <AvailableIcons option="close" color="#fafafa" />
          </S.Close>

          <S.MobileSections isMobile={isMobileMenuOpen}>
            <S.MobileSection
              isMobile={isMobileMenuOpen}
              onClick={() => onClickSection("aboutUs")}
            >
              sobre nós
            </S.MobileSection>

            <S.MobileSection
              isMobile={isMobileMenuOpen}
              onClick={() => onClickSection("advantages")}
            >
              vantagens
            </S.MobileSection>

            <S.MobileSection
              isMobile={isMobileMenuOpen}
              onClick={() => onClickSection("howDeal")}
            >
              como contratar
            </S.MobileSection>

            {/* <S.MobileSection
              isMobile={isMobileMenuOpen}
              onClick={() => onClickSection("benefits")}
            >
              benefícios
            </S.MobileSection> */}

            <S.MobileSection
              isMobile={isMobileMenuOpen}
              onClick={() => onClickSection("plataform")}
            >
              nossa plataforma
            </S.MobileSection>

            <S.MobileSection
              isMobile={isMobileMenuOpen}
              onClick={() => onClickSection("faq")}
            >
              dúvidas frequentes
            </S.MobileSection>
          </S.MobileSections>

          <S.CalculatorButton
            onClick={() => onCalculateMobile()}
            isMobile={mobileMenu && !isMobileMenuOpen}
          >
            <P color={primaryColor} fontWeight="bold">
              calcule suas emissões
            </P>

            <S.Icon>
              <AvailableIcons option="mathOps" color={primaryColor} />
            </S.Icon>
          </S.CalculatorButton>
        </S.MobileMenuSections>
      )}
    </S.Header>
  );
};

export default Header;
