import * as S from "./styles";
import { Analytics } from "../../../../services";
import H3 from "../../../../components/atoms/Typography/H3";
import { primaryColor } from "../../../../constants/colors";
import { getCookieFromUser } from "../../../../utils/cookies";
import kids from "../../../../components/assets/kids_banner.png";
import monkey from "../../../../components/assets/monkey_banner.png";
import florest from "../../../../components/assets/florest_banner.png";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";

interface IProps {
  onClickCalculators: () => void;
}

const InitialImage: React.FC<IProps> = ({ onClickCalculators }) => {
  const userId = getCookieFromUser();

  const onClickHandler = async () => {
    onClickCalculators();

    await Analytics.sendClick({
      user: userId || "",
      location: "home-lp",
      eventLabel: "cta-compensate-home",
      meta: {
        stage: "landing-page",
        section: "initial-banner",
        action: "calculators-modal",
      },
    });
  };

  return (
    <S.Container>
      <S.Box>
        <S.TitleCTA color="#000000" fontWeight="bold">
          Calcule Compense <span>& Comunique</span>
        </S.TitleCTA>

        <H3 color="#000000">
          Autonomia para compensar a pegada de carbono da sua empresa de forma
          rápida e simples.
        </H3>

        <S.Button onClick={() => onClickHandler()}>
          <H3 color={primaryColor} fontWeight="bold">
            calcule suas emissões
          </H3>

          <S.Icon>
            <AvailableIcons option="mathOps" color={primaryColor} />
          </S.Icon>
        </S.Button>
      </S.Box>

      <S.Images>
        <S.ImageSmall image={florest} />

        <S.ImageBig image={monkey} />

        <S.ImageSmall image={kids} />
      </S.Images>
    </S.Container>
  );
};

export default InitialImage;
