import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import H4 from "../../atoms/Typography/H4";
import ibioma from "../../assets/ibioma_logo.png";
import AvailableIcons from "../../atoms/AvailableIcons";
import { primaryColor } from "../../../constants/colors";

const HeaderCalculator: React.FC = () => {
  const [mobileMenu, setMobileMenu] = useState<boolean>(false);
  const [calculatorMenu, setCalculatorMenu] = useState<boolean>(false);
  const [showHeaderBackground, setShowHeaderBackground] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (typeof window !== "undefined")
      window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined")
      if (window.innerWidth <= 1200) {
        setMobileMenu(true);
        return;
      }

    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, []);

  const onScroll = () => {
    if (typeof window !== "undefined")
      window.scrollY > 80
        ? setShowHeaderBackground(false)
        : setShowHeaderBackground(true);
  };

  const onResize = () => {
    if (typeof window !== "undefined")
      window.innerWidth <= 900 ? setMobileMenu(true) : setMobileMenu(false);
  };

  const onLeaveMouse = () => {
    if (typeof window !== "undefined")
      window.scrollY > 80 && setShowHeaderBackground(false);
  };

  const onClickMobileSection = (label: string) => {
    setIsMobileMenuOpen(false);
    navigate(`/${label}`);
  };

  return (
    <S.Header
      scrolled={showHeaderBackground}
      onMouseLeave={() => onLeaveMouse()}
      onMouseEnter={() => setShowHeaderBackground(true)}
    >
      <S.Logo logo={ibioma} onClick={() => navigate("/")} />

      <S.Calculators>
        <S.Calculator
          onClick={() => navigate("/")}
          show={showHeaderBackground && !mobileMenu}
        >
          <H4 color={primaryColor} fontWeight="bold">
            Home
          </H4>
        </S.Calculator>

        <S.Calculator
          show={showHeaderBackground && !mobileMenu}
          onClick={() => navigate("/calculadora")}
        >
          <S.IconCalc>
            <AvailableIcons option="buildings" />
          </S.IconCalc>

          <H4 color={primaryColor} fontWeight="bold">
            Administrativa
          </H4>
        </S.Calculator>

        <S.Calculator
          show={showHeaderBackground && !mobileMenu}
          onClick={() => navigate("/calculadora-logistica")}
        >
          <S.IconCalc>
            <AvailableIcons option="engineCycle" />
          </S.IconCalc>

          <H4 color={primaryColor} fontWeight="bold">
            Logística
          </H4>
        </S.Calculator>

        <S.Calculator
          show={showHeaderBackground && !mobileMenu}
          onClick={() => navigate("/calculadora-agro")}
        >
          <S.IconCalc>
            <AvailableIcons option="agriculture" />
          </S.IconCalc>

          <H4 color={primaryColor} fontWeight="bold">
            Agro
          </H4>
        </S.Calculator>

        <S.Calculator
          show={showHeaderBackground && !mobileMenu}
          onClick={() => navigate("/calculadora-eventos")}
        >
          <S.IconCalc>
            <AvailableIcons option="ticket" />
          </S.IconCalc>

          <H4 color={primaryColor} fontWeight="bold">
            Eventos
          </H4>
        </S.Calculator>
      </S.Calculators>

      {calculatorMenu && (
        <S.Backdrop onClick={() => setCalculatorMenu(false)} />
      )}

      <S.MobileMenu
        show={mobileMenu}
        onClick={() => setIsMobileMenuOpen((curr) => !curr)}
      >
        <span />
        <span />
        <span />
      </S.MobileMenu>

      {isMobileMenuOpen && (
        <S.Backdrop onClick={() => setIsMobileMenuOpen(false)} />
      )}

      {isMobileMenuOpen && (
        <S.MobileMenuSections show={isMobileMenuOpen}>
          <S.Close
            show={isMobileMenuOpen}
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <AvailableIcons option="close" color="#fafafa" />
          </S.Close>

          <S.Calculators isMobile={isMobileMenuOpen}>
            <S.Calculator
              show={isMobileMenuOpen}
              isMobile={isMobileMenuOpen}
              onClick={() => onClickMobileSection("")}
            >
              <H4 color="#fafafa" fontWeight="bold">
                Home
              </H4>
            </S.Calculator>

            <S.Calculator
              show={isMobileMenuOpen}
              isMobile={isMobileMenuOpen}
              onClick={() => onClickMobileSection("calculadora")}
            >
              <S.IconCalc isMobile={isMobileMenuOpen}>
                <AvailableIcons option="buildings" color="#fafafa" />
              </S.IconCalc>

              <H4 color="#fafafa" fontWeight="bold">
                Administrativa
              </H4>
            </S.Calculator>

            <S.Calculator
              show={isMobileMenuOpen}
              isMobile={isMobileMenuOpen}
              onClick={() => onClickMobileSection("calculadora-logistica")}
            >
              <S.IconCalc isMobile={isMobileMenuOpen}>
                <AvailableIcons option="engineCycle" color="#fafafa" />
              </S.IconCalc>

              <H4 color="#fafafa" fontWeight="bold">
                Logística
              </H4>
            </S.Calculator>

            <S.Calculator
              show={isMobileMenuOpen}
              isMobile={isMobileMenuOpen}
              onClick={() => onClickMobileSection("calculadora-agro")}
            >
              <S.IconCalc isMobile={isMobileMenuOpen}>
                <AvailableIcons option="agriculture" color="#fafafa" />
              </S.IconCalc>

              <H4 color="#fafafa" fontWeight="bold">
                Agro
              </H4>
            </S.Calculator>

            <S.Calculator
              show={isMobileMenuOpen}
              isMobile={isMobileMenuOpen}
              onClick={() => onClickMobileSection("calculadora-eventos")}
            >
              <S.IconCalc isMobile={isMobileMenuOpen}>
                <AvailableIcons option="ticket" color="#fafafa" />
              </S.IconCalc>

              <H4 color="#fafafa" fontWeight="bold">
                Eventos
              </H4>
            </S.Calculator>
          </S.Calculators>
        </S.MobileMenuSections>
      )}
    </S.Header>
  );
};

export default HeaderCalculator;
