import styled, { css } from "styled-components";

import { primaryColor, secondaryColor } from "../../../constants/colors";

type ContentProps = {
  isOpen: boolean;
};

export const Content = styled.div<ContentProps>`
  ${({ isOpen }) => css`
    position: relative;
    align-items: center;
    justify-content: center;
    display: ${isOpen ? "flex" : "none"};
  `}
`;

export const Backdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5000;
  position: fixed;
  background-color: #00000080;
`;

export const Modal = styled.div`
  top: 10vh;
  gap: 32px;
  width: 500px;
  display: flex;
  padding: 20px;
  z-index: 10000;
  position: fixed;
  text-align: center;
  border-radius: 12px;
  flex-direction: column;
  background-color: #fafafa;

  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const Title = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;

  h4 {
    span {
      font-size: 1rem;
      font-weight: bold;
    }
  }
`;

export const FormBox = styled.div`
  gap: 28px;
  width: 100%;
  display: flex;
  flex-direction: column;

  button {
    width: 30%;
    align-self: center;
  }
`;

export const Form = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputBox = styled.div`
  gap: 12px;
  display: flex;
  text-align: start;
  flex-direction: column;
`;

type HighlightProps = {
  color: string;
};

export const Highlight = styled.span<HighlightProps>`
  ${({ color }) => css`
    color: ${color};
  `}
`;

export const SubmittedBox = styled.div`
  gap: 40px;
  display: flex;
  padding-top: 40px;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const SubmittedTexts = styled.div`
  gap: 32px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const IbiomaLogo = styled.div`
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const ContactBox = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Button = styled.div`
  display: flex;
  cursor: pointer;
  user-select: none;
  font-weight: bold;
  padding: 12px 24px;
  width: fit-content;
  align-self: center;
  align-items: center;
  border-radius: 20px;
  color: ${primaryColor};
  justify-content: center;
  background-color: ${secondaryColor};
`;
