import styled, { css } from "styled-components";

import Button from "../Button";
import { grayIbioma } from "../../../constants/colors";

export const Container = styled.div`
  display: flex;
  align-items: center;

  input {
    width: 100%;
    outline: none;
    font-size: 1rem;
    padding: 8px 0px;
    text-align: center;
    background-color: transparent;
  }
`;

export const MinusButton = styled(Button)`
  margin-right: 1rem;
  font-size: 1.5rem;
  line-height: 1rem;
`;

export const PlusButton = styled(Button)`
  margin-left: 1rem;
  font-size: 1.5rem;
  line-height: 1rem;
`;

type IInputBoxProps = {
  width: string;
  border?: boolean;
};

export const InputBox = styled.div<IInputBoxProps>`
  ${({ border, width }) => css`
    height: 44px;
    display: flex;
    width: ${width};
    border-radius: 12px;
    justify-content: center;
    border: ${border ? `1px solid ${grayIbioma}` : "none"};

    @media (max-width: 730px) {
      width: 132px;
    }
  `};
`;

export const Money = styled.span`
  ${() => {
    return css`
      height: 100%;
      display: flex;
      color: #000000;
      font-size: 1rem;
      padding-left: 12px;
      align-items: center;
      justify-content: center;

      @media (max-width: 750px) {
        padding-left: 8px;
      }
    `;
  }}
`;

export const EndUnit = styled.span`
  ${() => {
    return css`
      height: 100%;
      display: flex;
      color: #000000;
      font-size: 1rem;
      padding-right: 12px;
      align-items: center;
      justify-content: center;

      @media (max-width: 750px) {
        padding-right: 8px;
      }
    `;
  }}
`;
