import * as S from "./styles";
import Option from "../../atoms/Option";
import H4 from "../../atoms/Typography/H4";

interface IProps {
  label: string;
  options: string[];
  selectedItem: number;
  setSelectedItem: (index: number) => void;
}

const OptionsList: React.FC<IProps> = ({
  label,
  options,
  selectedItem,
  setSelectedItem,
}) => {
  return (
    <S.Container>
      <H4 color="#000000" fontWeight="regular">
        {label}
      </H4>

      <S.Options>
        {options.map((item, index) => (
          <Option
            key={item}
            label={item}
            isSelected={index === selectedItem}
            setSelected={() => setSelectedItem(index)}
          />
        ))}
      </S.Options>
    </S.Container>
  );
};

export default OptionsList;
