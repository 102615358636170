import styled, { css } from "styled-components";

export const Footer = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 100px;
  justify-content: space-between;

  @media (max-width: 1150px) {
    gap: 40px;
    padding: 60px 0;
    align-items: center;
    flex-direction: column-reverse;
  }
`;

export const LogoAndRights = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

type LogoProps = {
  image: string;
};

export const IbiomaLogo = styled.div<LogoProps>`
  ${({ image }) => css`
    width: 220px;
    height: 80px;
    display: flex;
    background: url(${image});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  `}
`;

export const Information = styled.div`
  gap: 60px;
  display: flex;
  justify-content: space-around;

  @media (max-width: 1200px) {
    gap: 28px;
  }

  @media (max-width: 1150px) {
    h4 {
      text-align: center;
    }
  }

  @media (max-width: 570px) {
    flex-direction: column;
  }
`;

export const Text = styled.div`
  gap: 4px;
  display: flex;
  max-width: 220px;
  text-align: justify;
  flex-direction: column;
`;

export const Sections = styled.div`
  gap: 32px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    margin-top: 28px;
    text-align: center;
  }
`;

export const Section = styled.div`
  cursor: pointer;
  color: #fafafa80;
  user-select: none;
  font-size: 0.75rem;
  text-decoration: none;
`;

export const SocialMediaContainer = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1150px) {
    text-align: center;
  }
`;

export const SocialMediaIcons = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.a`
  width: 40px;
  height: 40px;
  display: flex;
  cursor: pointer;
  user-select: none;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: #257034;

  svg {
    width: 60%;
    height: 60%;
  }

  @media (max-width: 1100px) {
    width: 28px;
    height: 28px;
  }
`;
