/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

import { IEvent } from "../../types/analytics";

const { REACT_APP_ANALYTICS_BASE_URL } = process.env;

const sendPageView = async (event: IEvent) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_ANALYTICS_BASE_URL}/page_view`,
      event
    );

    return data;
  } catch (error) {
    throw new Error("Houve um erro ao enviar o evento de page_view");
  }
};

const sendClick = async (event: IEvent) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_ANALYTICS_BASE_URL}/click`,
      event
    );

    return data;
  } catch (error) {
    throw new Error("Houve um erro ao enviar o evento de click");
  }
};

export default {
  sendClick,
  sendPageView,
};
