import * as S from "./styles";
import { primaryColor, secondaryColor } from "../../../constants/colors";
import AvailableIcons from "../AvailableIcons";

type WhatsappButtonProps = {
  text: string;
  href: string;
  textColor?: string;
  onClick: () => void;
  borderColor?: string;
  backgroundColor?: string;
  type: "large" | "medium";
};

const WhatsappButton: React.FC<WhatsappButtonProps> = ({
  type,
  href,
  text,
  onClick,
  textColor = primaryColor,
  borderColor = primaryColor,
  backgroundColor = secondaryColor,
}) => {
  return (
    <S.Container
      type={type}
      href={href}
      target="_blank"
      textColor={textColor}
      borderColor={borderColor}
      rel="noopener noreferrer"
      onClick={() => onClick()}
      backgroundColor={backgroundColor}
    >
      {text}

      <S.Icon>
        <AvailableIcons option="whatsapp" color={borderColor} />
      </S.Icon>
    </S.Container>
  );
};

export default WhatsappButton;
