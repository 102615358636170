import styled, { css } from "styled-components";

import { grayIbioma, secondaryColor } from "../../../../constants/colors";

export const Container = styled.div`
  gap: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 100px 0 100px 300px;

  @media (max-width: 1800px) {
    padding-left: 200px;
  }

  @media (max-width: 1700px) {
    padding-left: 140px;
  }

  @media (max-width: 1520px) {
    padding-left: 120px;
  }

  @media (max-width: 1020px) {
    padding-left: 80px;
  }

  @media (max-width: 960px) {
    padding: 60px 0 60px 40px;
  }

  @media (max-width: 700px) {
    padding: 60px 40px;
    justify-content: center;
  }

  @media (max-width: 460px) {
    padding: 60px 24px;
    justify-content: center;
  }
`;

export const Content = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

export const Menu = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 500px) {
    gap: 12px;
  }
`;

type MenuItemProps = {
  isSelected: boolean;
};

export const MenuItem = styled.div<MenuItemProps>`
  ${({ isSelected }) => css`
    width: 100%;
    display: flex;
    cursor: pointer;
    color: #00000080;
    user-select: none;
    padding: 8px 20px;
    align-items: center;
    white-space: nowrap;
    border-radius: 12px;
    justify-content: center;
    background-color: ${isSelected && grayIbioma};
    font-weight: ${isSelected ? "bold" : "regular"};

    @media (max-width: 500px) {
      white-space: wrap;
      padding: 8px 12px;
    }
  `}
`;

export const Title = styled.div`
  display: flex;
  margin-top: 28px;
  max-width: 900px;

  h1 {
    font-size: 3rem;

    span {
      font-size: 3rem;
      font-weight: bold;
    }
  }

  :hover {
    cursor: grab;
  }

  @media (max-width: 1520px) {
    max-width: 500px;
  }

  @media (max-width: 960px) {
    h1 {
      font-size: 2rem;

      span {
        font-size: 2rem;
        font-weight: bold;
      }
    }
  }
`;

export const Description = styled.div`
  display: flex;
  max-width: 600px;
  text-align: justify;
  align-items: center;

  :hover {
    cursor: grab;
  }
`;

export const RequestAccess = styled.div`
  gap: 8px;
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
`;

export const CTAIcon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${secondaryColor};

  svg {
    width: 40%;
    height: 40%;
  }
`;

export const Indicators = styled.div`
  gap: 8px;
  z-index: 100;
  display: flex;
  margin-top: 12px;
  position: relative;
`;

type IndicatorProps = {
  isSelected: boolean;
  selectedColor: string;
  notSelectedColor: string;
};

export const Indicator = styled.span<IndicatorProps>`
  ${({ isSelected, selectedColor, notSelectedColor }) => css`
    height: 8px;
    width: 60px;
    cursor: pointer;
    border-radius: 6px;
    border: ${isSelected ? "none" : "1px solid #000000"};
    background-color: ${isSelected ? selectedColor : notSelectedColor};
  `}
`;

export const MockBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media (max-width: 700px) {
    display: none;
  }
`;

type MockupProps = {
  image: string;
};

export const Mockup = styled.div<MockupProps>`
  ${({ image }) => css`
    width: 1000px;
    height: 600px;
    display: flex;
    border-radius: 12px;
    justify-content: flex-end;
    background: url(${image});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;

    @media (max-width: 1520px) {
      width: 800px;
      height: 500px;
    }

    @media (max-width: 960px) {
      width: 600px;
      height: 400px;
      flex-shrink: 0;
      background-position: center left;
    }

    @media (max-width: 700px) {
      display: none;
    }
  `}
`;
