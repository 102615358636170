import styled, { css } from "styled-components";

export const Container = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;
  max-width: 500px;
  align-self: center;
  justify-content: center;

  @media (max-width: 1080px) {
    max-width: 500px;
  }

  @media (max-width: 680px) {
    max-width: 300px;
  }
`;

export const IconAndLabel = styled.div`
  gap: 8px;
  width: 116px;
  display: flex;
  align-items: center;

  flex-direction: column;
`;

export const Icon = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Separators = styled.div`
  gap: 8px;
  display: flex;
  margin-top: 32px;
  align-self: flex-start;
`;

type SeparatorProps = {
  color: string;
};

export const Separator = styled.div<SeparatorProps>`
  ${({ color }) => css`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${color};
  `}
`;
