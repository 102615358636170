import styled, { css } from "styled-components";

import { grayIbioma, primaryColor } from "../../../constants/colors";

export const ProgressBar = styled.div`
  width: 100%;
  height: 3px;
  max-width: 700px;
  background-color: ${grayIbioma};

  @media (max-width: 1080px) {
    max-width: 500px;
  }

  @media (max-width: 680px) {
    max-width: 300px;
  }
`;

type ProgressProps = {
  progress: number;
};

export const Progress = styled.div<ProgressProps>`
  ${({ progress }) => css`
    height: 3px;
    max-width: 700px;
    width: ${`${progress}%`};
    background-color: ${primaryColor};

    @media (max-width: 1080px) {
      max-width: 500px;
    }

    @media (max-width: 680px) {
      max-width: 300px;
    }
  `}
`;
