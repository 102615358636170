interface IProps {
  color?: string;
}

const CropsIcon: React.FC<IProps> = ({ color }) => {
  return (
    <svg
      width="52"
      height="52"
      fill="none"
      viewBox="0 0 52 52"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.5 25.7533C51.5 39.6959 40.0878 51.0066 26 51.0066C11.9122 51.0066 0.5 39.6959 0.5 25.7533C0.5 11.8108 11.9122 0.5 26 0.5C40.0878 0.5 51.5 11.8108 51.5 25.7533Z"
        stroke={color || "#000000"}
      />
      <path
        d="M16 36L30 22M30 22H32C33.0609 22 34.0783 21.5786 34.8284 20.8284C35.5786 20.0783 36 19.0609 36 18V16H34C32.9391 16 31.9217 16.4214 31.1716 17.1716C30.4214 17.9217 30 18.9391 30 20V22ZM17.47 26.53L19 25L20.53 26.53C21.1834 27.186 21.5503 28.0741 21.5503 29C21.5503 29.9259 21.1834 30.814 20.53 31.47L19 33L17.47 31.47C16.8166 30.814 16.4497 29.9259 16.4497 29C16.4497 28.0741 16.8166 27.186 17.47 26.53ZM21.47 22.53L23 21L24.53 22.53C25.1834 23.186 25.5503 24.0741 25.5503 25C25.5503 25.9259 25.1834 26.814 24.53 27.47L23 29L21.47 27.47C20.8166 26.814 20.4497 25.9259 20.4497 25C20.4497 24.0741 20.8166 23.186 21.47 22.53ZM25.47 18.53L27 17L28.53 18.53C29.1834 19.186 29.5503 20.0741 29.5503 21C29.5503 21.9259 29.1834 22.814 28.53 23.47L27 25L25.47 23.47C24.8166 22.814 24.4497 21.9259 24.4497 21C24.4497 20.0741 24.8166 19.186 25.47 18.53Z"
        stroke={color || "#000000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.47 31.47L27 33L25.47 34.53C24.814 35.1834 23.9259 35.5502 23 35.5502C22.0741 35.5502 21.186 35.1834 20.53 34.53L19 33L20.53 31.47C21.186 30.8166 22.0741 30.4497 23 30.4497C23.9259 30.4497 24.814 30.8166 25.47 31.47ZM29.47 27.47L31 29L29.47 30.53C28.814 31.1834 27.9259 31.5502 27 31.5502C26.0741 31.5502 25.186 31.1834 24.53 30.53L23 29L24.53 27.47C25.186 26.8166 26.0741 26.4497 27 26.4497C27.9259 26.4497 28.814 26.8166 29.47 27.47ZM33.47 23.47L35 25L33.47 26.53C32.814 27.1834 31.9259 27.5502 31 27.5502C30.0741 27.5502 29.186 27.1834 28.53 26.53L27 25L28.53 23.47C29.186 22.8166 30.0741 22.4497 31 22.4497C31.9259 22.4497 32.814 22.8166 33.47 23.47Z"
        stroke={color || "#000000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CropsIcon;
