import styled, { css } from "styled-components";

import {
  grayIbioma,
  primaryColor,
  secondaryColor,
} from "../../../constants/colors";

type IScrolled = {
  scrolled: boolean;
};

export const Header = styled.div<IScrolled>`
  ${({ scrolled }) => css`
    gap: 8px;
    width: 95%;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    position: fixed;
    padding: 0 40px;
    margin: 20px 2.5%;
    align-self: center;
    align-items: center;
    border-radius: 60px;
    justify-content: space-between;
    background-color: ${scrolled ? grayIbioma : `${grayIbioma}40`};
  `}
`;

type LogoProps = {
  logo: string;
};

export const Logo = styled.div<LogoProps>`
  ${({ logo }) => css`
    width: 180px;
    height: 92px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    background: url(${logo});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 990px) {
      width: 100px;
    }

    @media (max-width: 900px) {
      width: 180px;
    }

    @media (max-width: 500px) {
      width: 120px;
    }
  `}
`;

type MobileProps = {
  isMobile: boolean;
};

export const Sections = styled.div<MobileProps>`
  ${({ isMobile }) => css`
    gap: 32px;
    user-select: none;
    justify-content: space-between;
    display: ${isMobile ? "none" : "flex"};

    @media (max-width: 900px) {
      display: none;
    }

    @media (max-width: 1110px) {
      gap: 20px;
    }
  `}
`;

export const Section = styled.div<MobileProps>`
  ${({ isMobile }) => css`
    gap: 6px;
    color: #000000;
    cursor: pointer;
    flex-wrap: wrap;
    user-select: none;
    text-align: center;
    align-items: center;
    text-decoration: none;
    display: ${isMobile ? "none" : "flex"};

    :hover {
      color: #257034;

      path {
        fill: #257034;
      }
    }

    svg {
      width: 12px;
      height: 12px;
      transform: ${isMobile ? `rotate(270deg)` : `rotate(90deg)`};
    }
  `}
`;

export const CalculatorButton = styled.div<MobileProps>`
  ${({ isMobile }) => css`
    gap: 4px;
    cursor: pointer;
    padding: 8px 12px;
    user-select: none;
    align-items: center;
    text-align: center;
    border-radius: 24px;
    justify-content: center;
    background-color: ${secondaryColor};
    display: ${isMobile ? "none" : "flex"};
  `}
`;

export const Icon = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Items = styled.div`
  gap: 40px;
  display: flex;
  margin-top: 12px;
`;

export const MobileMenu = styled.div<MobileProps>`
  ${({ isMobile }) => css`
    gap: 6px;
    cursor: pointer;
    user-select: none;
    align-items: center;
    flex-direction: column;
    display: ${isMobile ? "flex" : "none"};

    span {
      content: "";
      width: 32px;
      height: 4px;
      background-color: ${primaryColor};
    }
  `}
`;

export const Backdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
`;

export const MobileMenuSections = styled.div<MobileProps>`
  ${({ isMobile }) => css`
    top: 0;
    right: 0;
    bottom: 0;
    gap: 24px;
    width: 50%;
    padding: 20px;
    height: 100vh;
    cursor: pointer;
    user-select: none;
    position: absolute;
    flex-direction: column;
    animation: entrada 0.5s ease-out;
    background-color: ${primaryColor};
    display: ${isMobile ? "flex" : "none"};

    @keyframes entrada {
      from {
        transform: translateX(100%);
      }
      to {
        transform: translateX(0);
      }
    }
  `}
`;

export const Close = styled.div<MobileProps>`
  ${({ isMobile }) => css`
    width: 20px;
    height: 20px;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    display: ${isMobile ? "flex" : "none"};

    svg {
      width: 100%;
      height: 100%;
    }
  `}
`;

export const MobileSections = styled.div<MobileProps>`
  ${({ isMobile }) => css`
    gap: 40px;
    padding: 12px 0 20px;
    flex-direction: column;
    display: ${isMobile ? "flex" : "none"};
  `}
`;

export const MobileSection = styled.div<MobileProps>`
  ${({ isMobile }) => css`
    width: 100%;
    color: #fafafa;
    cursor: pointer;
    user-select: none;
    align-items: center;
    text-decoration: none;
    justify-content: space-between;
    display: ${isMobile ? "flex" : "none"};
    border-bottom: ${!isMobile && "1px solid #fafafa80"};

    svg {
      width: 12px;
      height: 12px;
      transform: ${isMobile ? `rotate(270deg)` : `rotate(90deg)`};
    }
  `}
`;
