import styled, { css } from "styled-components";
import { grayIbioma, primaryColor } from "../../constants/colors";

export const Page = styled.div`
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding: 160px 80px 100px;
`;

export const TitleAndDescription = styled.div`
  gap: 40px;
  width: 100%;
  display: flex;
  text-align: justify;
  flex-direction: column;
`;

export const Description = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  text-align: justify;
  flex-direction: column;
`;

export const DescriptionList = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 12px 20px;
  text-align: justify;
  flex-direction: column;
`;

export const TemplateSheet = styled.a`
  gap: 12px;
  width: 100%;
  display: flex;
  margin: 40px 0;
  max-width: 400px;
  padding: 20px 32px;
  align-self: center;
  text-align: justify;
  align-items: center;
  border-radius: 40px;
  text-decoration: none;
  justify-content: center;
  background-color: ${primaryColor};

  :hover {
    background-color: ${grayIbioma};

    h3 {
      color: ${primaryColor};
    }

    svg {
      path {
        fill: ${primaryColor};
      }
    }
  }
`;

export const Icon = styled.div`
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const StatesAndCities = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const States = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StatesBox = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
`;

type StateProps = {
  isSelected: boolean;
};

export const State = styled.div<StateProps>`
  ${({ isSelected }) => css`
    display: flex;
    cursor: pointer;
    padding: 12px 8px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: ${isSelected ? "#00000040" : "transparent"};
  `}
`;

export const Cities = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CitiesBox = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
  align-items: center;
`;

export const SearchBox = styled.div`
  display: flex;
  padding: 8px 0;
  align-items: center;
  justify-content: center;
`;

export const City = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
