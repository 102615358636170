export const maskDate = (value: string) => {
  if (!value) return "";

  if (value.length > 8) {
    const day = value.substring(0, 2);
    const month = value.substring(2, 4);
    const year = value.substring(4, 8);

    return `${day}/${month}/${year}`;
  }

  const day = value.substring(0, 2);
  const month = value.substring(2, 4);
  const year = value.substring(4);

  if (!month) return `${day}`;

  if (!year) return `${day}/${month}`;

  return `${day}/${month}/${year}`;
};
