/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";

import * as S from "./styles";
import { TStates } from "../../types";
import SEO from "../../components/atoms/SEO";
import { Analytics, Cookies } from "../../services";
import H1 from "../../components/atoms/Typography/H1";
import H2 from "../../components/atoms/Typography/H2";
import H3 from "../../components/atoms/Typography/H3";
import H4 from "../../components/atoms/Typography/H4";
import Footer from "../../components/molecules/Footer";
import { getCookieFromUser } from "../../utils/cookies";
import { states, statesCitiesIBGE } from "../../constants";
import InputText from "../../components/molecules/InputText";
import ContactUs from "../../components/organisms/ContactUs";
import AvailableIcons from "../../components/atoms/AvailableIcons";
import { backgroundColor, textColor } from "../../constants/colors";
import CalculatorModal from "../../components/molecules/CalculatorModal";
import HeaderCalculator from "../../components/molecules/HeaderCalculator";

import routesSheetTemplate from "../../config/routes-sheet-template.json";

const RoutesSheetTemplate: React.FC = () => {
  const [search, setSearch] = useState<string>();
  const [state, setState] = useState<TStates>("SP");
  const [calculatorModal, setCalculatorModal] = useState<boolean>(false);
  const [citiesOptions, setCitiesOptions] = useState<string[]>(
    statesCitiesIBGE[state].map((item) => item.city)
  );

  useEffect(() => {
    const sendPageView = async () => {
      if (typeof document !== "undefined") {
        const userId = getCookieFromUser();

        if (!userId) {
          const newUserId = await Cookies.setCookie();

          await Analytics.sendPageView({
            user: newUserId,
            meta: { stage: "landing-page" },
            location: "routes-sheet-template-lp",
            eventLabel: "page-view-routes-sheet-template",
          });
        } else {
          await Analytics.sendPageView({
            user: userId,
            meta: { stage: "landing-page" },
            location: "routes-sheet-template-lp",
            eventLabel: "page-view-routes-sheet-template",
          });
        }
      }
    };

    sendPageView();
  }, []);

  useEffect(() => {
    const cities = statesCitiesIBGE[state].map((item) => item.city);

    setCitiesOptions(cities);
  }, [state]);

  const onSearch = (val: string) => {
    setSearch(val);

    const cities = statesCitiesIBGE[state].map((item) => item.city);

    const filteredArray = cities.filter((item) =>
      item
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          val
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        )
    );

    setCitiesOptions(filteredArray);
  };

  return (
    <S.Page>
      <SEO
        route={routesSheetTemplate.route}
        title={routesSheetTemplate.title}
        keywords={routesSheetTemplate.keywords}
        description={routesSheetTemplate.description}
      />

      <HeaderCalculator />

      <S.Container>
        <S.TitleAndDescription>
          <H1 color={textColor} fontWeight="bold">
            Template para planilha de Rotas - Calculadora Logística
          </H1>

          <S.Description>
            <H3 color={textColor}>
              Para poder carregar uma planilha na Calculadora Logística, para o
              cálculo das emissões das rotas, deve-se seguir o padrão abaixo.
              Caso contrário, a leitura do arquivo não poderá ser realizada.
            </H3>

            <H3 color={textColor}>
              - Na calculadora, serão aceitos, apenas, arquivos .csv e .xlsx
            </H3>

            <H3 color={textColor}>
              - Usaremos alguns termos, por isso, vamos definir que:
              <S.DescriptionList>
                <H4 color={textColor}>
                  • Endereço - deve-se começar por Rua ou Avenida, seguido da
                  nomenclatura. Evite abreviações. Por exemplo: Avenida Cidade
                  Jardim
                </H4>

                <H4 color={textColor}>
                  • Número - número do endereço. Não é necessário informar
                  complemento
                </H4>

                <H4 color={textColor}>
                  • Bairro - bairro do endereço. Evite abreviações. Exemplo:
                  Jardim Paulistano
                </H4>

                <H4 color={textColor}>
                  • Estado (sigla) - estado do endereço, abreviado por duas
                  letras. Abaixo, disponibilizamos a lista com as siglas dos
                  estados e respectivos municípios. Exemplo: SP, RJ, MA, etc.
                </H4>

                <H4 color={textColor}>
                  • Cidade - cidade do endereço, seguindo o padrão IBGE. Abaixo,
                  disponibilizamos a lista com as siglas dos estados e
                  respectivos municípios. Exemplo: Espírito Santo do Pinhal, São
                  Paulo, etc.
                </H4>
              </S.DescriptionList>
            </H3>

            <H3 color={textColor}>
              - Solicitamos 4 colunas: origem, destino, veículo e trechos
              <S.DescriptionList>
                <H4 color={textColor}>
                  • Origem - endereço de origem da sua rota. Preferencialmente,
                  inclua o endereço completo, para maior precisão nos cálculos.
                  Para endereços completos, siga o formato: Endereço, Número,
                  Bairro, Cidade, Estado (sigla). Caso não possua o endereço
                  completo, é possível omitir alguns dados, mas é obrigatório
                  informar, ao menos, Cidade e Estado (sigla), no formato:
                  Cidade, Estado (sigla)
                </H4>

                <H4 color={textColor}>
                  • Destino - endereço de destino da sua rota.
                  Preferencialmente, inclua o endereço completo, para maior
                  precisão nos cálculos. Para endereços completos, siga o
                  formato: Tipo Endereço, Número, Bairro, Cidade, Estado
                  (sigla). Caso não possua o endereço completo, é possível
                  omitir alguns dados, mas é obrigatório informar, ao menos,
                  Cidade e Estado (sigla), no formato: Cidade, Estado (sigla)
                </H4>

                <H4 color={textColor}>
                  • Veículo - tipo do veículo utilizado na rota. Deve ser uma
                  das 5 opções: Carro, Van, Caminhão, Motocicleta, Utilitário
                  (devem ser escritos exatamente como da forma demonstrada)
                </H4>

                <H4 color={textColor}>
                  • Trechos - número de viagens nessa rota. Ou seja, quantas
                  vezes sua logística foi da origem ao destino. Dica: caso
                  precise considerar o trecho inverso, basta incrementar o
                  número de trechos, já que a distância será a mesma
                </H4>
              </S.DescriptionList>
            </H3>

            <H3 color={textColor}>
              - Não crie mais abas em seu arquivo, mantenha apenas a aba com as
              informações das rotas, no template necessário
            </H3>

            <H3 color={textColor}>
              - Para fazer o download do template, basta clicar no botão abaixo,
              que o redirecionará para uma planilha Google Sheets. Com o
              template aberto, clique na opção do menu 'Ficheiro', vá até a
              seção 'Transferir' e selecione .xlsx ou .csv, para baixá-lo em seu
              dispositivo e editá-lo conforme necessidade. Lembre-se de remover
              a linha de exemplo antes de acrescentar suas rotas
            </H3>
          </S.Description>
        </S.TitleAndDescription>

        <S.TemplateSheet
          target="_blank"
          rel="noreferrer noopener"
          href="https://docs.google.com/spreadsheets/d/1-C0kf2VYKpEu1U7OUBXTMPU6W3dgUGMHvm13XsvYboc/edit?usp=sharing"
        >
          <S.Icon>
            <AvailableIcons option="document" color={backgroundColor} />
          </S.Icon>

          <H3 color={backgroundColor}>Template p/ download</H3>
        </S.TemplateSheet>

        <S.StatesAndCities>
          <S.States>
            <H2 color={textColor}>Estados</H2>

            <S.StatesBox>
              {states.map((item, index) => (
                <S.State
                  key={`${item}#${index}`}
                  isSelected={item === state}
                  onClick={() => setState(item as TStates)}
                >
                  {item}
                </S.State>
              ))}
            </S.StatesBox>
          </S.States>

          <S.Cities>
            <H2 color={textColor}>Cidades</H2>

            <S.SearchBox>
              <InputText
                border={false}
                value={search || ""}
                placeholder="Busque aqui"
                onChange={(val) => onSearch(val)}
              />
            </S.SearchBox>

            <S.CitiesBox>
              {citiesOptions.map((item, index) => (
                <S.City key={`${item}#${index}`}>{item}</S.City>
              ))}
            </S.CitiesBox>
          </S.Cities>
        </S.StatesAndCities>
      </S.Container>

      <ContactUs location="routes-sheet-template" />

      <Footer />

      {calculatorModal && (
        <CalculatorModal
          isOpen={calculatorModal}
          onClose={() => setCalculatorModal(false)}
        />
      )}
    </S.Page>
  );
};

export default RoutesSheetTemplate;
