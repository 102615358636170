import styled, { css } from "styled-components";

import Button from "../../../../../../components/atoms/Button";
import { primaryColor } from "../../../../../../constants/colors";
import { Box } from "../../../../../Calculadora/components/Calculator/components/Questions/styles";

export const Container = styled.div`
  gap: 48px;
  width: 100%;
  display: flex;
  padding: 0 300px;
  min-height: 400px;
  flex-direction: column;

  @media (max-width: 1700px) {
    padding: 0 200px;
  }

  @media (max-width: 1400px) {
    padding: 0 100px;
  }

  @media (max-width: 1080px) {
    width: 100%;
  }

  @media (max-width: 930px) {
    padding: 0 80px;
  }

  @media (max-width: 830px) {
    padding: 0 60px;
  }

  @media (max-width: 600px) {
    padding: 0 20px;
  }

  @media (max-width: 480px) {
    padding: 0;
  }
`;

export const QuestionTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Questions = styled.div`
  gap: 32px;
  width: 100%;
  display: flex;
  padding: 0 40px;
  flex-direction: column;

  @media (max-width: 580px) {
    padding: 0;
  }
`;

type QuestionProps = {
  marginTop: string;
};

export const QuestionContainer = styled.div<QuestionProps>`
  ${({ marginTop }) => css`
    gap: 48px;
    display: flex;
    padding: 40px 40px;
    border-radius: 12px;
    flex-direction: column;
    border: 2px solid ${primaryColor};

    @media (max-width: 540px) {
      margin-top: ${marginTop && marginTop};
    }
  `}
`;

export const Question = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompanyQuestions = styled.div`
  gap: 32px;
  display: flex;
  flex-wrap: wrap;
  padding: 0 40px;
`;

type CompanyQuestionProps = {
  maxWidth: string;
};

export const CompanyQuestion = styled.div<CompanyQuestionProps>`
  ${({ maxWidth }) => css`
    gap: 32px;
    width: 100%;
    display: flex;
    max-width: ${maxWidth};
  `}
`;

export const SliderAndNumber = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Buttons = styled.div`
  display: flex;
  margin-bottom: 40px;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

type ButtonsProps = {
  isVisible: boolean;
};

export const Clickable = styled(Button)<ButtonsProps>`
  ${({ isVisible }) => css`
    width: 126px;
    align-items: center;
    justify-content: center;
    display: ${isVisible ? "flex" : "none"};
  `}
`;

type BoxProps = {
  marginTop?: string;
};

export const BoxContent = styled(Box)<BoxProps>`
  ${({ marginTop }) => css`
    margin-top: ${marginTop ? marginTop : 0};
  `}
`;
