import { useEffect, useState } from "react";

import * as S from "./styles";
import H1 from "../../../../components/atoms/Typography/H1";
import H4 from "../../../../components/atoms/Typography/H4";
import { secondaryColor } from "../../../../constants/colors";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";

import tela1 from "../../../../components/assets/mocks/ipad_1.png";
import tela2 from "../../../../components/assets/mocks/ipad_2.png";
import tela3 from "../../../../components/assets/mocks/ipad_3.png";

interface IProps {
  onRequestAccess: () => void;
}

const Plataform: React.FC<IProps> = ({ onRequestAccess }) => {
  const [hover, setHover] = useState<boolean>(false);
  const [selectedContent, setSelectedContent] = useState<number>(0);

  useEffect(() => {
    if (hover) return;

    if (!hover) {
      const interval = setInterval(() => {
        setSelectedContent((curr) => (curr + 1) % content.length);
      }, 6500);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hover]);

  const content = [
    {
      label: "Nossa Plataforma",
      title: (
        <H1 color="#000000">
          <span>Explore e invista em projetos</span> que fazem sentido para o
          seu negócio
        </H1>
      ),
      description: (
        <H4 color="#00000099">
          Nossa plataforma conecta você a projetos inovadores que têm o poder de
          fazer a diferença. Fechando essa parceria com a IBIOMA, você terá a
          oportunidade de investir e apoiar iniciativas de sustentabilidade que
          estão moldando um futuro mais verde e equilibrado para todos.
        </H4>
      ),
      mockup: tela1,
    },
    {
      label: "Marketing",
      title: (
        <H1 color="#000000">
          <span>Recursos de Marketing que ajudam</span> a ampliar a sua
          comunicação de neutralização
        </H1>
      ),
      description: (
        <H4 color="#00000099">
          Inspirar outros a adotar um estilo de vida sustentável é crucial para
          criar um impacto maior. Em nossa plataforma, você encontrará recursos
          valiosos, como materiais e conteúdo de marketing, para compartilhar
          sua jornada sustentável. Ao usar esses recursos, você pode influenciar
          positivamente mais pessoas a se juntarem à missão de neutralização da
          IBIOMA.
        </H4>
      ),
      mockup: tela2,
    },
    {
      label: "Extrato",
      title: (
        <H1 color="#000000">
          <span>Acesse seu extrato e acompanhe</span> cada neutralização que
          você faz
        </H1>
      ),
      description: (
        <H4 color="#00000099">
          O Extrato é sua janela para acompanhar o progresso das suas ações em
          direção à neutralização. Com ele, você pode visualizar todas as suas
          contribuições, entender como suas escolhas estão impactando
          positivamente o meio ambiente e celebrar suas conquistas no caminho
          para um estilo de vida mais sustentável.
        </H4>
      ),
      mockup: tela3,
    },
  ];

  const menu = content.map((item) => item.label);

  const onEnterMouse = (index: number) => {
    setHover(true);
    setSelectedContent(index);
  };

  return (
    <S.Container>
      <S.Content>
        <S.Menu>
          {menu.map((item, index) => (
            <S.MenuItem
              key={item}
              isSelected={index === selectedContent}
              onClick={() => setSelectedContent(index)}
            >
              {item}
            </S.MenuItem>
          ))}
        </S.Menu>

        <S.Title
          onMouseLeave={() => setHover(false)}
          onMouseEnter={() => onEnterMouse(selectedContent)}
        >
          {content[selectedContent].title}
        </S.Title>

        <S.Description
          onMouseLeave={() => setHover(false)}
          onMouseEnter={() => onEnterMouse(selectedContent)}
        >
          {content[selectedContent].description}
        </S.Description>

        <S.RequestAccess onClick={() => onRequestAccess()}>
          <S.CTAIcon>
            <AvailableIcons option="chevron" color="#fafafa" />
          </S.CTAIcon>

          <H4 color="#000000">Solicitar acesso</H4>
        </S.RequestAccess>

        <S.Indicators>
          {Array(content.length)
            .fill(null)
            .map((_, index) => (
              <S.Indicator
                key={index}
                selectedColor={secondaryColor}
                notSelectedColor="#fafafa40"
                isSelected={index === selectedContent}
                onClick={() => setSelectedContent(index)}
              />
            ))}
        </S.Indicators>
      </S.Content>

      <S.MockBox>
        <S.Mockup image={content[selectedContent].mockup} />
      </S.MockBox>
    </S.Container>
  );
};

export default Plataform;
