import styled from "styled-components";

import {
  grayIbioma,
  primaryColor,
  secondaryColor,
} from "../../../../constants/colors";

export const Container = styled.div`
  gap: 12px;
  width: 100%;
  display: flex;
  padding: 100px 80px;
  text-align: center;
  align-items: center;
  flex-direction: column;
  background-color: ${grayIbioma};

  h1 {
    font-size: 3.5rem;
  }

  h3 {
    margin-top: 20px;
  }

  @media (max-width: 930px) {
    padding: 100px 40px;
  }
`;

export const Input = styled.div`
  width: 100%;
  display: flex;
  max-width: 600px;
  margin-top: 40px;
  position: relative;
  align-items: center;
`;

export const Button = styled.div`
  right: 0;
  height: 100%;
  display: flex;
  padding: 0 40px;
  cursor: pointer;
  font-weight: bold;
  user-select: none;
  position: absolute;
  align-items: center;
  border-radius: 24px;
  color: ${primaryColor};
  justify-content: center;
  background-color: ${secondaryColor};

  @media (max-width: 550px) {
    padding: 0 20px;
  }
`;
