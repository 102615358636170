import { useState } from "react";

import * as S from "./styles";
import H1 from "../../atoms/Typography/H1";
import H3 from "../../atoms/Typography/H3";
import InputText from "../../molecules/InputText";
import { emptyContactForm } from "../../../utils";
import { IContactForm } from "../../../types/lead";
import ContactButton from "../../atoms/ContactButton";
import { grayIbioma } from "../../../constants/colors";
import { Analytics, Contact } from "../../../services";
import AvailableIcons from "../../atoms/AvailableIcons";
import { CONTACT_PHONE_NUMBER } from "../../../constants";
import { getCookieFromUser } from "../../../utils/cookies";

import arara from "../../assets/arara_contato.png";
import { Loading, Snackbar } from "../../../hooks";
import { maskPhoneNumber } from "../../../utils/numbers";

const validateForm = (data: IContactForm): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.name || data.name.length === 0)
    errors.name = [...(errors.name || []), "Nome é obrigatório"];

  if (!data.mail || data.mail.length === 0)
    errors.mail = [...(errors.mail || []), "E-mail é obrigatório"];

  if (
    data.mail &&
    !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(data.mail)
  )
    errors.mail = [...(errors.mail || []), "Verifique o email"];

  if (!data.phone || data.phone.length === 0)
    errors.phone = [...(errors.phone || []), "Telefone é obrigatório"];

  if (!data.message || data.message.length === 0)
    errors.message = [...(errors.message || []), "Escreva aqui é obrigatório"];

  return errors;
};

interface IProps {
  location: string;
}

const ContactUs: React.FC<IProps> = ({ location }) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [contactHref, setContactHref] = useState<string>("");
  const [form, setForm] = useState<IContactForm>(emptyContactForm);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();

  const onChangeHandler = (key: keyof IContactForm, value: string) => {
    setForm((curr) => ({
      ...curr,
      [key]: value,
    }));
  };

  const onSubmitHandler = async () => {
    try {
      showLoading();

      const currErrors = validateForm(form);

      if (currErrors && Object.keys(currErrors).length) {
        setErrors(currErrors);
        return alert(
          "O formulário possui erros, por favor verifique os campos para continuar"
        );
      }

      const userId = getCookieFromUser();

      await Analytics.sendClick({
        location: location,
        user: userId || "",
        eventLabel: "get-in-touch",
        meta: { section: "contact-us", method: "mail" },
      });

      await Contact.sendInformation({
        ...form,
        userId: userId || "",
        mail: form.mail.toLowerCase().trim(),
      });

      const url = `https://wa.me/${CONTACT_PHONE_NUMBER}`;

      const text = `
      Olá! Meu nome é ${form.name} e gostaria de tirar uma dúvida:
      ${form.message}
      `;

      const encodedText = encodeURIComponent(text);

      setContactHref(url + "?text=" + encodedText);

      setSubmitted(true);

      newSuccess("Mensagem enviada com sucesso!");
    } catch (error) {
      newError("Houve um erro ao enviar a mensagem");
    } finally {
      hideLoading();
    }
  };

  const onContactHandler = async () => {
    const userId = getCookieFromUser();

    await Analytics.sendClick({
      location: location,
      user: userId || "",
      eventLabel: "get-in-touch",
      meta: { section: "contact-us", method: "whatsapp" },
    });
  };

  return (
    <S.ContainerBox>
      <S.Container image={arara} />

      <S.FormBox>
        {submitted ? (
          <S.SubmittedBox>
            <S.SubmittedTexts>
              <H3 color="#000000" fontWeight="bold">
                Mensagem enviada!
              </H3>

              <H3 color="#000000" fontWeight="bold">
                Entraremos em contato em breve, via e-mail.
              </H3>
            </S.SubmittedTexts>

            <S.ContactBox>
              <H3 color="#000000">
                Se preferir, clique abaixo para falar via Whatsapp
              </H3>

              <ContactButton
                type="large"
                href={contactHref}
                text="Entrar em contato"
                onClick={() => onContactHandler()}
              />
            </S.ContactBox>

            <S.IbiomaLogo>
              <AvailableIcons option="ibioma" />
            </S.IbiomaLogo>
          </S.SubmittedBox>
        ) : (
          <>
            <H1 color="#000000">
              <span>vamos juntos construir</span> um futuro melhor!
            </H1>

            <S.Form>
              <InputText
                border={false}
                borderRadius="24px"
                errors={errors.name}
                value={form.name || ""}
                fixedPlaceholder="Nome:"
                backgroundColor={grayIbioma}
                onChange={(val) => onChangeHandler("name", val)}
              />

              <InputText
                border={false}
                borderRadius="24px"
                errors={errors.mail}
                value={form.mail || ""}
                fixedPlaceholder="E-mail:"
                backgroundColor={grayIbioma}
                onChange={(val) => onChangeHandler("mail", val)}
              />

              <InputText
                border={false}
                charLimit={15}
                borderRadius="24px"
                errors={errors.phone}
                fixedPlaceholder="Telefone:"
                backgroundColor={grayIbioma}
                value={maskPhoneNumber(form.phone || "")}
                onChange={(val) =>
                  onChangeHandler("phone", val.replace(/[^0-9]/g, ""))
                }
              />

              <InputText
                as="textarea"
                border={false}
                charLimit={1000}
                borderRadius="24px"
                errors={errors.message}
                value={form.message || ""}
                fixedPlaceholder="Mensagem:"
                backgroundColor={grayIbioma}
                onChange={(val) => onChangeHandler("message", val)}
              />
            </S.Form>

            <S.Button onClick={() => onSubmitHandler()}>enviar</S.Button>
          </>
        )}
      </S.FormBox>
    </S.ContainerBox>
  );
};

export default ContactUs;
