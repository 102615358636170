import styled, { css } from "styled-components";

export const Backdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
`;

type ContainerProps = {
  isOpen: boolean;
  listType: "colors" | "text" | "interests";
};

export const Container = styled.div<ContainerProps>`
  ${({ isOpen, listType }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    max-height: 38px;
    min-height: 38px;
    position: relative;
    border-radius: 12px;
    z-index: ${isOpen && 10};
    justify-content: flex-start;
  `}
`;

type DropdownProps = {
  isOpen: boolean;
  textColor: string;
  borderColor: string;
  backgroundColor: string;
  listType: "colors" | "text" | "interests";
};

export const Dropdown = styled.ul<DropdownProps>`
  ${({ isOpen, listType, textColor, borderColor, backgroundColor }) => css`
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    cursor: pointer;
    list-style: none;
    user-select: none;
    max-height: 220px;
    position: absolute;
    overflow-y: overlay;
    color: ${textColor};
    border-radius: 12px;
    flex-direction: column;
    height: ${!isOpen && "100%"};
    border: 1px solid ${borderColor};
    background-color: ${isOpen && backgroundColor};

    li {
      display: flex;
      margin: 0 16px;
      transition: none;
      padding: 20px 12px;
      align-items: center;
      border-bottom: 1px solid #000000;

      &:last-of-type,
      &:first-of-type {
        border-bottom: none;
      }

      &:first-of-type {
        margin: 0;
        padding: ${isOpen ? "20px 24px 20px 20px" : "0px 24px 0px 20px"};
      }
    }
  `}
`;

export const Selected = styled.li`
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  user-select: none;
  position: relative;
  border-radius: 12px;
  align-items: center;
  justify-content: space-between;
`;

export const IconAndText = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
`;

type IconProps = {
  color: string;
};

export const Icon = styled.div<IconProps>`
  ${({ color }) => css`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${color};
  `};
`;

export const AheadIcon = styled.div`
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);

  svg {
    width: 100%;
  }
`;
