import styled, { css } from "styled-components";

import { secondaryColor } from "../../../constants/colors";

type ContainerProps = {
  height: string;
};

export const Container = styled.div<ContainerProps>`
  ${({ height }) => css`
    gap: 40px;
    width: 100%;
    display: flex;
    height: ${height};
    position: relative;
    flex-direction: column;
    padding: 100px 160px 0;
    background-color: ${secondaryColor};

    h1 {
      max-width: 200px;
      font-size: 3.5rem;
    }

    @media (max-width: 1100px) {
      padding: 100px 80px 0;
    }

    @media (max-width: 800px) {
      gap: 20px;
      align-items: center;
      padding: 60px 40px 0;

      h1 {
        max-width: 300px;
        text-align: center;
      }
    }

    @media (max-width: 500px) {
      padding: 60px 20px 0;
    }
  `}
`;

export const QuestionsBox = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  margin-top: 20px;
  position: relative;
  padding: 80px 60px;
  border-radius: 24px;
  flex-direction: column;
  background-color: #fafafa;

  @media (max-width: 780px) {
    padding: 40px 40px;
  }
`;

export const Faq = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  user-select: none;
  flex-direction: column;
`;

type OpenProps = {
  isOpen: boolean;
  column?: boolean;
};

export const Title = styled.div<OpenProps>`
  ${({ isOpen }) => css`
    gap: 20px;
    display: flex;
    align-items: center;
    padding: 16px 12px 24px;
    justify-content: space-between;
    border-bottom: ${!isOpen && "1px solid #000000"};

    svg {
      width: 16px;
      height: 16px;
      transform: ${isOpen ? `rotate(270deg)` : `rotate(90deg)`};
    }
  `}
`;

export const Description = styled.div<OpenProps>`
  ${({ isOpen, column }) => css`
    width: 100%;
    text-align: justify;
    padding: 12px 20px 20px;
    display: ${isOpen ? "flex" : "none"};
    flex-direction: ${column ? "column" : "row"};
    border-bottom: ${isOpen && "1px solid #000000"};
  `}
`;

export const ScopesDescription = styled.div`
  gap: 12px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  flex-direction: column;

  p {
    width: 100%;
  }
`;
