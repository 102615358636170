import { primaryColor } from "../../constants/colors";

interface IProps {
  color?: string;
}

const VerifiedIcon: React.FC<IProps> = ({ color }) => {
  return (
    <svg
      id="Camada_1"
      data-name="Camada 1"
      viewBox="0 0 1000 1000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="_7n5Xp2" data-name="7n5Xp2">
        <g>
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m803.61,484.67c-4.15-100.5-53.22-189.69-151.89-247.15-99.76-58.1-203.04-56.46-303.26-.3-108.71,60.91-166.3,183.37-148.24,306.63,3.47,23.67-4.35,37.77-22.03,39.72-17.9,1.97-28.52-8.9-32.05-32.8-29.51-200.02,114.88-385.11,317.25-406.64,210.43-22.39,396.43,146.23,394.88,357.99-1.32,179.84-138.66,332.1-316.97,352.49-73.32,8.38-142.07-5.3-207.33-38.51-10.43-5.31-20.56-11.7-29.65-19.04-10.39-8.39-11.97-20.25-6.3-32.06,6.07-12.63,17.74-15.07,30.11-12.33,6.55,1.45,12.67,5.38,18.67,8.73,120.62,67.36,260.11,52.8,360.39-40.63,63.88-59.51,96-134.56,96.43-236.09Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m455.5,641.4c-10.53.14-18.39-5.27-25.44-12.31-37.27-37.22-74.66-74.32-111.71-111.75-14.89-15.04-16.53-33.32-4.96-44.23,12.15-11.44,28.23-9.57,44.07,6.05,27.65,27.28,55.37,54.5,82.14,82.63,10.81,11.36,17.73,11.12,28.3-.45,57.96-63.5,116.63-126.35,174.95-189.52,8.38-9.08,17.47-16.16,30.69-13.55,20.68,4.09,27.47,27.77,12.17,45.71-20.23,23.73-41.33,46.74-62.54,69.62-47.14,50.85-94.63,101.37-141.81,152.19-7.24,7.79-14.3,15.22-25.85,15.61Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m260.33,697.23c-.27,10.96-5.04,18.98-15.14,22.88-10.36,4-21.25,3.89-28.82-4.93-8.5-9.91-16.66-20.62-22.41-32.25-6.55-13.27-2.86-26.05,10.07-34.34,12.43-7.96,24.53-5.18,33.48,5.58,10.49,12.62,21.74,25.32,22.81,43.06Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default VerifiedIcon;
