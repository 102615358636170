import * as S from "./styles";
import H4 from "../../atoms/Typography/H4";
import { TCrop } from "../../../types/agro";
import { cropsObj } from "../../../constants";
import Label from "../../atoms/Typography/Label";
import AvailableIcons from "../../atoms/AvailableIcons";

interface IProps {
  crop: string;
  herds?: number;
  harvestDate: string;
  onRemove: () => void;
}

const CropCard: React.FC<IProps> = ({ crop, herds, harvestDate, onRemove }) => {
  return (
    <S.Card>
      <S.Texts>
        <Label color="#000000" fontWeight="bold">
          {cropsObj[crop as TCrop]}
        </Label>

        {crop === "livestock" ? (
          <H4 color="#000000">Número de rebanhos: {herds}</H4>
        ) : (
          <H4 color="#000000">Data de colheita: {harvestDate}</H4>
        )}
      </S.Texts>

      <S.CloseIcon onClick={onRemove}>
        <AvailableIcons option="close" color="#ff0000" />
      </S.CloseIcon>
    </S.Card>
  );
};

export default CropCard;
