import { useRef, useState } from "react";

import * as S from "./styles";
import {
  textColor,
  primaryColor,
  backgroundColor,
} from "../../../../constants/colors";
import Questions from "./components/Questions";
import { maskDate } from "../../../../utils/date";
import { ICalculatorResult } from "../../../../types";
import { Loading, Snackbar } from "../../../../hooks";
import Button from "../../../../components/atoms/Button";
import { maskCPFOrCNPJ } from "../../../../utils/numbers";
import P from "../../../../components/atoms/Typography/P";
import H1 from "../../../../components/atoms/Typography/H1";
import H2 from "../../../../components/atoms/Typography/H2";
import H3 from "../../../../components/atoms/Typography/H3";
import H5 from "../../../../components/atoms/Typography/H5";
import { getCookieFromUser } from "../../../../utils/cookies";
import CropCard from "../../../../components/molecules/CropCard";
import { Analytics, CalculatorService } from "../../../../services";
import ProgressBar from "../../../../components/molecules/ProgressBar";
import ContactButton from "../../../../components/atoms/ContactButton";
import { emptyAgroCalculatorForm, emptyCrop } from "../../../../utils";
import ExcludeModal from "../../../../components/molecules/ExcludeModal";
import { IAgroCalculatorForm, ICrop, IHerd } from "../../../../types/agro";
import { CONTACT_PHONE_NUMBER, transportUnitsObj } from "../../../../constants";
import AgroCalculatorStep from "../../../../components/molecules/AgroCalculatorStep";
import ChartAndIndicators from "../../../../components/organisms/ChartAndIndicators";

const validateForm = (
  data: IAgroCalculatorForm
): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.producerName || data.producerName.length === 0)
    errors.producerName = [
      ...(errors.producerName || []),
      "Nome do produtor é obrigatório",
    ];

  if (!data.producerState)
    errors.producerState = [
      ...(errors.producerState || []),
      "Estado do produtor é obrigatório",
    ];

  if (!data.producerCity)
    errors.producerCity = [
      ...(errors.producerCity || []),
      "Cidade do produtor é obrigatório",
    ];

  if (!data.contactName)
    errors.contactName = [
      ...(errors.contactName || []),
      "Nome para contato é obrigatório",
    ];

  if (!data.contactMail)
    errors.contactMail = [
      ...(errors.contactMail || []),
      "E-mail para contato é obrigatório",
    ];

  if (
    data.contactMail &&
    !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(data.contactMail)
  )
    errors.contactMail = [...(errors.contactMail || []), "Verifique o email"];

  if (!data.contactPhone)
    errors.contactPhone = [
      ...(errors.contactPhone || []),
      "Telefone para contato é obrigatório",
    ];

  return errors;
};

const validateCropForm = (data: ICrop): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.crop)
    errors.crop = [...(errors.crop || []), "Tipo de cultura é obrigatório"];

  if (!data.plantingDate && data.crop !== "livestock" && data.crop !== "cane")
    errors.plantingDate = [
      ...(errors.plantingDate || []),
      "Data de plantio é obrigatória",
    ];

  if (data.plantingDate) {
    if (data.plantingDate.length !== 8) {
      errors.plantingDate = [
        ...(errors.plantingDate || []),
        "Verifique a data inserida",
      ];
    }

    const day = +data.plantingDate.substring(0, 2);
    const month = +data.plantingDate.substring(2, 4);

    if (day < 1 || day > 31 || month < 1 || month > 12) {
      errors.plantingDate = [
        ...(errors.plantingDate || []),
        "Verifique a data inserida",
      ];
    }
  }

  if (!data.harvestDate && data.crop !== "livestock")
    errors.harvestDate = [
      ...(errors.harvestDate || []),
      "Data de colheita é obrigatória",
    ];

  if (data.harvestDate) {
    if (data.harvestDate.length !== 8) {
      errors.harvestDate = [
        ...(errors.harvestDate || []),
        "Verifique a data inserida",
      ];
    }

    const day = +data.harvestDate.substring(0, 2);
    const month = +data.harvestDate.substring(2, 4);

    if (day < 1 || day > 31 || month < 1 || month > 12) {
      errors.harvestDate = [
        ...(errors.harvestDate || []),
        "Verifique a data inserida",
      ];
    }
  }

  if (data.plantingDate && data.harvestDate) {
    const plantingDay = +data.plantingDate.substring(0, 2);
    const plantingMonth = +data.plantingDate.substring(2, 4);
    const plantingYear = +data.plantingDate.substring(4);

    const plantingDate = new Date(
      +plantingYear,
      +plantingMonth - 1,
      +plantingDay
    ).getTime();

    const harvestDay = +data.harvestDate.substring(0, 2);
    const harvestMonth = +data.harvestDate.substring(2, 4);
    const harvestYear = +data.harvestDate.substring(4);

    const harvestDate = new Date(
      +harvestYear,
      +harvestMonth - 1,
      +harvestDay
    ).getTime();

    if (harvestDate <= plantingDate) {
      errors.harvestDate = [
        ...(errors.harvestDate || []),
        "Data de colheita anterior ao plantio",
      ];
    }
  }

  if (!data.cycle && data.crop === "cane")
    errors.cycle = [...(errors.cycle || []), "Ciclo é obrigatório"];

  return errors;
};

const validateHerdForm = (data: IHerd): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.type)
    errors.type = [...(errors.type || []), "Tipo de rebanho é obrigatório"];

  if (!data.entryDate)
    errors.entryDate = [
      ...(errors.entryDate || []),
      "Data de entrada é obrigatória",
    ];

  if (data.entryDate) {
    const day = +data.entryDate.substring(0, 2);
    const month = +data.entryDate.substring(2, 4);

    if (day < 1 || day > 31 || month < 1 || month > 12) {
      errors.entryDate = [
        ...(errors.entryDate || []),
        "Verifique a data inserida",
      ];
    }
  }

  if (!data.departureDate)
    errors.departureDate = [
      ...(errors.departureDate || []),
      "Data de saída é obrigatória",
    ];

  if (data.departureDate) {
    const day = +data.departureDate.substring(0, 2);
    const month = +data.departureDate.substring(2, 4);

    if (day < 1 || day > 31 || month < 1 || month > 12) {
      errors.departureDate = [
        ...(errors.departureDate || []),
        "Verifique a data inserida",
      ];
    }
  }

  return errors;
};

const Calculator: React.FC = () => {
  const [search, setSearch] = useState<string>();
  const [herds, setHerds] = useState<IHerd[]>();
  const [currHerd, setCurrHerd] = useState<number>(0);
  const [filtered, setFiltered] = useState<string[]>();
  const [cpfOrCnpj, setCpfOrCnpj] = useState<string>("");
  const [aircraft, setAircraft] = useState<boolean>(true);
  const [currCrop, setCurrCrop] = useState<ICrop>(emptyCrop);
  const [contactHref, setContactHref] = useState<string>("");
  const [excludeCrop, setExcludeCrop] = useState<boolean>(false);
  const [excludeHerd, setExcludeHerd] = useState<boolean>(false);
  const [solarEnergy, setSolarEnergy] = useState<boolean>(false);
  const [questionNumber, setQuestionNumber] = useState<number>(1);
  const [cpfOrCnpjError, setCpfOrCnpjError] = useState<string[]>();
  const [excludeCropIndex, setExcludeCropIndex] = useState<number>(0);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [formStep, setFormStep] = useState<"crops" | "facilities">("crops");
  const [calculatorResult, setCalculatorResult] = useState<ICalculatorResult>();
  const [calculatorForm, setCalculatorForm] = useState<IAgroCalculatorForm>(
    emptyAgroCalculatorForm
  );

  const questionsTop = useRef<HTMLDivElement>(null);

  const { newError } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();

  const onChangeCropHandler = (
    key: keyof ICrop,
    value: string | number | boolean
  ) => {
    setCurrCrop((curr) => ({
      ...curr,
      [key]: value,
    }));
  };

  const onChangeHerdHandler = (
    key: keyof IHerd,
    index: number,
    value: string | number | boolean
  ) => {
    setHerds((curr) => {
      if (!curr && currHerd > 0) return curr;

      if (!curr && currHerd === 0) {
        return [
          {
            ...(curr || {}),
            [key]: value,
          },
        ];
      }

      if (curr) {
        const currentHerd = curr[index];

        const newData = curr.map((item, i) => {
          if (index === i) {
            return {
              ...currentHerd,
              [key]: value,
            };
          }

          return { ...item };
        });

        return newData;
      }
    });
  };

  const onChangeHandler = (
    key: keyof IAgroCalculatorForm,
    value: string | number | boolean
  ) => {
    setCalculatorForm((curr) => {
      if (key === "dieselUnit" || key === "aircraftUnit") {
        return {
          ...curr,
          [key]: value ? "R$" : "l",
        };
      }

      if (key === "energyUnit") {
        return {
          ...curr,
          [key]: value ? "R$" : "KWh",
        };
      }

      return {
        ...curr,
        [key]: value,
      };
    });
  };

  const onCalculateHandler = async () => {
    try {
      showLoading();

      const currErrors = validateForm(calculatorForm);

      const currErrorCpfOrCnpj =
        cpfOrCnpj.length !== 11 && cpfOrCnpj.length !== 14
          ? ["Verifique o preenchimento do CPF ou CNPJ"]
          : setCpfOrCnpjError(undefined);

      if (
        (currErrors && Object.keys(currErrors).length) ||
        currErrorCpfOrCnpj
      ) {
        if (currErrors && Object.keys(currErrors).length) setErrors(currErrors);
        if (Object.keys(currErrors).length === 0 && Object.keys(errors).length)
          setErrors({});
        if (currErrorCpfOrCnpj) setCpfOrCnpjError(currErrorCpfOrCnpj);
        window.scrollTo({ top: 0, behavior: "smooth" });
        return alert(
          "O formulário da calculadora possui erros, por favor verifique os campos para continuar"
        );
      }

      setErrors({});

      const userId = getCookieFromUser();

      await Analytics.sendClick({
        user: userId || "",
        location: "agro-calculator",
        eventLabel: "calculate-footprint",
      });

      questionsTop.current?.scrollIntoView({ behavior: "smooth" });

      const labelCpfOrCnpj = cpfOrCnpj.length === 11 ? "cpf" : "cnpj";

      const result = await CalculatorService.sendAgroInformation({
        ...calculatorForm,
        userId: userId || "",
        cpfOrCnpj: labelCpfOrCnpj,
        contactMail: calculatorForm.contactMail.toLowerCase().trim(),
        dieselUnit:
          calculatorForm.dieselExpenses && !calculatorForm.dieselUnit
            ? "l"
            : calculatorForm.dieselUnit,
        aircraftUnit:
          calculatorForm.aircraftExpenses && !calculatorForm.aircraftUnit
            ? "l"
            : calculatorForm.aircraftUnit,
        energyUnit:
          calculatorForm.energyExpenses && !calculatorForm.energyUnit
            ? "KWh"
            : calculatorForm.energyUnit,
        cpf:
          (labelCpfOrCnpj === "cpf" && maskCPFOrCNPJ(cpfOrCnpj)) || undefined,
        cnpj:
          (labelCpfOrCnpj === "cnpj" && maskCPFOrCNPJ(cpfOrCnpj)) || undefined,
      });

      setCalculatorResult(result);

      const url = `https://wa.me/${CONTACT_PHONE_NUMBER}`;

      const text = `
      Olá! Gostaria de compensar as emissões de carbono de minha propriedade. Aqui estão os meus dados:
      * Nome - ${calculatorForm.contactName}
      * Produtor - ${calculatorForm.producerName}
      * Emissões - ${result.totalEmissions
        .toFixed(2)
        .replace(".", ",")} toneladas de CO2e
      `;

      const encodedText = encodeURIComponent(text);

      setContactHref(url + "?text=" + encodedText);
    } catch (error) {
      newError("Houve um erro ao calcular suas emissões");
    } finally {
      hideLoading();
    }
  };

  const onSearch = (val: string, options: string[]) => {
    setSearch(val);

    const filteredArray = options.filter((item) =>
      item
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          val
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        )
    );

    setFiltered(filteredArray);
  };

  const onNewConsultHandler = async () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setCalculatorResult(undefined);
    setFormStep("crops");
    setCalculatorForm(emptyAgroCalculatorForm);
    setErrors({});
    setCpfOrCnpj("");
    setCurrCrop(emptyCrop);
    setCpfOrCnpjError(undefined);
    setContactHref("");
    setAircraft(true);
    setSolarEnergy(false);
    setQuestionNumber(1);

    const userId = getCookieFromUser();

    await Analytics.sendClick({
      user: userId || "",
      eventLabel: "new-consult",
      location: "agro-calculator",
    });
  };

  const onContactClickHandler = async () => {
    const userId = getCookieFromUser();

    await Analytics.sendClick({
      user: userId || "",
      eventLabel: "get-in-touch",
      location: "agro-calculator",
      meta: { section: "calculator", method: "whatsapp" },
    });
  };

  const onAddCrop = () => {
    const currErrors = validateCropForm(currCrop);

    if (currErrors && Object.keys(currErrors).length) {
      setQuestionNumber(1);
      setErrors(currErrors);
      window.scrollTo({ top: 0, behavior: "smooth" });
      questionsTop.current?.scrollIntoView({ behavior: "smooth" });
      return alert(
        "O formulário da calculadora possui erros, por favor verifique os campos para continuar"
      );
    }

    setCalculatorForm((curr) => ({
      ...curr,
      crops: [
        ...(curr.crops || []),
        {
          ...currCrop,
          harvestDate: maskDate(currCrop.harvestDate || ""),
          plantingDate: maskDate(currCrop.plantingDate || ""),
          herd: herds?.map((item) => ({
            ...item,
            entryDate: maskDate(item.entryDate || ""),
            departureDate: maskDate(item.departureDate || ""),
          })),
        },
      ],
    }));

    setCurrCrop(emptyCrop);
    setErrors({});
    setHerds(undefined);
    setQuestionNumber(1);

    questionsTop.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onGoToFacilities = () => {
    const currErrors = validateCropForm(currCrop);

    if (currErrors && Object.keys(currErrors).length) {
      setQuestionNumber(1);
      setErrors(currErrors);
      window.scrollTo({ top: 0, behavior: "smooth" });
      questionsTop.current?.scrollIntoView({ behavior: "smooth" });
      return alert(
        "O formulário da calculadora possui erros, por favor verifique os campos para continuar"
      );
    }

    setCalculatorForm((curr) => ({
      ...curr,
      crops: [
        ...(curr.crops || []),
        {
          ...currCrop,
          harvestDate: maskDate(currCrop.harvestDate || ""),
          plantingDate: maskDate(currCrop.plantingDate || ""),
          herd: herds?.map((item) => ({
            ...item,
            entryDate: maskDate(item.entryDate || ""),
            departureDate: maskDate(item.departureDate || ""),
          })),
        },
      ],
    }));

    setFormStep("facilities");
    setErrors({});
    setHerds(undefined);
    setQuestionNumber(1);

    questionsTop.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onRemoveCrop = (index: number) => {
    setCalculatorForm((curr) => {
      const newData = [...(curr.crops || [])];

      newData.splice(index, 1);

      return { ...curr, crops: newData };
    });

    setExcludeCrop(false);
  };

  const onAheadClick = () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setQuestionNumber((curr) => curr + 1);
  };

  const onPreviousClick = () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setQuestionNumber((curr) => curr - 1);
  };

  const onBackCrop = () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setFormStep("crops");
    setQuestionNumber(1);
  };

  const onExcludeCropCard = (index: number) => {
    setExcludeCropIndex(index);
    setExcludeCrop(true);
  };

  const onPreviousHerd = (index: number) => {
    setCurrHerd(index);
  };

  const onNextHerd = (index: number) => {
    setCurrHerd(index);
  };

  const onAddHerd = () => {
    const currErrors = validateHerdForm((herds && herds[currHerd]) || {});

    if (currErrors && Object.keys(currErrors).length) {
      setErrors(currErrors);
      window.scrollTo({ top: 0, behavior: "smooth" });
      questionsTop.current?.scrollIntoView({ behavior: "smooth" });
      return alert(
        "O formulário da calculadora possui erros, por favor verifique os campos para continuar"
      );
    }
    setErrors({});

    setHerds((curr) => [...(curr || []), {}]);

    setCurrHerd(herds?.length || 1);
  };

  const onRemoveHerd = (index: number) => {
    setHerds((curr) => {
      if (!curr) return;

      const newData = [...(curr || [])];

      newData.splice(index, 1);

      return [...newData];
    });

    setExcludeHerd(false);

    if (currHerd === 0) return;

    setCurrHerd((curr) => curr - 1);
  };

  const booleanOptions = ["Sim", "Não"];

  const transportUnitsOptions = Object.keys(transportUnitsObj);

  return (
    <S.Container result={!!calculatorResult}>
      <S.TitleAndProgress ref={questionsTop}>
        <S.TitleAndDescription>
          <H1 color={textColor} fontWeight="bold">
            Calculadora
          </H1>

          {!calculatorResult && (
            <H5 color={textColor}>
              Detalhe sua operação respondendo às perguntas abaixo,
              primeiramente sobre as culturas e, depois, sobre a propriedade, e
              descubra sua pegada mensal de carbono
            </H5>
          )}
        </S.TitleAndDescription>

        {!calculatorResult && <AgroCalculatorStep formStep={formStep} />}

        {!calculatorResult && (
          <ProgressBar
            currentQuestion={questionNumber}
            numberOfQuestions={formStep === "crops" ? 3 : 5}
          />
        )}
      </S.TitleAndProgress>

      <S.Content resultBox={!!calculatorResult}>
        {!calculatorResult &&
          calculatorForm.crops &&
          calculatorForm.crops.length > 0 &&
          calculatorForm.crops.map((curr, index) => (
            <S.CropCards key={`${curr.crop}#${index}`}>
              <CropCard
                crop={curr.crop || ""}
                herds={curr.herd?.length}
                harvestDate={curr.harvestDate || ""}
                onRemove={() => onExcludeCropCard(index)}
              />
            </S.CropCards>
          ))}

        {calculatorResult && (
          <S.ChartResult>
            <H2 color={textColor} fontWeight="bold">
              A pegada mensal de carbono de sua propriedade é:
            </H2>

            <H2 color={textColor}>
              {calculatorResult.totalEmissions.toFixed(2).replace(".", ",")}{" "}
              toneladas de CO<sub>2</sub>e
            </H2>

            <H3 color={textColor}>Nosso time entrará em contato em 24 horas</H3>

            <ContactButton
              type="large"
              href={contactHref}
              text="Compensar emissões de carbono"
              onClick={() => onContactClickHandler()}
            />
          </S.ChartResult>
        )}

        <S.CalculatorBox resultBox={!!calculatorResult}>
          {calculatorResult ? (
            <>
              <S.Charts>
                <S.ChartBox>
                  <ChartAndIndicators result={calculatorResult} />
                </S.ChartBox>
              </S.Charts>

              <S.Buttons>
                <ContactButton
                  type="medium"
                  href={contactHref}
                  text="Fale conosco"
                  onClick={() => onContactClickHandler()}
                />

                <Button
                  variant="solid"
                  fontWeight="bold"
                  textColor={primaryColor}
                  borderColor={primaryColor}
                  backgroundColor={backgroundColor}
                  onClick={() => onNewConsultHandler()}
                >
                  Nova consulta
                </Button>
              </S.Buttons>
            </>
          ) : (
            <Questions
              herds={herds}
              search={search}
              errors={errors}
              currHerd={currHerd}
              currCrop={currCrop}
              formStep={formStep}
              aircraft={aircraft}
              solarEnergy={solarEnergy}
              question={questionNumber}
              filteredOptions={filtered}
              formFields={calculatorForm}
              cpfOrCnpj={cpfOrCnpj || ""}
              onAddHerd={() => onAddHerd()}
              onAddCrop={() => onAddCrop()}
              onBackCrop={() => onBackCrop()}
              booleanOptions={booleanOptions}
              cpfOrCnpjError={cpfOrCnpjError}
              onAheadClick={() => onAheadClick()}
              onNextHerd={(val) => onNextHerd(val)}
              transportOptions={transportUnitsOptions}
              onRemoveHerd={() => setExcludeHerd(true)}
              setCpfOrCnpj={(val) => setCpfOrCnpj(val)}
              onPreviousClick={() => onPreviousClick()}
              onSearch={(val, opt) => onSearch(val, opt)}
              onChangeFormStep={() => onGoToFacilities()}
              onClearSearch={() => setFiltered(undefined)}
              onCalculateClick={() => onCalculateHandler()}
              onPreviousHerd={(val) => onPreviousHerd(val)}
              setAircraft={() => setAircraft((curr) => !curr)}
              setSolarEnergy={() => setSolarEnergy((curr) => !curr)}
              onChangeForm={(key, val) => onChangeHandler(key, val)}
              onChangeCropForm={(key, val) => onChangeCropHandler(key, val)}
              onChangeHerdForm={(key, index, val) =>
                onChangeHerdHandler(key, index, val)
              }
            />
          )}
        </S.CalculatorBox>
      </S.Content>

      {excludeHerd && (
        <ExcludeModal
          isOpen={excludeHerd}
          onClose={() => setExcludeHerd(false)}
          onExclude={() => onRemoveHerd(currHerd)}
        />
      )}

      {excludeCrop && (
        <ExcludeModal
          isOpen={excludeCrop}
          onClose={() => setExcludeCrop(false)}
          onExclude={() => onRemoveCrop(excludeCropIndex)}
        />
      )}

      <S.Disclamer>
        <P color="#00000060">
          Disclamer: O cálculo da pegada de carbono em nossa calculadora virtual
          é realizado com base em informações precisas, transparentes e
          atualizadas sobre ações e iniciativas, fornecidas unilateralmente pelo
          cliente. Após o fornecimento das informações de maneira unilateral, o
          selo IBIOMA será emitido e devidamente fornecido ao cliente. Ou O
          cálculo da pegada de carbono em nossa calculadora virtual é realizado
          com base em informações precisas, transparentes e atualizadas sobre
          ações e iniciativas, fornecidas unilateralmente pelo cliente. A equipe
          responsável pela Calculadora IBIOMA não se responsabiliza por
          discrepâncias causadas por dados incorretos ou desatualizados.
        </P>
      </S.Disclamer>
    </S.Container>
  );
};

export default Calculator;
