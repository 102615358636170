import styled, { css } from "styled-components";

export const Container = styled.div`
  gap: 100px;
  width: 100%;
  display: flex;
  padding: 0 80px;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 1000px) {
    gap: 40px;
    justify-content: space-between;
  }

  @media (max-width: 740px) {
    gap: 20px;
    padding: 0 40px;
    margin-bottom: 60px;
    flex-direction: column;
  }
`;

type ImageProps = {
  image: string;
};

export const Image = styled.div<ImageProps>`
  ${({ image }) => css`
    width: 50%;
    height: 200px;
    margin: 40px 0;
    max-width: 800px;
    border-radius: 12px;
    background: url(${image});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 900px) {
      height: 300px;
      flex-direction: column;
    }

    @media (max-width: 740px) {
      width: 200px;
      height: 100px;
      margin: 60px 0 0;
    }
  `}
`;

export const TextBox = styled.div`
  width: 80%;
  display: flex;
  text-align: justify;
  flex-direction: column;

  h3 {
    line-height: 140%;

    span {
      line-height: 140%;
      font-weight: bold;
      font-size: 1.25rem;
    }
  }

  @media (max-width: 830px) {
    width: 100%;
  }
`;
