import * as S from "./styles";

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  labels: string[];
  selectedSheet: number;
  setSelectedSheet: (index: number) => void;
}

const Sheets: React.FC<IProps> = ({
  labels,
  children,
  selectedSheet,
  setSelectedSheet,
}) => {
  return (
    <S.Container>
      <S.Menu>
        {labels.map((item, index) => (
          <S.Sheet
            key={`${item}#${index}`}
            isSelected={selectedSheet === index}
            onClick={() => setSelectedSheet(index)}
          >
            {item}
          </S.Sheet>
        ))}
      </S.Menu>

      {children}
    </S.Container>
  );
};

export default Sheets;
