import { primaryColor } from "../../constants/colors";

interface IProps {
  color?: string;
}

const CalculatorIcon: React.FC<IProps> = ({ color }) => {
  return (
    <svg
      id="Camada_1"
      data-name="Camada 1"
      viewBox="0 0 1000 1000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="dMLGd7.tif">
        <g>
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m199,909.45V138c7.26-24.41,22.09-41.43,47.37-47.87,8.72-2.22,17.75-2.2,26.72-2.2,157.44,0,314.89-.01,472.33.03,5.66,0,11.34.36,16.99.83,32.54,2.71,57.4,28.83,57.42,61.22.11,249.07.09,498.14.01,747.21,0,20.88-9.31,37.29-25.73,49.86-10.06,7.71-21.87,10.63-34.04,12.6h-501.93c-30.55-3.97-50.43-20.52-59.16-50.23Zm594.49-384.82c0-122.87.02-245.74-.02-368.62,0-26.12-14.49-40.76-40.35-40.77-162.5-.03-325-.03-487.5,0-26.54,0-40.86,14.43-40.87,41.15-.02,244.85-.02,489.69,0,734.54,0,26.76,14.3,41.25,40.79,41.26,162.5.03,325,.02,487.5-.02,3.86,0,7.82-.05,11.57-.87,18.35-4.02,28.86-18.52,28.86-39.86.04-122.27.02-244.55.02-366.82Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m509.13,187.61c66.02,0,132.04-.02,198.07,0,23.21,0,32.04,8.81,32.05,31.84.02,34.08.05,68.17-.02,102.25-.04,18.32-8.47,27.52-26.69,29.2-4.45.41-8.95.39-13.43.39-126.67.02-253.33.02-380,.01-33.75,0-40.13-6.46-40.13-40.38,0-31.39-.05-62.79.02-94.18.04-19.32,9.83-29.09,29.37-29.11,66.92-.07,133.84-.03,200.76-.03Zm-.07,136.38c64.21,0,128.42-.13,192.62.16,8.66.04,11.43-2.76,11.31-11.38-.42-29.29-.38-58.58-.02-87.87.1-8.09-2.36-11.01-10.73-11-128.71.21-257.43.2-386.15,0-8.25-.01-10.95,2.67-10.84,10.89.38,28.69.13,57.39.15,86.08,0,13.08.03,13.11,12.83,13.12,63.61.01,127.22,0,190.83,0Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m724.89,716.38c0,36.16.04,72.32-.02,108.48-.03,17.82-7.29,26.18-25.05,26.83-19.38.71-38.82.71-58.21.04-17.94-.62-25.47-9.27-25.49-27.56-.05-72.02-.06-144.04,0-216.06.02-18.75,8.61-27.27,27.49-27.39,18.21-.12,36.43-.15,54.64.02,18.36.17,26.58,8.62,26.6,27.17.05,36.16.02,72.32.02,108.48Zm-26.43.2c0-32.56-.15-65.12.11-97.68.06-7.5-2.14-11.07-10.14-10.81-11.63.39-23.28.3-34.92.04-7.22-.16-10.35,2.87-10.22,10.1.2,11.94.02,23.9.02,35.84,0,52.87-.03,105.75.03,158.62.01,10.91,1,11.8,11.74,11.86,10.74.06,21.51-.43,32.23.15,8.67.47,11.42-2.73,11.31-11.36-.41-32.26-.16-64.52-.16-96.78Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m293.37,635.64c0-10.45-.16-20.91.04-31.36.27-13.61,8.44-22.9,22.06-23.29,21.46-.62,42.97-.62,64.44.01,13.48.4,21.91,9.71,22.12,23.38.31,20.9.33,41.81-.02,62.71-.22,13.02-7.94,21.45-20.89,22.42-22.32,1.67-44.73,1.69-67.05-.02-13.08-1-20.36-9.38-20.64-22.51-.22-10.45-.04-20.9-.05-31.36Zm53.57,27.41c6.86,0,13.73-.14,20.59.04,5.7.16,8.17-2.38,8.12-8.03-.1-12.84-.13-25.69,0-38.53.07-6.23-3.01-8.43-8.86-8.37-12.83.14-25.67.13-38.5.02-6.01-.06-8.66,2.54-8.56,8.64.19,12.54.2,25.09,0,37.63-.1,6.03,2.22,8.86,8.41,8.64,6.26-.22,12.53-.05,18.8-.05Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m293.36,796.6c0-10.16-.14-20.31.04-30.47.24-13.69,8.23-23.09,21.81-23.54,21.46-.71,42.98-.68,64.45-.05,13.62.4,22.14,9.61,22.36,23.17.34,20.9.33,41.82,0,62.72-.2,13.09-7.62,21.66-20.64,22.72-22.31,1.81-44.74,1.72-67.05.05-13.71-1.03-20.69-9.56-20.92-23.24-.17-10.45-.03-20.91-.03-31.36Zm53.59,27.92c7.16,0,14.32-.05,21.48.02,4.85.05,7.15-2.35,7.15-7.1,0-13.43,0-26.87,0-40.3,0-4.67-2.16-7.29-7.02-7.3-13.72,0-27.44,0-41.16-.03-5.15,0-7.65,2.41-7.62,7.66.08,13.14.08,26.27-.01,39.41-.04,5.21,2.22,7.74,7.5,7.66,6.56-.09,13.12-.02,19.69-.03Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m563.51,635.46c0,10.15.16,20.31-.04,30.46-.28,14.21-7.54,22.64-21.51,23.67-21.73,1.6-43.54,1.53-65.28.05-14.11-.96-21.71-9.38-21.88-23.33-.24-20.6-.24-41.22,0-61.82.16-13.98,8.81-23.26,22.8-23.59,20.88-.5,41.78-.48,62.66-.03,14.46.31,22.96,9.56,23.22,24.12.18,10.15.04,20.31.03,30.46Zm-54.86,27.58c6.87,0,13.74-.09,20.61.03,5.14.09,7.81-2.05,7.79-7.41-.07-13.16-.07-26.31,0-39.47.03-5.09-2.06-7.93-7.38-7.93-13.74,0-27.48,0-41.22,0-5.32,0-7.37,2.86-7.33,7.96.09,12.86.17,25.72-.05,38.57-.11,6.31,2.83,8.51,8.76,8.31,6.27-.22,12.55-.05,18.82-.06Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m563.5,797.27c0,9.85.13,19.71-.03,29.56-.24,14.82-7.24,23.45-21.72,24.49-21.71,1.55-43.54,1.55-65.26,0-14.44-1.03-21.59-9.73-21.71-24.5-.17-20-.18-40.01,0-60.01.14-14.87,8.92-24.1,23.91-24.42,20.28-.43,40.57-.44,60.85,0,15.03.32,23.65,9.51,23.91,24.43.18,10.15.03,20.3.04,30.45Zm-54.93,27.28c6.57,0,13.15-.17,19.71.05,5.89.2,8.92-1.94,8.82-8.26-.2-12.85-.15-25.7-.03-38.56.05-5.76-2.69-8.06-8.22-8.02-13.14.09-26.28.12-39.42-.01-6.18-.06-8.45,2.84-8.36,8.81.2,12.55.21,25.11,0,37.66-.11,6.25,2.71,8.59,8.69,8.38,6.26-.22,12.54-.05,18.81-.05Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m346.87,527.97c-10.44,0-20.89.19-31.33-.05-13.17-.29-21.88-8.73-22.07-21.98-.31-21.5-.3-43,0-64.5.18-12.54,8.75-21.71,21.35-22.03,21.77-.55,43.57-.57,65.33,0,13.03.34,21.62,9.56,21.84,22.65.35,21.2.38,42.41-.02,63.6-.26,13.63-9.23,21.89-22.89,22.31-.6.02-1.19.02-1.79.02-10.14,0-20.29,0-30.43-.02Zm.47-26.43c6.87,0,13.74-.1,20.6.03,5.26.11,7.69-2.3,7.68-7.52-.05-13.15-.04-26.3,0-39.45.01-5.04-2.12-7.93-7.43-7.93-13.73,0-27.47-.01-41.2.03-4.7.02-7.17,2.38-7.17,7.2.01,13.45.05,26.9-.03,40.34-.03,5.43,2.77,7.41,7.85,7.32,6.57-.11,13.14-.03,19.71-.03Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m670.54,527.96c-10.74,0-21.49.22-32.22-.06-12.64-.32-21.71-8.65-22-21.28-.5-21.79-.46-43.6-.04-65.39.24-12.48,9.03-21.49,21.7-21.82,21.76-.57,43.57-.57,65.33,0,12.93.33,21.32,9.7,21.47,22.87.24,20.9.24,41.81,0,62.71-.16,14.38-8.67,22.73-22.93,22.97-10.44.17-20.89.03-31.33.01Zm27.88-53.79c0-6.57-.07-13.15.02-19.72.07-5.21-2.39-7.78-7.55-7.77-13.73,0-27.47,0-41.2.03-4.83.01-7.03,2.7-7.04,7.36-.02,13.45-.01,26.9-.01,40.34,0,4.74,2.22,7.16,7.11,7.14,13.73-.06,27.47-.07,41.21.02,5.3.03,7.59-2.45,7.5-7.67-.12-6.57-.03-13.15-.04-19.72Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m509.17,527.98c-10.44,0-20.88.14-31.31-.03-14.02-.24-22.84-8.84-23.03-23.02-.28-20.89-.28-41.78.01-62.67.18-13.11,8.61-22.46,21.58-22.82,21.75-.61,43.54-.6,65.29-.01,12.99.35,21.49,9.65,21.67,22.75.3,21.18.35,42.38-.03,63.56-.24,13.58-9.23,21.86-22.88,22.24-.6.02-1.19.02-1.79.02-9.84,0-19.68,0-29.52-.01Zm-28.02-53.59c0,6.55.08,13.11-.03,19.66-.09,5.27,2.4,7.56,7.6,7.52,13.69-.09,27.38-.07,41.07-.02,4.75.02,7.23-2.12,7.22-6.97,0-13.4-.04-26.81.02-40.21.02-5.19-2.47-7.7-7.6-7.7-13.39,0-26.78,0-40.18-.04-5.48-.02-8.27,2.39-8.14,8.1.15,6.55.03,13.11.03,19.66Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default CalculatorIcon;
