import * as S from "./styles";
import H4 from "../Typography/H4";

interface IProps {
  label: string;
  isSelected: boolean;
  setSelected: () => void;
}

const Option: React.FC<IProps> = ({ label, isSelected, setSelected }) => {
  return (
    <S.Container onClick={setSelected}>
      <S.OptionBox>
        <S.OptionSelected isSelected={isSelected} />
      </S.OptionBox>

      <H4 color="#000000">{label}</H4>
    </S.Container>
  );
};

export default Option;
