import * as S from "./styles";
import CropsIcon from "../../icons/CropsIcon";
import FacilitiesIcon from "../../icons/FacilitiesIcon";
import H3 from "../../atoms/Typography/H3";

interface IProps {
  formStep: "crops" | "facilities";
}

const AgroCalculatorStep: React.FC<IProps> = ({ formStep }) => {
  return (
    <S.Container>
      <S.IconAndLabel>
        <S.Icon>
          <CropsIcon color="#257034" />
        </S.Icon>

        {formStep === "crops" && (
          <H3 color="#257034" fontWeight="bold">
            Culturas
          </H3>
        )}
      </S.IconAndLabel>

      <S.Separators>
        {[1, 2, 3, 4, 5].map((item) => (
          <S.Separator
            key={item}
            color={formStep === "facilities" ? "#257034" : "#D9D9D9"}
          />
        ))}
      </S.Separators>

      <S.IconAndLabel>
        <S.Icon>
          <FacilitiesIcon
            color={formStep === "facilities" ? "#257034" : "#D9D9D9"}
          />
        </S.Icon>

        {formStep === "facilities" && (
          <H3 color="#257034" fontWeight="bold">
            Propriedade
          </H3>
        )}
      </S.IconAndLabel>
    </S.Container>
  );
};

export default AgroCalculatorStep;
