import { ReactElement } from "react";

import * as S from "./styles";

type NivoChartContainerProps = {
  children: ReactElement;
  type: "bar" | "pie";
};

const NivoChartContainer: React.FC<NivoChartContainerProps> = ({
  type,
  children,
}) => {
  return (
    <S.Container>
      <S.Box chart={type}>{children}</S.Box>
    </S.Container>
  );
};

export default NivoChartContainer;
