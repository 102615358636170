export function getCookieFromUser() {
  if (typeof document === "undefined") return null;

  const cookies = document.cookie.split(";");

  if (!cookies || cookies.length === 0) return null;

  const cookieNames: string[] = cookies.map((curr) => curr.split("=")[0]);

  const userIndex = Math.max(
    cookieNames.indexOf(" userIdOma"),
    cookieNames.indexOf("userIdOma"),
    cookieNames.indexOf(" userIdIbioma"),
    cookieNames.indexOf("userIdIbioma")
  );

  if (userIndex === -1) return null;

  const userIdIbioma = cookies[userIndex].split("=")[1];

  return userIdIbioma;
}
