import * as S from "./styles";
import SwitchBase from "../../atoms/SwitchBase";

interface IProps {
  isOn: boolean;
  color?: string;
  option1: string;
  option2: string;
  labelSize?: "medium" | "small";
  setIsOn?: (isOn: boolean) => void;
}

const Switch: React.FC<IProps> = ({
  isOn,
  color,
  option1,
  option2,
  setIsOn,
  labelSize = "medium",
}) => {
  return (
    <S.Container>
      <S.Label
        fontWeight="regular"
        labelSize={labelSize}
        color={color || "#fafafa"}
      >
        {option1}
      </S.Label>

      <SwitchBase isOn={isOn} setIsOn={setIsOn} />

      <S.Label
        fontWeight="regular"
        labelSize={labelSize}
        color={color || "#fafafa"}
      >
        {option2}
      </S.Label>
    </S.Container>
  );
};

export default Switch;
