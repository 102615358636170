import { primaryColor } from "../../constants/colors";

interface IProps {
  color?: string;
}

const CoinStackIcon: React.FC<IProps> = ({ color }) => {
  return (
    <svg
      id="Camada_1"
      data-name="Camada 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
    >
      <g id="_24QElN.tif" data-name="24QElN.tif">
        <g>
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m53.3,669.22v-80.99c11.24-29.12,31.11-46.44,63.65-46.11,8.52.09,8.23-4.22,7.2-10.8-4.12-26.38-4.29-52.96,0-79.25,1.49-9.11-.16-12.29-8.89-15.08-26.07-8.33-42.6-30.76-43.4-57.97-.5-17.05-.73-34.15.07-51.18,1.11-23.46,11.97-41.6,32.68-53.31,12.14-6.86,25.54-8.17,39.18-8.17,84.6.01,169.2-.1,253.8.14,10.02.03,18.13-2.69,26.36-8.52,72.89-51.61,153.54-72.43,242.29-59.07,176.54,26.58,299.79,187.29,277.08,364.34-15.86,123.61-83.4,210.88-196.53,262.85-33.88,15.56-70.11,22.49-106.96,26.52H143.9c-30.13-8.42-49.52-26.99-51.74-59.04-1.66-23.99-3.41-48.3,3.22-72.05,1.3-4.65,1.6-8.57-3.88-10.71-19.53-7.63-30.82-22.84-38.19-41.62Zm564.9,147.06c160.72.02,292.54-129.96,291.89-291.9-.7-173.69-141-292.65-292.23-293.08-152.01-.43-293.21,119.2-293.51,291.6-.3,176.39,143.21,295.22,293.85,293.38Zm-157.78.1c-42.08-26.15-78.19-57.21-107.44-96.11-3.24-4.32-7.76-4.96-12.71-4.96-61.13.05-122.26-.11-183.39.13-18.17.07-28.42,10.83-28.8,29.21-.25,11.89-.09,23.79-.08,35.69.04,27.97,8.04,36.01,36.15,36.03,94.87.04,189.74.02,284.61.02h11.65ZM248.71,303.18c-37.29,0-74.59-.17-111.88.07-18.16.12-27.8,9.88-28.18,28.02-.3,13.87-.21,27.75-.07,41.62.22,21.97,9.24,31.16,31.13,31.2,52.77.08,105.54-.27,158.3.08,9.49.06,14.07-3.51,17.7-12,12.2-28.6,28.48-54.82,48.51-78.68,1.98-2.36,5.79-4.59,4.67-7.77-1.5-4.29-6.19-2.46-9.48-2.47-36.9-.13-73.8-.08-110.69-.05Zm-33.61,375.56c32.93,0,65.86-.19,98.79.11,10.1.09,11.64-2.87,7.12-11.74-12.1-23.78-20.68-48.91-25.64-75.12-1.91-10.12-6.81-13.51-16.99-13.5-35.69.05-71.38.91-107.08-.94-17.41-.9-34.93-.64-52.35.09-21.27.89-30.82,11.13-31.28,32.33-.27,12.28-.25,24.58,0,36.86.43,22.15,9.9,31.65,32.22,31.82,31.74.25,63.48.06,95.22.08Zm19.96-237.43c-16.66,0-33.32-.18-49.97.05-16.46.23-25.86,9.21-26.39,25.65-.49,15.04-.38,30.11-.14,45.16.31,19.87,10.16,29.64,30.33,29.81,28.95.24,57.91-.14,86.86.19,8.97.1,13.23-2.73,12.78-12.27-1.24-26.26,1.64-52.21,7.51-77.79,1.82-7.95-.5-11-8.62-10.89-17.45.25-34.9.08-52.35.09Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m381.51,523.64c-.02-129.22,105.51-235.17,234.72-235.63,130.19-.47,236.54,105.79,236.6,236.19.05,122.62-96.56,234.31-235.98,235.34-130.02.96-235.31-105.79-235.33-235.9Zm236.49-199.36c-111.61-.04-199.96,88.09-199.83,199.31.13,111.53,88.21,199.75,199.5,199.8,111.04.05,200.17-88.75,200.21-199.45.03-110.87-88.81-199.62-199.87-199.66Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m583.25,475.69c-.04,15.01,4.96,22,19.27,25.91,12.25,3.35,24.64,6.16,36.94,9.3,28.57,7.31,45.69,26.33,48.22,53.47,2.29,24.61-13.3,51.75-37.48,62.57-9.69,4.34-15.38,8.69-15.26,20.53.12,11.55-7.99,18.16-18.15,17.82-11.15-.37-17.17-7.36-17.42-17.41-.27-10.71-5.01-14.72-14.6-18.03-22.33-7.69-34.32-24.71-37.73-47.77-1.7-11.51,4.39-20.97,14.09-22.9,10.63-2.12,18.94,4.24,21.84,16.72,3.87,16.68,9.2,21.11,26.13,21.35,5.95.08,11.98.25,17.84-.54,12.94-1.75,22.99-12.97,23.92-25.92.94-13.09-5.3-20.86-20.4-24.86-12.27-3.25-24.66-6.1-36.95-9.27-28.56-7.36-45.85-28.31-47.13-56.94-1.37-30.8,13.54-53.78,41.97-63.12,7.25-2.38,10.09-5.83,10.6-13.33.95-13.96,7.36-20.68,18.09-20.74,10.81-.06,17.44,6.63,18.27,20.53.49,8.25,4.1,11.41,11.59,13.84,23.35,7.58,36.57,24.27,40.28,48.4,1.86,12.12-4.43,21.85-14.7,23.45-10.38,1.62-18.23-4.47-21.06-16.33-4.2-17.59-9.1-21.58-26.93-21.8-5.56-.07-11.12-.1-16.68.03-17.13.4-24.51,7.96-24.55,25.02Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default CoinStackIcon;
