import styled from "styled-components";

const InputBase = styled.input`
  width: 100%;
  resize: none;
  outline: none;
  color: #000000;
  font-size: 1rem;

  &::placeholder {
    color: #00000080;
  }
`;

export default InputBase;
