const FugitivesIcon: React.FC = () => {
  return (
    <svg
      width="537"
      height="537"
      viewBox="0 0 537 537"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.6665 168.667H218.666C233.5 168.667 248.001 164.268 260.334 156.027C272.668 147.786 282.281 136.072 287.957 122.368C293.634 108.663 295.119 93.5833 292.225 79.0348C289.331 64.4862 282.188 51.1225 271.699 40.6335C261.211 30.1446 247.847 23.0015 233.298 20.1076C218.75 17.2138 203.67 18.699 189.965 24.3756C176.261 30.0521 164.547 39.6651 156.306 51.9988C148.065 64.3325 143.666 78.8329 143.666 93.6665M68.6665 368.666H343.666C358.5 368.666 373.001 373.065 385.334 381.306C397.668 389.547 407.281 401.261 412.957 414.965C418.634 428.67 420.119 443.75 417.225 458.298C414.331 472.847 407.188 486.211 396.699 496.7C386.211 507.188 372.847 514.331 358.298 517.225C343.75 520.119 328.67 518.634 314.965 512.957C301.261 507.281 289.547 497.668 281.306 485.334C273.065 473.001 268.666 458.5 268.666 443.666M18.6665 268.666H443.666C458.5 268.666 473.001 264.268 485.334 256.027C497.668 247.786 507.281 236.072 512.957 222.368C518.634 208.663 520.119 193.583 517.225 179.035C514.331 164.486 507.188 151.122 496.699 140.634C486.211 130.145 472.847 123.002 458.298 120.108C443.75 117.214 428.67 118.699 414.965 124.376C401.261 130.052 389.547 139.665 381.306 151.999C373.065 164.332 368.666 178.833 368.666 193.667"
        stroke="black"
        strokeWidth="36"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FugitivesIcon;
