import { primaryColor } from "../../constants/colors";

interface IProps {
  color?: string;
}

const CO2CycleIcon: React.FC<IProps> = ({ color }) => {
  return (
    <svg
      id="Camada_1"
      data-name="Camada 1"
      viewBox="0 0 1000 1000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="fy7iER">
        <g>
          <path
            d="m499.22,688.41c-58.09,0-116.18.5-174.27-.15-52.94-.59-92.47-25.48-114.75-72.9-22.37-47.61-15.03-93.7,17.82-134.88,11.8-14.79,27.18-25.96,44.82-33.06,9.43-3.8,13.36-9.73,15.64-19.44,11.93-50.8,43.4-82.76,94.7-93.72,24.03-5.14,47.93-2.91,70.11,8.4,9.68,4.94,16.51,3.56,25.07-3.07,89.24-69.1,214.81-20.14,233.77,91.01,1.45,8.48,5.57,12.6,13.29,15.96,53.23,23.14,81.95,73,76.24,131.25-4.85,49.48-45.57,95.5-94.74,106.93-10.97,2.55-22.09,3.78-33.42,3.75-58.09-.14-116.18-.06-174.27-.07Zm1.09-22.87c58.86,0,117.73-.24,176.59.1,27.93.16,51.39-10.53,71.03-29.08,56.99-53.84,34.84-147.61-41-173.56-11.2-3.83-15.36-9.85-15.86-21.03-.86-19.51-7.38-37.68-17.95-53.82-43.86-66.92-132.99-77.33-191.08-22.32-8.68,8.22-15.91,8.65-25.86,2.81-61.59-36.11-139.5,1.49-148.21,72.25-1.74,14.17-7.48,20.15-19.76,24.28-26.65,8.95-45.94,27.39-57.37,52.41-32.45,71.01,17.07,147.56,95.12,147.91,58.11.26,116.23.05,174.34.05Z"
            fill={color || primaryColor}
          />
          <path
            d="m861.73,738.34c-4.54.18-5.25,3.08-6.62,5.15-76.02,114.38-182.89,179.27-319.07,191.28-120.76,10.65-227.92-25.81-318.72-107.3-76.54-68.69-120.38-155.11-134.99-256.11-9.08-62.74-3.31-124.89,16.33-185.46,1.15-3.56,2.19-7.27,4.06-10.45,2.7-4.6,6.98-6.69,12.46-5.17,5.52,1.53,8.11,5.61,7.86,10.91-.19,4.03-1.51,8.06-2.73,11.97-30.5,98.24-25.29,194.31,17.49,288.08,38.12,83.56,98.85,145.9,179.79,188.22,49.66,25.96,102.76,40.81,159.18,43.74,107.3,5.57,201.64-27.01,283.52-95.66,28.79-24.14,53.53-52.41,73.93-84.19,1.75-2.73,5.31-5.59,3.48-8.91-2.12-3.85-6.16-1.02-9.18-.46-14,2.58-27.93,5.55-41.91,8.26-7.96,1.55-15.99,1.36-17.73-8.47-1.6-9.03,5.22-12.16,12.89-13.72,25.34-5.15,50.64-10.5,75.99-15.58,13.68-2.74,17.74,0,20.69,13.32,5.43,24.51,10.74,49.05,16.29,73.54,1.87,8.24,2.99,16.76-7.15,19.43-10.49,2.77-13.24-5.42-15.04-13.69-3.55-16.33-7.25-32.63-10.84-48.73Z"
            fill={color || primaryColor}
          />
          <path
            d="m139.88,294.16c7.66-10.95,13.53-19.86,19.92-28.39,74.32-99.15,173.01-156.89,296.17-169.86,123.13-12.97,232.17,22.19,325.06,104.32,81.03,71.65,126.29,162.35,138.24,269.58,6.6,59.22,1.18,117.44-17.45,174.27-2.81,8.57-5.17,18.44-16.62,14.9-10.84-3.35-7.72-13.06-5.13-20.99,10.38-31.9,15.92-64.62,18.54-98.04,3.75-47.73-1.98-94.42-16-139.84-30.34-98.26-89.8-174.46-177.73-227.82-55.44-33.64-115.9-52-180.78-55.78-84.57-4.94-162.82,15.24-234.7,59.54-50.5,31.12-91.89,71.92-123.91,122-1.5,2.34-4.83,4.87-2.34,7.84,2.22,2.65,5.61.63,8.47.05,13.95-2.85,27.88-5.78,41.85-8.56,7.89-1.57,16.01-1.6,18.03,8.11,2.05,9.88-5.37,12.5-13.43,14.13-25.71,5.21-51.41,10.48-77.01,16.17-10.16,2.26-15.96-1.08-18.16-11.09-5.88-26.72-11.78-53.43-17.62-80.16-1.52-6.95-.46-13.27,7.36-15.25,8.05-2.04,12.78,2.2,14.54,10.08,2.93,13.17,5.9,26.33,8.9,39.49.97,4.25,2.1,8.46,3.81,15.28Z"
            fill={color || primaryColor}
          />
          <path
            d="m610.94,519.11c-.02,42.58-29.39,72.38-70.85,71.9-40.98-.48-70.84-30.38-71.21-71.31-.37-41.94,29.98-72.61,71.84-72.59,41.89.02,70.23,29.09,70.21,72Zm-69.7-49.26c-28.48-.15-47.67,19.03-47.93,47.91-.27,29.24,19.31,50.37,46.88,50.61,27.14.23,47.61-20.77,47.66-48.92.05-28.59-19.56-49.46-46.61-49.6Z"
            fill={color || primaryColor}
          />
          <path
            d="m413.75,447.08c13.61-.65,26.87,3.55,38.64,12.56,6.26,4.8,10.45,10.66,5.03,18.1-5.04,6.91-11.6,4.85-17.93.98-20.7-12.67-40.52-11.85-56.81,2.19-15.42,13.29-20.77,37.35-12.79,57.57,10.26,26,39.64,36.89,64.09,23.75,1.32-.71,2.58-1.52,3.86-2.29,6.59-3.95,13.38-8.44,19.58.24,4.72,6.61,1.48,13.52-8.7,19.76-25.54,15.66-57.32,13.95-79.86-4.3-23.63-19.14-32.39-52.54-21.66-82.56,9.94-27.79,35.69-45.91,66.57-45.99Z"
            fill={color || primaryColor}
          />
          <path
            d="m657,614.03c-5.24,0-10.48.09-15.72-.03-5.51-.13-11.83,1-14.54-5.66-2.77-6.82.53-12.09,5.05-17.17,9.94-11.17,19.68-22.53,29.24-34.03,2.58-3.1,4.76-6.8,6.13-10.58,2.06-5.69.05-10.71-5.68-12.83-5.91-2.19-11.08-.44-14.05,5.66-.32.66-.52,1.41-.68,2.13-1.68,8.04-5.04,15.36-14.75,12.45-10.29-3.08-6.76-12.2-5.26-19.08,3.75-17.21,22.76-26.75,40.78-21.09,17.07,5.37,25.17,24.3,17.92,42.11-3.75,9.22-11.14,15.83-17.13,23.44-2.93,3.73-9.54,6.74-7.95,11.27,1.79,5.14,8.61,1.91,13.17,2.64,2.57.41,5.29.13,7.78.78,5.38,1.41,8.14,5.12,7.84,10.75-.29,5.35-3.67,8.4-8.61,8.69-7.82.46-15.7.12-23.55.12,0,.14,0,.28,0,.41Z"
            fill={color || primaryColor}
          />
        </g>
      </g>
    </svg>
  );
};

export default CO2CycleIcon;
