import styled, { css } from "styled-components";

import Button from "../../../../../../components/atoms/Button";
import { Box } from "../../../../../Calculadora/components/Calculator/components/Questions/styles";

export const Container = styled.div`
  gap: 48px;
  width: 100%;
  display: flex;
  padding: 0 300px;
  min-height: 400px;
  flex-direction: column;

  @media (max-width: 1700px) {
    padding: 0 200px;
  }

  @media (max-width: 1400px) {
    padding: 0 100px;
  }

  @media (max-width: 1080px) {
    width: 100%;
  }

  @media (max-width: 930px) {
    padding: 0 80px;
  }

  @media (max-width: 830px) {
    padding: 0 60px;
  }

  @media (max-width: 600px) {
    padding: 0 20px;
  }

  @media (max-width: 480px) {
    padding: 0;
  }
`;

export const QuestionTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuestionTitleHerd = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Questions = styled.div`
  gap: 32px;
  width: 100%;
  display: flex;
  padding: 0 40px;
  flex-direction: column;

  @media (max-width: 580px) {
    padding: 0;
  }
`;

export const QuestionsBox = styled.div`
  gap: 12px;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const QuestionsHerd = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;

  button {
    width: 180px;
    align-self: flex-end;
  }
`;

export const BackHerd = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    transform: rotate(180deg);
  }
`;

export const NextHerd = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const AddHerd = styled.div`
  gap: 8px;
  padding: 12px;
  display: flex;
  color: #257034;
  cursor: pointer;
  font-weight: bold;
  border-radius: 16px;
  align-items: center;
  border: 1px dashed #257034;
  background-color: transparent;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const QuestionsGrid = styled.div`
  gap: 24px;
  width: 100%;
  display: grid;
  padding: 0 40px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1170px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 820px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 580px) {
    padding: 0;
  }
`;

export const Question = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const QuestionSmall = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CompanyQuestions = styled.div`
  gap: 32px;
  display: flex;
  flex-wrap: wrap;
  padding: 0 40px;
`;

type CompanyQuestionProps = {
  maxWidth: string;
};

export const CompanyQuestion = styled.div<CompanyQuestionProps>`
  ${({ maxWidth }) => css`
    gap: 32px;
    width: 100%;
    display: flex;
    max-width: ${maxWidth};
  `}
`;

export const SliderAndNumber = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Buttons = styled.div`
  display: flex;
  margin-bottom: 40px;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

export const CropButtons = styled.div`
  gap: 40px;
  width: 100%;
  display: flex;
  margin-left: 40px;
  justify-content: flex-end;
`;

export const RemoveHerd = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 820px) {
    margin-top: 20px;
  }
`;

type ButtonsProps = {
  width?: string;
  isVisible: boolean;
};

export const Clickable = styled(Button)<ButtonsProps>`
  ${({ isVisible, width }) => css`
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: ${width ? width : "126px"};
    display: ${isVisible ? "flex" : "none"};
  `}
`;

type BoxProps = {
  marginTop?: string;
};

export const BoxContent = styled(Box)<BoxProps>`
  ${({ marginTop }) => css`
    margin-top: ${marginTop ? marginTop : 0};
  `}
`;
