import * as S from "./styles";
import Button from "../../atoms/Button";
import H4 from "../../atoms/Typography/H4";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const DisclamerPopUp: React.FC<IProps> = ({ isOpen, onClose }) => {
  return (
    <S.Content isOpen={isOpen}>
      <S.Backdrop />

      <S.Modal>
        <H4 color="#000000">
          O cálculo da pegada de carbono em nossa calculadora virtual é
          realizado com base em informações precisas, transparentes e
          atualizadas sobre ações e iniciativas, fornecidas unilateralmente pelo
          cliente. A equipe responsável pela Calculadora IBIOMA não se
          responsabiliza por discrepâncias causadas por dados incorretos ou
          desatualizados.
        </H4>

        <Button
          variant="solid"
          fontWeight={600}
          onClick={onClose}
          textColor="#fafafa"
          backgroundColor="#257034"
        >
          Estou ciente
        </Button>
      </S.Modal>
    </S.Content>
  );
};

export default DisclamerPopUp;
