import { useState } from "react";

import * as S from "./styles";
import { Snackbar } from "../../../../hooks";
import { Analytics, Contact } from "../../../../services";
import P from "../../../../components/atoms/Typography/P";
import H1 from "../../../../components/atoms/Typography/H1";
import H3 from "../../../../components/atoms/Typography/H3";
import { primaryColor } from "../../../../constants/colors";
import { getCookieFromUser } from "../../../../utils/cookies";
import InputText from "../../../../components/molecules/InputText";

const validateMail = (mail: string) => {
  return !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(mail);
};

const Newsletter: React.FC = () => {
  const [mail, setMail] = useState<string>();
  const [submitted, setSubmitted] = useState<boolean>(false);

  const { newError, newSuccess } = Snackbar.useSnackbar();

  const onSubmitHandler = async () => {
    try {
      const validMail = validateMail(mail || "");

      if (!mail && (mail || "").length > 0 && !validMail) {
        newError("Insira um e-mail válido para receber as novidades!");
        return;
      }

      const userId = getCookieFromUser();

      await Analytics.sendClick({
        user: userId || "",
        location: "home-lp",
        eventLabel: "get-newsletter",
        meta: { stage: "landing-page", section: "newsletter" },
      });

      await Contact.sendNewsletterInterest({
        userId: userId || "",
        mail: mail?.toLowerCase().trim() || "",
      });

      setSubmitted(true);

      newSuccess("Adicionamos você à nossa lista da Newsletter!");
    } catch (error) {
      newError(
        "Sentimos muito, mas houve um erro! Tente novamente mais tarde."
      );
    }

    return;
  };

  return (
    <S.Container>
      <H1 color="#000000" fontWeight="bold">
        acompanhe nosso trabalho
      </H1>

      <P color="#000000">
        Inscreva-se e acompanhe as novidades dos nossos projetos, parcerias e o
        mercado de carbono.
      </P>

      {submitted ? (
        <H3 color={primaryColor}>
          Adicionamos você à nossa lista de divulgação das novidade!
        </H3>
      ) : (
        <S.Input>
          <InputText
            height="48px"
            border={false}
            value={mail || ""}
            borderRadius="24px"
            backgroundColor="#fafafa"
            onChange={(val) => setMail(val)}
          />

          <S.Button onClick={() => onSubmitHandler()}>enviar</S.Button>
        </S.Input>
      )}
    </S.Container>
  );
};

export default Newsletter;
