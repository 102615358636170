import styled from "styled-components";

import Small from "../../atoms/Typography/Small";

export const LabelBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InfoIcon = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  transform: rotate(180deg);

  svg {
    width: 16px;
    height: 16px;
  }

  :hover {
    span {
      display: flex;
    }
  }
`;

export const InfoBox = styled.span`
  top: -44px;
  padding: 8px;
  left: -160px;
  width: 160px;
  display: none;
  font-size: 12px;
  position: absolute;
  border-radius: 8px;
  transform: rotate(180deg);
  background-color: #fafafa;
`;

export const Container = styled.div`
  gap: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ErrorMessage = styled(Small)`
  font-size: 0.75rem;
`;
