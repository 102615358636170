import * as S from "./styles";
import {
  states,
  statesCities,
  energySourcesObj,
  transportUnitsObj,
} from "../../../../../../constants";
import {
  IInputValues,
  IEventCalculatorForm,
} from "../../../../../../types/event";
import {
  maskCPFOrCNPJ,
  maskPhoneNumber,
} from "../../../../../../utils/numbers";
import {
  textColor,
  primaryColor,
  backgroundColor,
} from "../../../../../../constants/colors";
import { TStates, TUnits } from "../../../../../../types";
import H2 from "../../../../../../components/atoms/Typography/H2";
import InputText from "../../../../../../components/molecules/InputText";
import AddInputs from "../../../../../../components/organisms/AddInputs";
import OptionsList from "../../../../../../components/molecules/OptionsList";
import DropdownMenu from "../../../../../../components/organisms/DropdownMenu";
import InputSliceAndNumber from "../../../../../../components/organisms/InputSliceAndNumber";

interface IProps {
  search?: string;
  question: number;
  cpfOrCnpj: string;
  booleanOptions: string[];
  onAheadClick: () => void;
  suppliers: IInputValues[];
  cpfOrCnpjError?: string[];
  filteredOptions?: string[];
  transportOptions: string[];
  onClearSearch?: () => void;
  createSupplier: () => void;
  onPreviousClick: () => void;
  onCalculateClick: () => void;
  formFields: IEventCalculatorForm;
  setCpfOrCnpj: (val: string) => void;
  errors: { [key: string]: string[] };
  removeSuppliers: (index: number) => void;
  onSearch?: (value: string, opt: string[]) => void;
  addSuppliers: (index: number, key: keyof IInputValues, value: string) => void;
  onChangeForm: (
    key: keyof IEventCalculatorForm,
    value: string | number | boolean
  ) => void;
}

const Questions: React.FC<IProps> = ({
  errors,
  search,
  onSearch,
  question,
  cpfOrCnpj,
  suppliers,
  formFields,
  setCpfOrCnpj,
  addSuppliers,
  onAheadClick,
  onChangeForm,
  onClearSearch,
  cpfOrCnpjError,
  booleanOptions,
  createSupplier,
  filteredOptions,
  removeSuppliers,
  onPreviousClick,
  transportOptions,
  onCalculateClick,
}) => {
  const content = () => {
    switch (question) {
      case 1:
        return (
          <>
            <S.BoxContent>
              <S.QuestionTitle>
                <H2 color={textColor} fontWeight="bold">
                  Equipe do evento e público
                </H2>
              </S.QuestionTitle>

              <S.Questions>
                <S.Question>
                  <InputSliceAndNumber
                    type="number"
                    minValue={1}
                    maxValue={500}
                    value={formFields.employees}
                    onChange={(val) => onChangeForm("employees", val)}
                    label="Quantas pessoas trabalharão em seu evento?"
                  />
                </S.Question>

                <S.Question>
                  <InputSliceAndNumber
                    type="number"
                    minValue={1}
                    maxValue={10000}
                    value={formFields.maxCapacity}
                    label="Qual a lotação máxima do evento?"
                    onChange={(val) => onChangeForm("maxCapacity", val)}
                  />
                </S.Question>

                <S.Question>
                  <InputSliceAndNumber
                    type="number"
                    minValue={1}
                    maxValue={10000}
                    value={formFields.public}
                    onChange={(val) => onChangeForm("public", val)}
                    label="Quantas pessoas são estimadas no evento?"
                  />
                </S.Question>
              </S.Questions>
            </S.BoxContent>

            <S.BoxContent>
              <S.QuestionTitle>
                <H2 color={textColor} fontWeight="bold">
                  Eletricidade
                </H2>
              </S.QuestionTitle>

              <S.Questions>
                <S.Question>
                  <OptionsList
                    options={booleanOptions}
                    selectedItem={formFields.considerEnergy ? 0 : 1}
                    setSelectedItem={(val) =>
                      onChangeForm("considerEnergy", booleanOptions[val])
                    }
                    label="Seu evento arca com a despesa de energia elétrica?"
                  />
                </S.Question>

                {formFields.considerEnergy && (
                  <>
                    <S.Question>
                      <OptionsList
                        options={Object.values(energySourcesObj)}
                        selectedItem={Object.keys(energySourcesObj).findIndex(
                          (item) => item === formFields.energySource
                        )}
                        setSelectedItem={(val) =>
                          onChangeForm("energySource", val)
                        }
                        label="Qual(s) a(s) fonte(s) de energia do evento?"
                      />
                    </S.Question>

                    {(formFields.energySource === "city-energy" ||
                      formFields.energySource === "both") && (
                      <S.Question>
                        <InputSliceAndNumber
                          option2="R$"
                          option1="KWh"
                          maxValue={20000}
                          unitSwitcher={true}
                          errors={errors.energyExpenses}
                          isOn={formFields.energyUnit === "R$"}
                          type={formFields.energyUnit || "KWh"}
                          value={formFields.energyExpenses || "0,00"}
                          setIsOn={(val) => onChangeForm("energyUnit", val)}
                          onChange={(val) =>
                            onChangeForm("energyExpenses", val)
                          }
                          label="Qual o gasto com energia elétrica da rede da cidade?"
                        />
                      </S.Question>
                    )}

                    {(formFields.energySource === "generator" ||
                      formFields.energySource === "both") && (
                      <S.Question>
                        <InputSliceAndNumber
                          option2="R$"
                          option1="L"
                          maxValue={20000}
                          unitSwitcher={true}
                          errors={errors.generatorFuelExpenses}
                          isOn={formFields.generatorFuelUnit === "R$"}
                          value={formFields.generatorFuelExpenses || "0,00"}
                          label="Qual o gasto com combustível para o gerador?"
                          setIsOn={(val) =>
                            onChangeForm("generatorFuelUnit", val)
                          }
                          onChange={(val) =>
                            onChangeForm("generatorFuelExpenses", val)
                          }
                          type={
                            transportOptions[
                              Object.values(transportUnitsObj).indexOf(
                                formFields.generatorFuelUnit || "l"
                              )
                            ] as TUnits
                          }
                        />
                      </S.Question>
                    )}
                  </>
                )}
              </S.Questions>
            </S.BoxContent>
          </>
        );

      case 2:
        return (
          <S.BoxContent>
            <S.QuestionTitle>
              <H2 color={textColor} fontWeight="bold">
                Gastos com deslocamento
              </H2>
            </S.QuestionTitle>

            <S.Questions>
              <S.Question>
                <InputSliceAndNumber
                  maxValue={20000}
                  unitSelector={true}
                  units={transportOptions}
                  value={formFields.internalTransportExpenses || "0,00"}
                  selectUnit={(val) =>
                    onChangeForm("internalTransportUnit", val)
                  }
                  onChange={(val) =>
                    onChangeForm("internalTransportExpenses", val)
                  }
                  selectedUnit={Object.values(transportUnitsObj).indexOf(
                    formFields.internalTransportUnit || "R$"
                  )}
                  type={
                    transportOptions[
                      Object.values(transportUnitsObj).indexOf(
                        formFields.internalTransportUnit || "R$"
                      )
                    ] as TUnits
                  }
                  label="Qual o gasto com combustível para transporte de infraestrutura, materias, entre outros? - Uso interno"
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  maxValue={200000}
                  unitSelector={true}
                  units={transportOptions}
                  value={formFields.employeesTransportExpenses || "0,00"}
                  selectUnit={(val) =>
                    onChangeForm("employeesTransportUnit", val)
                  }
                  onChange={(val) =>
                    onChangeForm("employeesTransportExpenses", val)
                  }
                  selectedUnit={Object.values(transportUnitsObj).indexOf(
                    formFields.employeesTransportUnit || "R$"
                  )}
                  type={
                    transportOptions[
                      Object.values(transportUnitsObj).indexOf(
                        formFields.employeesTransportUnit || "R$"
                      )
                    ] as TUnits
                  }
                  label="Qual o gasto total com combustível para deslocamento casa-trabalho dos colaboradores em veículos próprios ou alugados, táxis, transporte por aplicativo, etc? Informe dados de ida e volta."
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  maxValue={200000}
                  unitSelector={true}
                  units={transportOptions}
                  selectUnit={(val) =>
                    onChangeForm("employeesPublicTransportUnit", val)
                  }
                  onChange={(val) =>
                    onChangeForm("employeesPublicTransportExpenses", val)
                  }
                  selectedUnit={Object.values(transportUnitsObj).indexOf(
                    formFields.employeesPublicTransportUnit || "R$"
                  )}
                  value={formFields.employeesPublicTransportExpenses || "0,00"}
                  type={
                    transportOptions[
                      Object.values(transportUnitsObj).indexOf(
                        formFields.employeesPublicTransportUnit || "R$"
                      )
                    ] as TUnits
                  }
                  label="Qual o gasto total com combustível para deslocamento casa-trabalho dos colaboradores em transporte público em geral (ônibus, metrô, etc.)? Informe dados de ida e volta."
                />
              </S.Question>

              <S.Question>
                <OptionsList
                  options={booleanOptions}
                  setSelectedItem={(val) =>
                    onChangeForm("clientsEmissions", val)
                  }
                  selectedItem={formFields.clientsEmissions ? 0 : 1}
                  label="Deseja considerar as emissões geradas pelo deslocamento dos clientes até seu evento?"
                />
              </S.Question>

              {formFields.clientsEmissions && (
                <>
                  <S.Question>
                    <InputSliceAndNumber
                      type="Km"
                      maxValue={20000}
                      errors={errors.clientsTransportExpenses}
                      value={formFields.clientsTransportExpenses || "0,00"}
                      onChange={(val) =>
                        onChangeForm("clientsTransportExpenses", val)
                      }
                      label="Quantos quilômetros, em média, seus clientes percorrerão para o evento? Informe dados de ida e volta."
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      type="%"
                      maxValue={100}
                      errors={errors.clientsPublicTransportation}
                      value={formFields.clientsPublicTransportation || "0"}
                      onChange={(val) =>
                        onChangeForm("clientsPublicTransportation", val)
                      }
                      label="Qual o percentual do público esperado utilizará transporte público para chegar até a região do evento?"
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      type="%"
                      maxValue={100}
                      errors={errors.clientsSelfOrAppVehicles}
                      value={formFields.clientsSelfOrAppVehicles || "0"}
                      onChange={(val) =>
                        onChangeForm("clientsSelfOrAppVehicles", val)
                      }
                      label="Qual o percentual do público esperado utilizará veículos próprios ou transporte por aplicativo para chegar até a região do evento?"
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      type="%"
                      maxValue={100}
                      errors={errors.clientsWalking}
                      value={formFields.clientsWalking || "0"}
                      onChange={(val) => onChangeForm("clientsWalking", val)}
                      label="Qual o percentual do público irá à pé até o evento?"
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      type="%"
                      maxValue={100}
                      errors={errors.publicShortFlights}
                      value={formFields.publicShortFlights || "0"}
                      onChange={(val) =>
                        onChangeForm("publicShortFlights", val)
                      }
                      label="Qual o percentual do público esperado utilizará voos curtos (até 3 horas de voo) para chegar até a região do evento?"
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      type="%"
                      maxValue={100}
                      errors={errors.publicMediumFlights}
                      value={formFields.publicMediumFlights || "0"}
                      onChange={(val) =>
                        onChangeForm("publicMediumFlights", val)
                      }
                      label="Qual o percentual do público esperado utilizará voos médios (até 6 horas de voo) para chegar até a região do evento?"
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      type="%"
                      maxValue={100}
                      errors={errors.publicLongFlights}
                      value={formFields.publicLongFlights || "0"}
                      onChange={(val) => onChangeForm("publicLongFlights", val)}
                      label="Qual o percentual do público esperado utilizará voos longos (mais de 6 horas de voo) para chegar até a região do evento?"
                    />
                  </S.Question>
                </>
              )}

              <S.Question>
                <OptionsList
                  options={booleanOptions}
                  selectedItem={formFields.suppliersEmissions ? 0 : 1}
                  setSelectedItem={(val) =>
                    onChangeForm("suppliersEmissions", val)
                  }
                  label="Deseja considerar as emissões de seus fornecedores geradas pelo deslocamento dos materiais/serviços até seu evento?"
                />
              </S.Question>

              {formFields.suppliersEmissions && (
                <S.Question>
                  <AddInputs
                    values={suppliers}
                    addValues={() => createSupplier()}
                    changeValues={(index, key, value) =>
                      addSuppliers(index, key, value)
                    }
                    buttonLabel="Adicionar Fornecedores"
                    removeValues={(index) => removeSuppliers(index)}
                  />
                </S.Question>
              )}
            </S.Questions>
          </S.BoxContent>
        );

      case 3:
        return (
          <S.QuestionContainer marginTop="40px">
            <S.QuestionTitle>
              <H2 color={textColor} fontWeight="bold">
                Outros
              </H2>
            </S.QuestionTitle>

            <S.Questions>
              <S.Question>
                <InputSliceAndNumber
                  type="horas"
                  maxValue={60}
                  errors={errors.eventDuration}
                  value={formFields.eventDuration || "0"}
                  onChange={(val) => onChangeForm("eventDuration", val)}
                  label="Quantas horas terá seu evento? Se seu evento tiver mais de um dia, some as horas."
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  option2="R$"
                  option1="Kg"
                  maxValue={1000}
                  unitSwitcher={true}
                  type={formFields.cookingGasUnit || "Kg"}
                  isOn={formFields.cookingGasUnit === "R$"}
                  value={formFields.cookingGasExpenses || "0,00"}
                  setIsOn={(val) => onChangeForm("cookingGasUnit", val)}
                  onChange={(val) => onChangeForm("cookingGasExpenses", val)}
                  label="Qual o gasto com gás de cozinha (GLP), para preparação de alimentos?"
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  type="Kg"
                  maxValue={20000}
                  value={formFields.organicWaste || "0,00"}
                  onChange={(val) => onChangeForm("organicWaste", val)}
                  label="Quantos Kg de lixo Orgânico foram gerados pelo evento?"
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  type="Kg"
                  maxValue={20000}
                  value={formFields.recyclableTrash || "0,00"}
                  onChange={(val) => onChangeForm("recyclableTrash", val)}
                  label="Quantos Kg de lixo Reciclável foram gerados pelo evento?"
                />
              </S.Question>
            </S.Questions>
          </S.QuestionContainer>
        );

      case 4:
        return (
          <S.BoxContent>
            <S.QuestionTitle>
              <H2 color={textColor} fontWeight="bold">
                Informações do evento
              </H2>
            </S.QuestionTitle>

            <S.CompanyQuestions>
              <S.CompanyQuestion maxWidth="440px">
                <InputText
                  labelColor={textColor}
                  label="Nome do evento"
                  errors={errors.eventName}
                  value={formFields.eventName}
                  onChange={(val) => onChangeForm("eventName", val)}
                />
              </S.CompanyQuestion>

              <S.CompanyQuestion maxWidth="260px">
                <DropdownMenu
                  labelWeight="bold"
                  labelColor={textColor}
                  label="Segmento do evento"
                  selectorType="eventVertical"
                  errors={errors.eventVertical}
                  placeholder="Selecione o segmento"
                  selected={formFields.eventVertical}
                  onSelect={(val) => onChangeForm("eventVertical", val)}
                />
              </S.CompanyQuestion>

              <S.CompanyQuestion maxWidth="360px">
                <InputText
                  charLimit={18}
                  label="CPF ou CNPJ"
                  labelColor={textColor}
                  errors={cpfOrCnpjError}
                  value={maskCPFOrCNPJ(cpfOrCnpj)}
                  onChange={(val) => setCpfOrCnpj(val.replace(/[^0-9]/g, ""))}
                />
              </S.CompanyQuestion>

              <S.CompanyQuestion maxWidth="200px">
                <DropdownMenu
                  search={search}
                  useSearch={true}
                  options={states}
                  labelWeight="bold"
                  labelColor={textColor}
                  label="Estado do evento"
                  errors={errors.eventState}
                  placeholder="Selecione o estado"
                  selected={formFields.eventState}
                  filteredOptions={filteredOptions}
                  onSelect={(val) => onChangeForm("eventState", val)}
                  onSearch={(val) => onSearch && onSearch(val, states)}
                  onClearSearch={() => onClearSearch && onClearSearch()}
                />
              </S.CompanyQuestion>

              <S.CompanyQuestion maxWidth="220px">
                <DropdownMenu
                  search={search}
                  infoIcon={true}
                  useSearch={true}
                  labelWeight="bold"
                  labelColor={textColor}
                  label="Cidade do evento"
                  errors={errors.eventCity}
                  selected={formFields.eventCity}
                  placeholder="Selecione a cidade"
                  filteredOptions={filteredOptions}
                  onSelect={(val) => onChangeForm("eventCity", val)}
                  onClearSearch={() => onClearSearch && onClearSearch()}
                  options={statesCities[formFields.eventState as TStates]}
                  info="Caso não encontre sua cidade, selecione aquela mais próxima"
                  onSearch={(val) =>
                    onSearch &&
                    onSearch(
                      val,
                      statesCities[formFields.eventState as TStates]
                    )
                  }
                />
              </S.CompanyQuestion>

              <S.CompanyQuestion maxWidth="440px">
                <InputText
                  labelColor={textColor}
                  label="Nome para contato"
                  errors={errors.contactName}
                  value={formFields.contactName}
                  onChange={(val) => onChangeForm("contactName", val)}
                />
              </S.CompanyQuestion>

              <S.CompanyQuestion maxWidth="240px">
                <InputText
                  type="tel"
                  charLimit={15}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  labelColor={textColor}
                  errors={errors.contactPhone}
                  label="Telefone para contato"
                  value={maskPhoneNumber(formFields.contactPhone)}
                  onChange={(val) =>
                    onChangeForm(
                      "contactPhone",
                      (val || "").replace(/[^0-9]/g, "")
                    )
                  }
                />
              </S.CompanyQuestion>

              <S.CompanyQuestion maxWidth="480px">
                <InputText
                  labelColor={textColor}
                  label="E-mail para contato"
                  errors={errors.contactMail}
                  value={formFields.contactMail}
                  onChange={(val) => onChangeForm("contactMail", val)}
                />
              </S.CompanyQuestion>
            </S.CompanyQuestions>
          </S.BoxContent>
        );

      default:
        return;
    }
  };

  return (
    <S.Container>
      {content()}

      <S.Buttons>
        <S.Clickable
          variant="solid"
          fontWeight="bold"
          borderColor={primaryColor}
          isVisible={question !== 4}
          textColor={backgroundColor}
          backgroundColor={primaryColor}
          onClick={() => onAheadClick()}
        >
          Próximo
        </S.Clickable>

        <S.Clickable
          variant="solid"
          fontWeight="bold"
          borderColor={primaryColor}
          isVisible={question === 4}
          textColor={backgroundColor}
          backgroundColor={primaryColor}
          onClick={() => onCalculateClick()}
        >
          Calcular
        </S.Clickable>

        <S.Clickable
          variant="solid"
          fontWeight="bold"
          textColor={primaryColor}
          isVisible={question > 1}
          borderColor={primaryColor}
          backgroundColor={backgroundColor}
          onClick={() => onPreviousClick()}
        >
          Anterior
        </S.Clickable>
      </S.Buttons>
    </S.Container>
  );
};

export default Questions;
