import { primaryColor } from "../../constants/colors";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const WhatsappIcon: React.FC<IProps> = ({ color }) => {
  return (
    <svg
      width="600"
      height="600"
      fill="none"
      viewBox="0 0 600 600"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.804 296.409C12.7899 346.821 26.0649 396.044 51.3071 439.43L10.3899 587.667L163.277 547.891C205.564 570.733 252.943 582.702 301.09 582.705H301.216C460.158 582.705 589.54 454.372 589.608 296.635C589.638 220.2 559.666 148.326 505.212 94.2512C450.766 40.1814 378.356 10.3884 301.205 10.3535C142.245 10.3535 12.8719 138.679 12.8063 296.409"
        fill={color || primaryColor}
      />
      <path
        d="M2.50781 296.316C2.49141 348.542 16.2422 399.526 42.3844 444.465L0 598.016L158.37 556.814C202.005 580.421 251.135 592.867 301.127 592.886H301.256C465.9 592.886 599.93 459.937 600 296.554C600.028 217.372 568.978 142.914 512.578 86.9023C456.171 30.8977 381.171 0.0325581 301.256 0C136.584 0 2.57344 132.93 2.50781 296.316ZM96.8203 436.726L90.907 427.412C66.0492 388.193 52.9289 342.872 52.9477 296.335C53.0016 160.533 164.386 50.0465 301.35 50.0465C367.678 50.0744 430.012 75.7302 476.897 122.279C523.779 168.833 549.577 230.716 549.56 296.535C549.499 432.337 438.112 542.837 301.256 542.837H301.158C256.596 542.814 212.892 530.94 174.778 508.5L165.708 503.163L71.7281 527.612L96.8203 436.723V436.726Z"
        fill="#fafafa"
      />
      <path
        d="M226.589 172.437C220.997 160.105 215.112 159.856 209.794 159.64C205.439 159.454 200.461 159.468 195.488 159.468C190.509 159.468 182.421 161.326 175.584 168.733C168.741 176.147 149.456 194.063 149.456 230.502C149.456 266.944 176.206 302.161 179.934 307.107C183.668 312.044 231.574 389.216 307.446 418.905C370.502 443.577 383.334 438.67 397.02 437.433C410.707 436.2 441.185 419.521 447.403 402.226C453.626 384.933 453.626 370.109 451.76 367.012C449.895 363.926 444.916 362.072 437.452 358.37C429.984 354.665 393.286 336.747 386.445 334.275C379.601 331.805 374.625 330.572 369.647 337.988C364.669 345.393 350.374 362.072 346.017 367.012C341.665 371.963 337.308 372.579 329.845 368.874C322.376 365.158 298.336 357.347 269.815 332.116C247.624 312.484 232.643 288.24 228.288 280.823C223.934 273.419 227.822 269.405 231.565 265.714C234.919 262.395 239.032 257.065 242.768 252.742C246.49 248.416 247.732 245.33 250.221 240.391C252.713 235.447 251.466 231.121 249.602 227.416C247.732 223.712 233.227 187.081 226.589 172.437Z"
        fill="#fafafa"
      />
    </svg>
  );
};

export default WhatsappIcon;
