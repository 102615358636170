import styled, { css } from "styled-components";

type ContainerProps = {
  result: boolean;
};

export const Container = styled.div<ContainerProps>`
  ${({ result }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 180px 40px 40px;

    @media (max-width: 1270px) {
      height: auto;
    }

    @media (max-width: 1080px) {
      margin-bottom: 80px;
    }

    @media (max-width: 540px) {
      margin-bottom: 40px;
    }

    @media (max-width: 480px) {
      padding: 160px 0px 40px;
      margin-bottom: ${result ? "180px" : "40px"};
    }
  `}
`;

export const TitleAndProgress = styled.div`
  gap: 36px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TitleAndDescription = styled.div`
  gap: 12px;
  display: flex;
  max-width: 440px;
  text-align: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 680px) {
    max-width: 300px;
  }
`;

type CalculatorBoxProps = {
  resultBox: boolean;
};

export const Content = styled.div<CalculatorBoxProps>`
  ${({ resultBox }) => css`
    gap: 20px;
    width: 100%;
    display: flex;
    margin-top: 52px;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: ${resultBox && "column"};

    @media (max-width: 1270px) {
      gap: 40px;
    }

    @media (max-width: 840px) {
      margin-top: ${resultBox ? "100px" : "52px"};
    }
  `}
`;

export const CalculatorBox = styled.div<CalculatorBoxProps>`
  ${({ resultBox }) => css`
    gap: 36px;
    width: 100%;
    display: flex;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    align-items: center;
    flex-direction: column;
    max-width: ${resultBox && "80%"};

    @media (max-width: 600px) {
      max-width: ${resultBox && "90%"};
    }

    @media (max-width: 540px) {
      max-width: ${resultBox && "100%"};
      margin-top: ${!resultBox && "-60px"};
      transform: ${!resultBox && "scale(0.9)"};
    }

    @media (max-width: 480px) {
      padding: ${resultBox && "0 32px"};
      margin-top: ${!resultBox && "-100px"};
      transform: ${!resultBox && "scale(0.8)"};
    }
  `}
`;

export const Charts = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 840px) {
    gap: 32px;
    align-items: center;
    flex-direction: column;
  }
`;

export const ChartBox = styled.div`
  height: 100%;
  display: flex;
  position: relative;
`;

export const ChartResult = styled.div`
  gap: 32px;
  display: flex;
  padding: 20px 24px;
  text-align: center;
  align-items: center;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  background-color: #d6e5c5;

  h2 {
    sub {
      font-size: 1.1rem;
    }
  }

  @media (max-width: 1080px) {
    margin: -20px 0 0;
  }

  @media (max-width: 840px) {
    margin: -60px 0 0;
  }

  @media (max-width: 480px) {
    margin: -60px 32px 32px;
  }
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row-reverse;
  justify-content: space-between;

  button {
    max-width: 240px;
  }

  @media (max-width: 840px) {
    button {
      max-width: 200px;
    }
  }

  @media (max-width: 720px) {
    button {
      max-width: 160px;
    }
  }

  @media (max-width: 400px) {
    button {
      max-width: 110px;
    }
  }
`;

export const Disclamer = styled.div`
  width: 100%;
  margin-top: 60px;
  text-align: justify;

  @media (max-width: 540px) {
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    padding: 0 20px;
    margin-top: 0;
  }
`;
