import { Helmet } from "react-helmet-async";

interface IProps {
  title: string;
  route: string;
  keywords: string;
  description: string;
}

const SEO: React.FC<IProps> = ({ title, route, keywords, description }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={route} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

export default SEO;
