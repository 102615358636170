import styled from "styled-components";

type H1Props = {
  color: string;
  fontWeight?: string;
};

const H1 = styled.h1<H1Props>`
  font-size: 2rem;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
`;

export default H1;
