const EnergyIcon: React.FC = () => {
  return (
    <svg
      width="401"
      height="501"
      viewBox="0 0 401 501"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M141.712 50.7192L63.8529 225.904H150.121C167.389 225.904 179.552 242.797 174.097 259.189L132.652 383.495L315.299 200.877H250.979C246.67 200.876 242.433 199.774 238.67 197.675C234.907 195.576 231.743 192.549 229.479 188.883C227.215 185.217 225.925 181.034 225.733 176.729C225.54 172.424 226.452 168.142 228.38 164.289L285.166 50.7192H141.712ZM102.519 15.6823C106.574 6.5477 115.609 0.666504 125.619 0.666504H325.259C344.029 0.666504 356.268 20.4373 347.859 37.255L291.072 150.825H375.113C397.637 150.825 408.899 178.053 392.982 193.97L93.8351 493.11C74.9648 511.98 43.531 492.56 51.9901 467.258L115.759 275.956H25.7118C21.5009 275.956 17.3567 274.904 13.6557 272.896C9.95473 270.887 6.81415 267.986 4.51925 264.455C2.22436 260.925 0.847844 256.877 0.514756 252.679C0.181669 248.482 0.902558 244.267 2.61194 240.419L102.519 15.6823Z"
        fill="black"
      />
    </svg>
  );
};

export default EnergyIcon;
