import styled, { css } from "styled-components";

import { secondaryColor } from "../../../constants/colors";

export const Container = styled.div`
  display: flex;
  max-width: 55%;
  flex-direction: column;

  @media (max-width: 950px) {
    width: 100%;
    padding: 40px;
    max-width: 100%;
  }
`;

export const Menu = styled.div`
  gap: 20px;
  display: flex;
  padding: 0 80px 0 12px;
  border-bottom: 1px solid #000000;

  @media (max-width: 950px) {
    padding: 0 120px;
    justify-content: center;
  }

  @media (max-width: 880px) {
    padding: 0 60px;
    justify-content: center;
  }

  @media (max-width: 660px) {
    padding: 0 20px;
    justify-content: center;
  }

  @media (max-width: 550px) {
    gap: 8px;
  }
`;

type SheetProps = {
  isSelected: boolean;
};

export const Sheet = styled.div<SheetProps>`
  ${({ isSelected }) => css`
    width: 200px;
    padding: 8px;
    display: flex;
    min-height: 48px;
    cursor: pointer;
    font-weight: bold;
    font-size: 0.8rem;
    user-select: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: ${isSelected && secondaryColor};
    border-top: ${!isSelected && "1px solid #000000"};
    border-left: ${!isSelected && "1px solid #000000"};
    border-right: ${!isSelected && "1px solid #000000"};
  `}
`;
