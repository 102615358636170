import styled, { css } from "styled-components";

type SwitcherProps = {
  isOn: boolean;
  backgroundColorOn: string;
  backgroundColorOff: string;
};

export const Switcher = styled.div<SwitcherProps>`
  ${({ isOn, backgroundColorOn, backgroundColorOff }) => css`
    width: 40px;
    height: 20px;
    padding: 1px;
    display: flex;
    cursor: pointer;
    user-select: none;
    position: relative;
    align-items: center;
    border-radius: 100px;
    opacity: ${isOn ? 1 : 0.5};
    justify-content: ${isOn ? "flex-end" : "flex-start"};
    background-color: ${isOn ? backgroundColorOn : backgroundColorOff};
  `}
`;

type SwitcherButtonProps = {
  switcherColor: string;
};

export const SwitcherButton = styled.div<SwitcherButtonProps>`
  ${({ switcherColor }) => css`
    width: 16px;
    height: 16px;
    cursor: pointer;
    user-select: none;
    position: relative;
    border-radius: 100px;
    background-color: ${switcherColor};
  `}
`;
