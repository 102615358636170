import styled, { css } from "styled-components";
import { primaryColor, secondaryColor } from "../../../constants/colors";

export const ContainerBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 990px) {
    padding: 120px 40px 80px;
  }

  @media (max-width: 700px) {
    padding: 120px 20px 80px;
  }
`;

type ContainerProps = {
  image: string;
};

export const Container = styled.div<ContainerProps>`
  ${({ image }) => css`
    width: 100%;
    height: 140vh;
    background: url(${image});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;

    @media (max-width: 990px) {
      display: none;
    }
  `}
`;

export const FormBox = styled.div`
  gap: 28px;
  width: 100%;
  display: flex;
  padding: 0 80px;
  flex-direction: column;

  button {
    width: 30%;
    align-self: center;
  }

  h1 {
    width: 600px;
    flex-shrink: 0;
    padding: 0 80px;
    font-size: 3.5rem;

    span {
      font-size: 3.5rem;
      font-weight: bold;
    }
  }

  @media (max-width: 1300px) {
    padding: 0 40px;
  }

  @media (max-width: 990px) {
    h1 {
      max-width: 600px;
      align-self: center;
      text-align: center;
    }
  }

  @media (max-width: 620px) {
    h1 {
      padding: 0 40px;
      max-width: 100%;
      font-size: 2.5rem;
      align-self: center;
      text-align: center;

      span {
        font-size: 2.5rem;
        font-weight: bold;
      }
    }
  }

  @media (max-width: 470px) {
    padding: 0 20px;

    h1 {
      padding: 0 20px;
    }
  }
`;

export const Form = styled.div`
  gap: 12px;
  width: 100%;
  display: flex;
  padding: 0 80px;
  flex-direction: column;

  @media (max-width: 1300px) {
    padding: 0 40px;
  }

  @media (max-width: 700px) {
    padding: 0;
  }
`;

export const InputBox = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const InputBoxMessage = styled.div`
  gap: 12px;
  width: 100%;
  display: flex;
  margin-top: 24px;
  flex-direction: column;
`;

type HighlightProps = {
  color: string;
};

export const Highlight = styled.span<HighlightProps>`
  ${({ color }) => css`
    color: ${color};
  `}
`;

export const SubmittedBox = styled.div`
  gap: 40px;
  display: flex;
  padding-top: 40px;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const SubmittedTexts = styled.div`
  gap: 32px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const ContactBox = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const IbiomaLogo = styled.div`
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Button = styled.div`
  display: flex;
  cursor: pointer;
  user-select: none;
  font-weight: bold;
  padding: 12px 24px;
  width: fit-content;
  align-self: center;
  align-items: center;
  border-radius: 20px;
  color: ${primaryColor};
  justify-content: center;
  background-color: ${secondaryColor};
`;
