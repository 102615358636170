import { primaryColor } from "../../constants/colors";

interface IProps {
  color?: string;
}

const QualitySealIcon: React.FC<IProps> = ({ color }) => {
  return (
    <svg
      id="Camada_1"
      data-name="Camada 1"
      viewBox="0 0 1000 1000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="_8RDeaz.tif" data-name="8RDeaz.tif">
        <g>
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m668.23,929.53h-19.55c-8.88-3.16-16-8.94-23.02-15.01-39.41-34.1-78.96-68.04-118.27-102.24-5.14-4.47-8.7-4.51-13.86-.03-39.32,34.2-78.88,68.12-118.29,102.23-7.03,6.08-14.37,11.55-23.03,15.05h-17.92c-20.6-7.63-30.4-22.2-29.29-42.68,1.15-21.14,1.88-42.3,2.77-63.45,1.27-30.38,2.49-60.75,3.77-91.13,1.97-46.64,3.93-93.28,6.05-139.92.26-5.68-1.65-9.77-5.86-13.49-15.33-13.54-28.69-28.92-40.18-45.81-45.37-66.7-60.44-139.81-43.86-218.75,13-61.87,44.52-113.37,92.61-154.24,46.53-39.55,100.69-61.57,161.58-65.56,61.44-4.03,118.49,10.68,170.21,44.39,49.9,32.53,85.83,76.71,108.1,132.06,9.91,24.62,15.03,50.35,18.83,76.44v52.18c-.4,2.13-.9,4.24-1.17,6.38-8.07,63.28-33.71,118.42-79.08,163.15-12.22,12.05-16.22,23.46-15.36,39.71,3.3,62.33,5.59,124.72,8.24,187.08,1.21,28.47,1.89,56.98,3.73,85.41,1.61,24.88-4.8,37.31-27.12,48.21ZM277.82,373.25c.57,122.08,97.43,222.49,222.11,222.78,125.05.29,222.7-100.23,223.06-222.57.35-122.57-99.64-222.65-222.03-222.84-122.88-.2-223.08,99.78-223.13,222.63Zm86.54,475.32c4.5-3.73,7.43-6.09,10.28-8.54,32.07-27.65,64.13-55.31,96.19-82.98,21.23-18.31,38.09-18.27,59.11-.08,32.43,28.06,64.92,56.05,97.47,83.98,2.54,2.18,4.6,5.33,9.37,5.86,0-3.19.11-6.1-.02-9-1.03-24.4-2.18-48.8-3.16-73.21-1.76-44.2-3.4-88.42-5.16-132.62-.36-8.94-1.48-9.44-9.48-5.82-78.94,35.78-157.93,35.79-236.89.06-8.12-3.67-9.23-3.22-9.61,5.61-1.19,27.66-2.22,55.32-3.39,82.98-1.53,36.33-3.19,72.66-4.66,109-.31,7.52-.04,15.07-.04,24.73Z"
          />
          <path
            className="cls-1"
            fill={color || primaryColor}
            d="m361.04,376.25c.25-15.71,5.77-24.5,16.65-29.49,11.72-5.38,22.53-2.69,31.48,5.94,13.67,13.2,26.84,26.92,40.2,40.44q14.76,14.94,29.55.14c33.59-33.62,67.2-67.2,100.73-100.88,6.57-6.6,13.76-11.24,23.56-10.94,10.73.33,18.78,5.03,23.99,14.24,5.4,9.54,5.27,19.3-.77,28.54-2.35,3.59-5.42,6.78-8.47,9.84-43.14,43.26-86.32,86.48-129.52,129.69-17.22,17.22-31.98,17.26-48.99.09-22.55-22.76-45.03-45.6-67.56-68.38-6.29-6.37-11.14-13.38-10.86-19.22Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default QualitySealIcon;
