import styled, { css } from "styled-components";

type PProps = {
  color: string;
  opacity?: number;
  ellipsis?: boolean;
  fontWeight?: string;
};

const P = styled.p<PProps>`
  ${({ color, fontWeight, opacity, ellipsis }) => css`
    color: ${color};
    font-size: 0.75rem;
    opacity: ${opacity};
    font-weight: ${fontWeight};
    overflow: ${ellipsis && "hidden"};
    white-space: ${ellipsis && "nowrap"};
    text-overflow: ${ellipsis && "ellipsis"};
  `}
`;

export default P;
