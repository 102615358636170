import { primaryColor } from "../../../constants/colors";
import * as S from "./styles";

interface IProps {
  isOn: boolean;
  setIsOn?: (isOn: boolean) => void;
}

const SwitchBase: React.FC<IProps> = ({ isOn, setIsOn }) => {
  return (
    <S.Switcher
      isOn={isOn}
      backgroundColorOn={primaryColor}
      backgroundColorOff="#18181880"
      onClick={() => setIsOn && setIsOn(!isOn)}
    >
      <S.SwitcherButton switcherColor="#fafafa" role="button" />
    </S.Switcher>
  );
};

export default SwitchBase;
