import styled, { css } from "styled-components";

import { grayIbioma, secondaryColor } from "../../../../constants/colors";

export const Container = styled.div`
  gap: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleBox = styled.div`
  width: 600px;
  display: flex;
  min-height: 500px;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 50%;
  padding: 140px 60px 140px 80px;
  border-bottom-right-radius: 50%;
  background-color: ${grayIbioma};

  h1 {
    font-size: 3.5rem;
  }

  @media (max-width: 950px) {
    display: none;
  }
`;

export const CarbonCreditBox = styled.div`
  gap: 40px;
  display: flex;
  padding: 80px 0;
  flex-direction: column;

  @media (max-width: 950px) {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 1px solid #000000;
  }

  @media (max-width: 700px) {
    padding: 40px 0;
  }
`;

export const CarbonEquality = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CarbonOption = styled.div`
  width: 92px;
  height: 160px;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  padding-top: 12px;
  text-align: center;
  align-items: center;
  border-radius: 40px;
  flex-direction: column;
  background-color: ${secondaryColor};
`;

export const CarbonIcon = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  border-radius: 28px;
  justify-content: center;
  background-color: ${secondaryColor};

  svg {
    width: 60px;
    height: 60px;
  }
`;

export const CarbonText = styled.div`
  gap: 20px;
  display: flex;
  text-align: justify;
  padding-right: 80px;
  flex-direction: column;

  @media (max-width: 950px) {
    padding: 0 80px;
  }

  @media (max-width: 700px) {
    padding: 0 40px;
  }

  @media (max-width: 500px) {
    padding: 0 20px;
  }
`;

export const HowBox = styled.div`
  gap: 32px;
  display: grid;
  padding: 60px 80px 0;
  align-items: flex-start;
  justify-content: center;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 950px) {
    padding-bottom: 40px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 1px solid #000000;
  }

  @media (max-width: 620px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 480px) {
    padding: 60px 0 40px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const HowItem = styled.div`
  gap: 20px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const HowIcon = styled.div`
  width: 48px;
  height: 80px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  border-radius: 28px;
  justify-content: center;
  background-color: ${secondaryColor};

  svg {
    width: 40px;
    height: 40px;
  }
`;

export const SealBox = styled.div`
  gap: 40px;
  display: flex;
  align-items: center;
  padding: 140px 0 160px;

  @media (max-width: 950px) {
    padding: 120px 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 1px solid #000000;
  }

  @media (max-width: 900px) {
    padding: 100px 0;
  }

  @media (max-width: 700px) {
    gap: 20px;
  }

  @media (max-width: 550px) {
    padding: 40px;
    flex-direction: column-reverse;
  }

  @media (max-width: 480px) {
    padding: 40px 20px;
    flex-direction: column-reverse;
  }
`;

type ImageProps = {
  image: string;
};

export const SealLogo = styled.div<ImageProps>`
  ${({ image }) => css`
    width: 220px;
    height: 220px;
    display: flex;
    flex-shrink: 0;
    background: url(${image});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 700px) {
      width: 160px;
      height: 160px;
    }
  `}
`;

export const SealTexts = styled.div`
  gap: 20px;
  display: flex;
  text-align: justify;
  padding-right: 100px;
  flex-direction: column;

  @media (max-width: 1300px) {
    padding-right: 60px;
  }

  @media (max-width: 1080px) {
    padding-right: 20px;
  }

  @media (max-width: 900px) {
    padding-right: 0;
  }
`;

export const IconAndText = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
`;

export const SealIcon = styled.div`
  width: 40px;
  height: 76px;
  display: flex;
  flex-shrink: 0;
  border-radius: 28px;
  align-items: center;
  justify-content: center;
  background-color: ${secondaryColor};

  svg {
    width: 36px;
    height: 36px;
  }
`;

export const SealText = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ReportBox = styled.div`
  gap: 36px;
  display: flex;
  padding: 80px 200px 80px 0;

  @media (max-width: 1750px) {
    padding-right: 100px;
  }

  @media (max-width: 1550px) {
    padding-right: 20px;
  }

  @media (max-width: 1130px) {
    flex-direction: column;
    padding: 20px 20px 40px 0;
  }

  @media (max-width: 950px) {
    padding: 40px 0px;
    flex-direction: row;
    justify-content: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 1px solid #000000;
  }

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

export const ReportTextAndCTA = styled.div`
  gap: 20px;
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
`;

export const ReportText = styled.div`
  gap: 16px;
  display: flex;
  max-width: 700px;
  text-align: justify;
  flex-direction: column;

  @media (max-width: 1750px) {
    max-width: 600px;
  }

  @media (max-width: 1550px) {
    max-width: 500px;
  }

  @media (max-width: 1020px) {
    max-width: 440px;
  }

  @media (max-width: 640px) {
    max-width: 100%;
  }
`;

export const ReportCTA = styled.a`
  gap: 8px;
  display: flex;
  padding: 2px 12px;
  width: fit-content;
  align-items: center;
  border-radius: 24px;
  text-decoration: none;
  justify-content: center;
  background-color: ${secondaryColor};

  @media (max-width: 640px) {
    align-self: center;
  }
`;

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const ReportMockup = styled.div<ImageProps>`
  ${({ image }) => css`
    width: 240px;
    height: 300px;
    flex-shrink: 0;
    align-self: center;
    background: url(${image});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `}
`;
