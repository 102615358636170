import styled, { css } from "styled-components";

export const ContentSlider = styled.div`
  gap: 28px;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  user-select: none;
  overflow-x: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
`;

type ContainerProps = {
  paddingLeft: string;
  centralize: boolean;
  clearPadding: boolean;
  transformValue: number;
};

export const Container = styled.div<ContainerProps>`
  ${({ centralize, paddingLeft, clearPadding, transformValue }) => css`
    gap: 20px;
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    padding-left: 12px;
    justify-content: ${centralize && "center"};
    padding-left: ${clearPadding ? paddingLeft : 0};
    transform: ${`translateX(-${transformValue}px)`};
  `}
`;

type ContentBoxProps = {
  blur: boolean;
  isSelected: boolean;
};

export const ContentBox = styled.div<ContentBoxProps>`
  ${({ blur, isSelected }) => css`
    gap: 40px;
    width: 100%;
    display: flex;
    filter: ${!isSelected && blur && "blur(6px)"};
  `}
`;

export const Indicators = styled.div`
  gap: 8px;
  z-index: 2;
  display: flex;
  position: relative;
`;

type IndicatorProps = {
  isSelected: boolean;
  selectedColor: string;
  notSelectedColor: string;
};

export const Indicator = styled.span<IndicatorProps>`
  ${({ isSelected, selectedColor, notSelectedColor }) => css`
    height: 8px;
    width: 60px;
    cursor: pointer;
    border-radius: 6px;
    border: ${isSelected ? "none" : "1px solid #000000"};
    background-color: ${isSelected ? selectedColor : notSelectedColor};
  `}
`;
